import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FeatureFlagsTable } from './FeatureFlagsTable';

const queryClient = new QueryClient();

function FeatureFlags() {
    return (
        <QueryClientProvider client={queryClient}>
            <FeatureFlagsTable />
        </QueryClientProvider>
    );
}

FeatureFlags.propTypes = {};
export default FeatureFlags;
