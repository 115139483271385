import React, { useEffect, useRef, useState } from 'react';
import { getFileInfo } from '../../Services/Search.services';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { CircularProgress, Grid, Pagination } from '@mui/material';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import { datetimeToFormattedString } from '../../Utils/Utils';
import { Header } from './Header/Header';
import { Filters } from './Filters/Filters';
import { format } from 'date-fns';
import { dateFormat } from '../../Constants/Date.constant';
import SkeletonComponent from '../SkeletonComponent';

const columns = [
    {
        id: 'uploaded_at',
        label: 'Uploaded\u00a0At',
        minWidth: 170,
        align: 'left',
        format: (value) => datetimeToFormattedString(value),
    },
    {
        id: 'folder_id',
        label: 'Folder\u00a0Id',
        align: 'left',
        minWidth: 170,
    },
    {
        id: 'document_id',
        label: 'Document\u00a0Id',
        align: 'left',
        minWidth: 100,
    },
    {
        id: 'title',
        label: 'Title',
        minWidth: 90,
        align: 'left',
        format: (value) => value.toLocaleString('en-GB', { timeZone: 'UTC' }),
    },
    {
        id: 'user_name',
        label: 'User\u00a0Name',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-GB', { timeZone: 'UTC' }),
    },
    {
        id: 'organisation',
        label: 'Organisation',
        minWidth: 110,
        align: 'left',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'source',
        label: 'Source',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'is_error',
        label: 'Is\u00a0Error',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'is_deleted',
        label: 'Is\u00A0Deleted',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'file_type',
        label: 'File\u00A0Type',
        minWidth: 80,
        align: 'left',
        format: (value) => value.toFixed(2),
    },
];

function DownloadIcon() {
    return null;
}

function FileInformation({ folderId }) {
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [textSearch, setTextSearch] = useState('');
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [records, setRecords] = useState([]);
    const myRefs = useRef([]);
    const myLoadingRefs = useRef([]);
    const [pageCount, setPageCount] = useState(1);
    const [type, setType] = useState({
        'title_register': true,
        'title_plan': true,
        'lease': true,
    });

    useEffect(() => {
        setIsLoading(true);
        getRecords(page);
    }, [textSearch, startDate, endDate, type]);

    const makeParams = (pagination_page) => {
        let urlParam = 'offset=' + offset;
        urlParam = 'limit=' + limit;
        if (textSearch) {
            urlParam += '&query=' + textSearch;
        }
        if (startDate) {
            urlParam += '&startDate=' + startDate;
        }
        if (endDate) {
            urlParam += '&endDate=' + endDate;
        }
        if (page) {
            urlParam += '&offset=' + (pagination_page - 1);
        }
        if (type) {
            // iterate over the object
            for (let key in type) {
                urlParam += `&${key}=` + type[key];
            }
        }
        return urlParam;
    };

    const getRecords = (page) => {
        const paramUrl = makeParams(page);

        getFileInfo(folderId, paramUrl)
            .then((res) => {
                if ((res && res.stack && res.message)) {
                    setRecords([]);
                    setOffset(0);
                    setLimit(10);
                    setPageCount(1);
                } else {
                    const dataResp = res;
                    const count = Number(dataResp.count);
                    const limit = Number(dataResp.limit);
                    const pcount = Math.ceil((count / limit));

                    setRecords(dataResp.folders);
                    setOffset(dataResp.offset);
                    setLimit(dataResp.limit);
                    setPageCount(pcount);
                }
            })
            .catch(() => {
                setRecords([]);
                setOffset(0);
                setLimit(10);
                setPageCount(1);
            })
            .finally(() => {
                setIsLoading(false);
            });

    };

    const handlePageChange = (event, value) => {
        setPage(value);
        getRecords(value);
    };

    const handleChangeFilter = (filterValues) => {
        const startDate = filterValues.startDate;
        const endDate = filterValues.endDate;
        const formattedStartDate = startDate && format(startDate, dateFormat);
        const formattedEndDate = endDate && format(endDate, dateFormat);

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setTextSearch(filterValues.text);
        setOffset(0);
        setPage(1);
    };

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ mt: 2, mb: 2, width: '100% !important' }}
            id={'file-information-grid'}
        >
            <div className="header">
                <Header
                    filters={(
                        <Filters
                            isDateRange
                            isText
                            onFiltersChange={handleChangeFilter}
                        />
                    )}
                />
            </div>
            {
                isLoading
                    ? (<SkeletonComponent />)
                    : records.length
                        ? (
                            <>
                                <TableContainer sx={{ maxHeight: '400px', height: '400px' }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="user table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column, index) => (
                                                    <TableCell
                                                        key={column.id + index}
                                                        align={column.align}
                                                        style={{
                                                            top: 57,
                                                            minWidth: column.minWidth,
                                                            overflow: 'auto',
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {records.map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row['document_id'] + index}
                                                        sx={{ height: '10px!important' }}
                                                    >
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            // debugger;
                                                            if (column.label === 'PDF' || column.label === 'Data' || column.label === 'Reports') {
                                                                let downloadId = column.id + row['document_id'];
                                                                let projectName = '';
                                                                let excludedDocument = [];
                                                                let includedDocument = [];
                                                                if (column.label === 'PDF') {
                                                                    includedDocument = ['.pdf'];
                                                                    projectName = 'pdf';
                                                                } else if (column.label === 'Reports') {
                                                                    includedDocument = ['.docx'];
                                                                    projectName = 'reports';
                                                                } else {
                                                                    excludedDocument = ['.pdf', '.data'];
                                                                    projectName = 'data';
                                                                }
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                m: 1,
                                                                                position: 'relative',
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                size="small"
                                                                                id={'button' + downloadId}
                                                                                variant="outlined"
                                                                                ref={(el) => (myRefs.current[downloadId] = el)}
                                                                                // FIXME: this method should be implemented
                                                                                // onClick={(e) => (handleDownload(downloadId, row['folder_id'], excludedDocument, includedDocument, projectName))}
                                                                                disabled={false}
                                                                                endIcon={
                                                                                    <DownloadIcon />
                                                                                }
                                                                            >
                                                                                Download
                                                                            </Button>
                                                                            <CircularProgress
                                                                                size={24}
                                                                                ref={(el) => (myLoadingRefs.current[downloadId] = el)}
                                                                                sx={{
                                                                                    color: green[500],
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    marginTop: '-12px',
                                                                                    marginLeft: '-12px',
                                                                                    display: 'none',
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </TableCell>
                                                                );
                                                            } else if (column.label === 'Is\u00a0Error') {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                    >
                                                                        {row['is_error'] !== undefined
                                                                            ? row['is_error'].toString()
                                                                            : (row['is_error'] !== undefined
                                                                                ? row['is_error'].toString()
                                                                                : '-')}
                                                                    </TableCell>
                                                                );
                                                            } else if (column.label === 'Is\u00A0Deleted') {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                    >
                                                                        {row['is_deleted'] !== undefined
                                                                            ? row['is_deleted'].toString()
                                                                            : (row['is_deleted'] !== undefined
                                                                                ? row['is_deleted'].toString()
                                                                                : '-')}
                                                                    </TableCell>
                                                                );
                                                            } else if (column.label === 'Uploaded\u00a0At') {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                    >
                                                                        {datetimeToFormattedString(row['uploaded_at'])}
                                                                    </TableCell>
                                                                );
                                                            }
                                                            // if the value is null or undefined, then return '-'
                                                            else if (value === null || value === undefined || value === '') {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                    >
                                                                        -
                                                                    </TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                    >
                                                                        {column.format && typeof value === 'number'
                                                                            ? column.format(value)
                                                                            : value}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid item xs={12} sm={12} md={12} sx={{ mt: 1, mb: 2, ml: 2 }}>
                                    <Stack spacing={2}>
                                        {/*<Pagination count={3} showFirstButton showLastButton /> */}
                                        <Pagination
                                            showFirstButton
                                            showLastButton
                                            count={pageCount}
                                            page={page}
                                            siblingCount={1}
                                            boundaryCount={1}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handlePageChange}
                                        />
                                    </Stack>
                                </Grid>
                            </>
                        ) : (
                            <Stack sx={{ width: '100% !important', height: '460px' }} spacing={2}>
                                <Alert severity="warning">Records not found!</Alert>
                            </Stack>
                        )
            }
        </Grid>
    );
}

FileInformation.propTypes = {};
export default FileInformation;
