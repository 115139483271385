import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


function Stats({ data }) {

    const maxNumber = useMemo(
        () => data.reduce((acc, curr) => Math.max(acc, curr.Numbers), 0),
        [],
    );

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'Name',
                enableGrouping: false, //do not let this column be grouped
                size: 300,
            },
            {
                header: 'Numbers',
                accessorKey: 'Numbers',
                aggregationFn: 'max', //show the max age in the group (lots of pre-built aggregationFns to choose from)
                //required to render an aggregated cell
                size: 300,

            },
            {
                header: 'Type',
                accessorKey: 'Type',
                size: 300,
            },
            {
                header: 'Period',
                accessorKey: 'Period',
                size: 300,
            },
        ],
        [maxNumber],
    );

    const csvOptions = (columns) => {
        return {
            fieldSeparator: ',',
            quoteStrings: false,
            decimalSeparator: '.',
            showColumnHeaders: true,
            useBom: true,
            useKeysAsHeaders: false,
            columnHeaders: columns.map((c) => c.accessorKey),
            title: 'Organisation Statistics',
            filename: 'Organisation Statistics',

        };
    };

    // const csvExporter = new ExportToCsv(csvOptions);


    const handleExportRows = (rows) => {
        const csvConfig = mkConfig(csvOptions(columns));
        const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
        download(csvConfig)(csv);
    };
    //
    const handleExportData = () => {
        const csvConfig = mkConfig(csvOptions(columns));
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnResizing
            enableGrouping
            enableStickyHeader
            enableStickyFooter
            enableRowSelection
            initialState={{
                density: 'compact',
                expanded: true, //expand all groups by default
                grouping: [], //an array of columns to group by by default (can be multiple)
                pagination: { pageIndex: 0, pageSize: 20 },
                sorting: [], //sort by state by default
            }}
            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
            muiTableContainerProps={{ sx: { maxHeight: 700 } }}
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={table.getPreFilteredRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                            handleExportRows(table.getPreFilteredRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Rows
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        //only export selected rows
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
}

export default Stats;
