import React, { useEffect, useState } from 'react';
import { getOverallStatistics } from '../Services/Search.services';
import Stats from '../Components/Shared/Stats';
import { transformData } from '../Utils/Utils';
import { format } from 'date-fns';
import { dateFormat } from '../Constants/Date.constant';
import { Header } from '../Components/Shared/Header/Header';
import SkeletonComponent from '../Components/SkeletonComponent';
import { Filters } from '../Components/Shared/Filters/Filters';
import { Card, CardContent, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';

function OverallStatistics() {
    const [isGroupedByOrg, setIsGroupedByOrg] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [tableData, setTableData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [excludeOrganisations, setExcludeOrganisations] = useState(['avail.ai']);

    const setFilterFormValue = (filterValues) => {
        const excludedOrganizations = filterValues.excludeLines;
        const startDate = filterValues.startDate;
        const endDate = filterValues.endDate;
        const formattedStartDate = startDate && format(startDate, dateFormat);
        const formattedEndDate = endDate && format(endDate, dateFormat);
        const checkboxValue = filterValues.checkbox;

        setExcludeOrganisations(excludedOrganizations);
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setIsGroupedByOrg(checkboxValue);
    };

    useEffect(() => {
        const getRecords = () => {
            setIsLoading(true);
            const urlParam = makeParams(startDate, endDate, excludeOrganisations, isGroupedByOrg);

            getOverallStatistics(urlParam)
                .then((data) => {
                    if (!data) return;

                    const statsData = transformData(data);
                    setTableData(statsData);
                    setIsLoading(false);
                });
        };

        getRecords();
    }, [startDate, endDate, excludeOrganisations, isGroupedByOrg]);

    const makeParams = (startDate, endDate, excludeOrganisations, isGroupedByOrg) => {
        const params = new URLSearchParams();

        if (startDate) {
            params.append('startDate', startDate);
        }

        if (endDate) {
            params.append('endDate', endDate);
        }

        if (excludeOrganisations) {
            if (Array.isArray(excludeOrganisations)) {
                excludeOrganisations.forEach((org) => params.append('excludeOrganisations', org));
            } else {
                params.append('excludeOrganisations', excludeOrganisations);
            }
        }

        params.append('group_by_organisation', isGroupedByOrg);

        return '?' + params.toString();
    };

    return (
        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2, mb: 2 }}>
            <Card component={Paper}>
                <CardContent>
                    <div className="header">
                        <Header
                            title="Overall Statistics"
                            filters={(
                                <Filters
                                    isDateRange
                                    isExcludeLines
                                    isCheckbox
                                    onFiltersChange={setFilterFormValue}
                                    initialValues={{ excludeLines: excludeOrganisations }}
                                    initialFieldOptions={{ checkbox: { label: 'Group by organisation' } }}
                                />
                            )}
                        />
                    </div>
                    {
                        isLoading
                            ? <SkeletonComponent />
                            : <Stats data={tableData} />
                    }
                </CardContent>
            </Card>
        </Grid>
    );
}

OverallStatistics.propTypes = {};
export default OverallStatistics;
