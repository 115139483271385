import { Header } from '../../../../Shared/Header/Header';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AvlSwitch } from '../../../../Shared/Inputs/Switch/AvlSwitch';
import styles from './OrgDetailsTab.module.css';
import Button from '@mui/material/Button';
import {
    getOrganisationStatus,
    getOrganizationInfo,
    updateOrganisationStatus,
    updatePlan,
} from '../../../../../Services/Search.services';
import SkeletonComponent from '../../../../SkeletonComponent';
import { OrgForm } from '../../../../Shared/OrgForm/OrgForm';
import OutputSpecification from './OrgDetailsContent/OutputSpecification/OutputSpecification';
import { AvlModal } from '../../../../Shared/AvlModal/AvlModal';
import ConfirmationModal from '../../../../Shared/ConfirmationModal';

export function OrgDetailsTab({ orgName }) {
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isOrganisationUnconfirmedDisabled, setIsOrganisationUnconfirmedDisabled] = useState(false);
    const [isOrganisationDisabled, setIsOrganisationDisabled] = useState(false);
    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const isOrganisationDisabledTimeoutId = useRef(null);
    const [isEditable, setIsEditable] = useState(false);
    const [orgDetails, setOrgDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isOutputSpecificationOpen, setIsOutputSpecificationOpen] = useState(false);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    useEffect(() => {
        const asyncFunction = async () => {
            setIsEditable(false);
            const orgInfo = await getOrganizationInfo(orgName);
            const orgStatus = await getOrganisationStatus(orgName);

            if (orgInfo) {
                setOrgDetails(mapOrgDetails(orgInfo));
            }

            if (orgStatus) {
                setIsOrganisationDisabled(orgStatus);
            }

            setIsLoading(false);
        };
        void asyncFunction();
    }, [orgName]);

    const mapOrgDetails = (details) => {
        const xeroId = details.xeroContact?.split('/')
            .pop();
        const isXeroIdExists = xeroId !== 'None';
        const restrictions = JSON.parse(details.matterNumberRestriction || '');

        return {
            displayName: details.name || details.displayName,
            xeroId: isXeroIdExists ? xeroId : '',
            oneTitleCost: details.oneTitleCost,
            dayOfMonthsInvoicing: details.dayOfMonthsInvoicing,
            matterNumberPattern: restrictions.matterNumber?.pattern,
            matterNumberErrorMessage: restrictions.matterNumber?.errorMessage,
            projectNamePattern: restrictions.projectName?.pattern,
            projectNameErrorMessage: restrictions.projectName?.errorMessage,
            apps: details.apps?.split(';') ?? ['titles'],
            acceptableDocuments: details.acceptableDocuments ?? [],
            titlesDefaultExtras: details.defaultExtras ?? [],
            titleRegisterSchedules: details.schedules ?? [],
            isSchedulesEnabled: details.isSchedulesEnabled ?? false,
            isTrial: details.isTrial ?? false,
            isFlatOneTitleCost: details.isFlatOneTitleCost ?? false,
            isLandRegistryEnabled: details.landRegistry ?? false,
            isRosEnabled: details.isRosEnabled ?? false,
            isSearchesEnabled: details.isSearchesEnabled ?? false,

            // Additional fields
            directDebit: details.directDebit,
            dayOfMonthInvoicing: details.dayOfMonthInvoicing,
            hourlyRate: details.hourlyRate,
            monthlyFee: details.monthlyFee,
            subscriptionRenewalAt: details.subscriptionRenewalAt,
            subscriptionStartedAt: details.subscriptionStartedAt,
            tierName: details.tierName,
            trialEndedAt: details.trialEndedAt,
            trialStartedAt: details.trialStartedAt,
            xeroContact: details.xeroContact,
        };
    };

    const handleIsOrgDisabledChanged = async (value) => {
        const isDisable = !value;
        setIsOrganisationUnconfirmedDisabled(isDisable);
        setIsConfirmDialogVisible(true);
    };

    const handleIsOrgDisabledChangeConfirmed = async () => {
        const isDisable = isOrganisationUnconfirmedDisabled;

        setIsOrganisationDisabled(isDisable);
        setIsConfirmDialogVisible(false);

        if (isOrganisationDisabledTimeoutId.current) {
            clearTimeout(isOrganisationDisabledTimeoutId.current);
        }

        const interval = setTimeout(async () => {
            await updateOrganisationStatus(orgName, isDisable);
            isOrganisationDisabledTimeoutId.current = null;
        }, 500);
        isOrganisationDisabledTimeoutId.current = interval;
    };

    const handleFormUpdate = (orgDetails) => {
        setIsLoading(true);

        updatePlan(orgName, orgDetails)
            .then(() => setIsEditable(false))
            .catch((error) => console.error(error))
            .finally(() => {
                getOrganizationInfo(orgName)
                    .then((res) => {
                        if (!res) return;

                        setOrgDetails(mapOrgDetails(res));
                    })
                    .finally(() => setIsLoading(false));
            });
    };

    return (
        <>
            <div className="header">
                <Header
                    title={isEditable ? 'Edit Organisation Details' : 'Organisation Details'}
                    actionButtons={[
                        isAdmin && (
                            <div
                                key="is-organisation-disabled-switch"
                                className={styles.switchField}
                            >
                                <div
                                    className={`${styles.fixCenterOfSwitchField} ${styles.fixSwitchFieldRightAlign} ${styles.marginLeft5px}`}
                                >
                                    <AvlSwitch
                                        checked={!isOrganisationDisabled}
                                        onSwitch={handleIsOrgDisabledChanged}
                                    />
                                </div>
                                {
                                    isOrganisationDisabled
                                        ? (<span style={{ color: '#c51031' }}>Disabled</span>)
                                        : (<span style={{ color: '#8ac291' }}>Enabled</span>)
                                }
                            </div>
                        ),
                        isAdmin && (
                            <AvlSwitch
                                key="form"
                                label="Is the details editable"
                                disabled={isEditable && isFormDirty}
                                checked={isEditable}
                                onSwitch={setIsEditable}
                            />
                        ),
                        isAdmin && (
                            <Button
                                key="output-specification-button"
                                variant="contained"
                                onClick={() => setIsOutputSpecificationOpen(true)}
                            >
                                Output Specification
                            </Button>
                        ),
                    ]}
                />
            </div>
            <AvlModal
                isOpen={isOutputSpecificationOpen}
                onClose={() => setIsOutputSpecificationOpen(false)}
                title="Output Specification"
            >
                <OutputSpecification
                    organisation={orgName}
                    onSubmit={() => setIsOutputSpecificationOpen(false)}
                />
            </AvlModal>
            <ConfirmationModal
                open={isConfirmDialogVisible}
                handleClose={() => setIsConfirmDialogVisible(false)}
                handleConfirm={handleIsOrgDisabledChangeConfirmed}
                title="Confirm action"
                content={`Are you sure you want to ${isOrganisationDisabled ? 'enable' : 'disable'} ${orgName} organisation?`}
            />
            <div className={styles.buttonsAndForm}>
                {
                    isLoading
                        ? (<SkeletonComponent />)
                        : (
                            <OrgForm
                                isViewFieldsVisible
                                initialValues={orgDetails}
                                onSave={handleFormUpdate}
                                isEditable={isEditable}
                                onDirtyChange={setIsFormDirty}
                            />
                        )
                }
            </div>
        </>
    );
}
