import React from 'react';
import { Grid, Skeleton } from '@mui/material';

export default function SkeletonComponent(props) {
    return (
        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2, mb: 2 }}>
            <Skeleton variant="text" animation="wave" height={20} style={{ marginBottom: 6 }} width="100%" />
            <Skeleton variant="text" animation="wave" height={20} style={{ marginBottom: 6 }} width="90%" />
            <Skeleton variant="text" animation="wave" height={20} style={{ marginBottom: 6 }} width="80%" />
            <Skeleton variant="rectangular" sx={{ height: 190 }} />
        </Grid>
    );
}