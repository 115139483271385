import { styled } from '@mui/system';
import Box from '@mui/material/Box';

export const PopUpInspectBox = styled(Box)(({ theme }) => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        // bgcolor: 'background.paper',
        // border: '2px solid #000',
        // boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        backgroundColor: '#ffffff',
        maxHeight: '632px',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '164px',
            // height: '134px',
            // borderRadius: '5px',

        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '250px',
            // height: '245px',
            // borderRadius: '5px',

        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '433px',
            // height: '232px',
            // borderRadius: '5px',

        },
        [theme.breakpoints.between('lg', 'xl')]: {
            width: width * 0.85,
            // height: '100px',
            maxHeight: height * 0.85,
            // borderRadius: '5px',


        },
        [theme.breakpoints.up('xl')]: {
            width: width * 0.85,
            // height: '100%',
            maxHeight: height * 0.85,
            // borderRadius: '5px',

        },
    };
});