import { Outlet } from 'react-router-dom';
import NavbarComponent from '../Header/NavbarComponent';
import styles from './Layout.module.css';

function Layout() {
    return (
        <>
            <div className={styles.backgroundColor} />
            <NavbarComponent>
                <div className={styles.content}>
                    <Outlet />
                </div>
            </NavbarComponent>
        </>
    );
}

export default Layout;
