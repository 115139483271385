import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1)).childNodes[0];
            if (elem) {
                elem.scrollIntoView({ behavior: 'smooth', alignToTop: true, block: 'center' });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [location,]);

    return null;
}