import PropTypes from 'prop-types';
import styles from './ExcludingLines.module.css';
import { useState } from 'react';
import Chip from '@mui/material/Chip';
import { AvlTextField } from '../../Inputs/FieldText/AvlTextField';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';

export function ExcludingLines({ onChange, initialValues, label = 'Exclude lines' }) {
    const [newLine, setNewLine] = useState('');
    const [lines, setLines] = useState(initialValues || []);

    const handleKeyUp = (e) => {
        const code = e.keyCode || e.which;
        const enterId = 13;

        if (code === enterId) {
            saveNewLine();
        }
    };

    const saveNewLine = () => {
        const line = newLine?.trim();

        if (!line) return;

        if (!lines.includes(line)) {
            const updatedLines = [...lines, line];

            setLines(updatedLines);
            onChange?.(updatedLines);
        }

        setNewLine('');
    };

    const handleDelete = (deletedLine) => () => {
        setLines((lines) => {
            const updatedLines = lines.filter((line) => line !== deletedLine);
            onChange?.(updatedLines);

            return updatedLines;
        });
    };

    const handleClearAll = () => {
        setLines([]);
        onChange?.([]);
    };

    const lowerFieldSxProp = {
        '& .MuiInputBase-root': {
            height: '34px',
            marginTop: '0',
        },
        '& .MuiInputLabel-root': {
            transform: 'translate(8px, 6px) scale(1)',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -12px) scale(0.75)',
            opacity: '0',
        },
        '& .MuiInputBase-input': {
            paddingTop: '7px',
            paddingBottom: '7px',
        },
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.fieldWrapper}>
                <AvlTextField
                    className={styles.field}
                    label={label}
                    value={newLine}
                    autoComplete="off"
                    onKeyUp={handleKeyUp}
                    onChange={(e) => setNewLine(e.target.value)}
                    InputProps={{ disableUnderline: true }}
                    sx={lowerFieldSxProp}
                />
                <IconButton
                    className={`${styles.fieldClear} ${lines.length > 0 ? '' : styles.fieldClearDisabled}`}
                    aria-label="clear the lines"
                    onClick={handleClearAll}
                    disabled={!lines.length}
                >
                    <ClearIcon />
                </IconButton>
                <IconButton
                    className={styles.fieldEnter}
                    aria-label="add line"
                    onClick={() => saveNewLine()}
                >
                    <AddIcon className={styles.fieldEnterIcon} />
                </IconButton>
            </div>
            <div className={styles.chips}>
                {lines.map((line) => (
                    <Chip
                        key={line}
                        variant="outlined"
                        color="primary"
                        size="small"
                        label={line}
                        onDelete={handleDelete(line)}
                    />
                ))}
            </div>
        </div>
    );
}

ExcludingLines.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValues: PropTypes.array,
    label: PropTypes.string,
};
