import { useEffect, useState } from 'react';
import { getOutputSpecification, updateOutputSpecification } from '../../../../../../../Services/Search.services';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { AvlTextField } from '../../../../../../Shared/Inputs/FieldText/AvlTextField';
import Button from '@mui/material/Button';
import styles from './OutputSpecification.module.css';
import SkeletonComponent from '../../../../../../SkeletonComponent';
import { ThreeStatesCheckbox } from '../../../../../../Shared/Inputs/ThreeStatesCheckbox/ThreeStatesCheckbox';
import { Unit } from '../../../../../../Shared/Inputs/ImageResizerAndFields/ImageResizerAndFields';
import {
    ImageUploaderAndResizer,
} from '../../../../../../Shared/Inputs/ImageUploaderAndResizer/ImageUploaderAndResizer';

const FieldKeys = {
    id: 'id',
    cllLongTemplate: 'clls_long_template',
    cllShortTemplate: 'clls_short_template',
    cotReportsLimit: 'cot_reports_limit',
    splitWordReportsLimit: 'split_word_report_limit',
    fullWordReportLimit: 'full_word_report_limit',
    disclaimer: 'disclaimer',
    isCotReportsEnabled: 'is_cot_reports_enabled',
    organisation: 'organisation',
    outputLogo: 'output_logo',
    outputLogoHeight: 'output_logo_height',
    outputLogoWidth: 'output_logo_width',
    webIcon: 'web_icon',
    webIconHeight: 'web_icon_height',
    webIconWidth: 'web_icon_width',
};

const FieldOptions = {
    [FieldKeys.id]: {
        label: 'Id',
        isVisible: false,
        isRequired: true,
        type: 'text',
    },
    [FieldKeys.organisation]: {
        label: 'Organisation',
        isVisible: false,
        isRequired: false,
        type: 'text',
    },
    [FieldKeys.webIcon]: {
        label: 'Upload web icon',
        isVisible: true,
        isRequired: false,
        outputUnit: Unit.px,
        inputUnit: Unit.px,
        type: 'image-picker',
    },
    [FieldKeys.webIconWidth]: {
        label: 'Width (in pixels)',
        isVisible: true,
        isRequired: false,
        type: 'number',
        step: '1',
        min: 70,
        max: 600,
    },
    [FieldKeys.webIconHeight]: {
        label: 'Height (in pixels)',
        isVisible: true,
        isRequired: false,
        type: 'number',
        step: '1',
        min: 70,
        max: 600,
    },
    [FieldKeys.outputLogo]: {
        label: 'Upload output logo',
        isVisible: true,
        isRequired: true,
        outputUnit: Unit.cm,
        inputUnit: Unit.cm,
        type: 'image-picker',
    },
    [FieldKeys.outputLogoWidth]: {
        label: 'Width (in cm)',
        isVisible: true,
        isRequired: true,
        type: 'number',
        step: '0.01',
        min: 0.5,
        max: 6,
    },
    [FieldKeys.outputLogoHeight]: {
        label: 'Height (in cm)',
        isVisible: true,
        isRequired: true,
        type: 'number',
        step: '0.01',
        min: 0.5,
        max: 6,
    },
    [FieldKeys.cotReportsLimit]: {
        label: 'Cot reports limit',
        isVisible: true,
        isRequired: false,
        type: 'number',
        step: '1',
    },
    [FieldKeys.splitWordReportsLimit]: {
        label: 'Split word reports limit',
        isVisible: true,
        isRequired: false,
        type: 'number',
        step: '1',
    },
    [FieldKeys.fullWordReportLimit]: {
        label: 'Full word reports limit',
        isVisible: true,
        isRequired: false,
        type: 'number',
        step: '1',
    },
    [FieldKeys.isCotReportsEnabled]: {
        label: 'Is cot reports enabled',
        isVisible: true,
        isRequired: false,
        type: 'checkbox',
    },
    [FieldKeys.cllLongTemplate]: {
        label: 'Clls long templates',
        isVisible: true,
        isRequired: false,
        type: 'text',
    },
    [FieldKeys.cllShortTemplate]: {
        label: 'Clls short templates',
        isVisible: true,
        isRequired: false,
        type: 'text',
    },
    [FieldKeys.disclaimer]: {
        label: 'Disclaimer',
        isVisible: true,
        isRequired: false,
        type: 'text',
    },
};

function OutputSpecification({ organisation, onSubmit }) {
    const [initialData, setInitialData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const isBool = (value) => typeof value === 'boolean';

    const validationSchema = yup.object({
        [FieldKeys.cllLongTemplate]: yup.string('Please write the clls long template')
            .nullable(),
        [FieldKeys.cllShortTemplate]: yup.string('Please write the clls short template')
            .nullable(),
        [FieldKeys.cotReportsLimit]: yup.number('Please write the cot reports limit')
            .positive('Value must be a positive number')
            .nullable(),
        [FieldKeys.disclaimer]: yup.string('Please write the disclaimer')
            .nullable(),
        [FieldKeys.fullWordReportLimit]: yup.number('Please write the full word report limit')
            .positive('Value must be a positive number')
            .nullable(),
        [FieldKeys.isCotReportsEnabled]: yup.mixed()
            .nullable(),
        [FieldKeys.organisation]: yup.string('Please write the organisation name')
            .required('Organisation name is required'),
        [FieldKeys.outputLogo]: yup.string('Please upload an output logo')
            .required('Output logo is required'),
        [FieldKeys.outputLogoHeight]: yup.number('Please write the output logo height')
            .positive('Value must be a positive number')
            .required('Output logo height is required'),
        [FieldKeys.outputLogoWidth]: yup.number('Please write the output logo width')
            .positive('Value must be a positive number')
            .required('Output logo width is required'),
        [FieldKeys.webIcon]: yup.string('Please write the web icon')
            .nullable(),
        [FieldKeys.splitWordReportsLimit]: yup.number('Please write the split word report limit')
            .nullable(),
        [FieldKeys.webIconHeight]: yup.number('Please write the web icon height')
            .nullable(),
        [FieldKeys.webIconWidth]: yup.number('Please write the web icon width')
            .nullable(),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            [FieldKeys.id]: initialData[FieldKeys.id],
            [FieldKeys.cllLongTemplate]: initialData[FieldKeys.cllLongTemplate] || null,
            [FieldKeys.cllShortTemplate]: initialData[FieldKeys.cllShortTemplate] || null,
            [FieldKeys.cotReportsLimit]: initialData[FieldKeys.cotReportsLimit] || null,
            [FieldKeys.splitWordReportsLimit]: initialData[FieldKeys.splitWordReportsLimit] || null,
            [FieldKeys.fullWordReportLimit]: initialData[FieldKeys.fullWordReportLimit] || null,
            [FieldKeys.disclaimer]: initialData[FieldKeys.disclaimer] || null,
            [FieldKeys.isCotReportsEnabled]: isBool(initialData[FieldKeys.isCotReportsEnabled])
                ? initialData[FieldKeys.isCotReportsEnabled]
                : null,
            [FieldKeys.organisation]: initialData[FieldKeys.organisation] || organisation,
            [FieldKeys.outputLogo]: initialData[FieldKeys.outputLogo] || '',
            [FieldKeys.outputLogoHeight]: initialData[FieldKeys.outputLogoHeight],
            [FieldKeys.outputLogoWidth]: initialData[FieldKeys.outputLogoWidth],
            [FieldKeys.webIcon]: initialData[FieldKeys.webIcon] || '',
            [FieldKeys.webIconHeight]: initialData[FieldKeys.webIconHeight] || null,
            [FieldKeys.webIconWidth]: initialData[FieldKeys.webIconWidth] || null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const results = {};

            for (const fieldKey in values) {
                const options = FieldOptions[fieldKey];
                const isViewField = !options || !!options.isViewField;

                if (!isViewField) {
                    results[fieldKey] = values[fieldKey];
                }
            }

            const handledEmptyValuesInResult = handleEmptyValues(results);

            updateOutputSpecification(organisation, handledEmptyValuesInResult)
                .then((isSuccessful) => {
                    if (isSuccessful) {
                        onSubmit?.();
                    }
                });
        },
    });

    useEffect(() => {
        let ignore = false;

        const startFetching = async () => {
            const json = await getOutputSpecification(organisation);

            if (!ignore) {
                setInitialData(json);
                setIsLoading(false);
            }
        };

        startFetching();

        return () => {
            ignore = true;
        };
    }, [organisation]);

    const handleEmptyValues = (rawValues) => {
        const values = { ...rawValues };

        if (values.web_icon) {
            if (!values.web_icon.startsWith('data:image/png;base64,')) {
                values.web_icon = 'data:image/png;base64,' + values.web_icon;
            }
        }

        if (values.output_logo) {
            if (values.output_logo.startsWith('data:image/png;base64,')) {
                values.output_logo = values.output_logo.replace('data:image/png;base64,', '');
            }
        }

        return values;
    };

    const textField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const isError = formik.touched[fieldKey] ? !!formik.errors[fieldKey] : false;
        const helperText = formik.touched[fieldKey] && formik.errors[fieldKey];

        return (
            <AvlTextField
                className={styles.field}
                required={params.isRequired}
                label={params.label}
                name={fieldKey}
                type={params.type}
                step={params.step}
                value={formik.values[fieldKey] ?? ''}
                onChange={formik.handleChange}
                error={isError}
                helperText={helperText}
            />
        );
    };

    const imageField = (imgFieldKey, widthFieldKey, heightFieldKey) => {
        const imgParams = FieldOptions[imgFieldKey];
        const widthParams = FieldOptions[widthFieldKey];
        const heightParams = FieldOptions[heightFieldKey];
        const width = formik.values[widthFieldKey] || 0;
        const height = formik.values[heightFieldKey] || 0;
        const isImageError = formik.touched[imgFieldKey] ? !!formik.errors[imgFieldKey] : false;
        const isWidthError = formik.touched[widthFieldKey] ? !!formik.errors[widthFieldKey] : false;
        const isHeightError = formik.touched[heightFieldKey] ? !!formik.errors[heightFieldKey] : false;
        const imageHelperText = isImageError && formik.errors[imgFieldKey];
        const widthHelperText = isWidthError && formik.errors[widthFieldKey];
        const heightHelperText = isHeightError && formik.errors[heightFieldKey];

        return (
            <ImageUploaderAndResizer
                required={imgParams.isRequired}
                uploadButtonLabel={imgParams.label}
                imageSrc={formik.values[imgFieldKey] || ''}
                width={+width}
                height={+height}
                step={widthParams.step || heightParams.step}
                inputUnit={imgParams.inputUnit}
                outputUnit={imgParams.outputUnit}
                aspectRatio={+width / +height}
                onChangeImage={(fileBase64) => formik.setFieldValue(imgFieldKey, fileBase64)}
                onChangeWidth={(width) => formik.setFieldValue(widthFieldKey, width)}
                onChangeHeight={(height) => formik.setFieldValue(heightFieldKey, height)}
                isImageError={isImageError}
                isWidthError={isWidthError}
                isHeightError={isHeightError}
                widthFieldLabel={widthParams.label}
                heightFieldLabel={heightParams.label}
                imageHelperText={imageHelperText}
                widthHelperText={widthHelperText}
                heightHelperText={heightHelperText}
                minWidth={widthParams.min}
                maxWidth={widthParams.max}
            />
        );
    };

    const checkboxField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const error = formik.touched[fieldKey] ? formik.errors[fieldKey] : undefined;

        return (
            <ThreeStatesCheckbox
                label={params.label}
                name={fieldKey}
                value={formik.values[fieldKey]}
                onChange={(value) => formik.setFieldValue(fieldKey, value)}
                error={error}
            />
        );
    };

    return (
        <>
            {
                isLoading || !formik.values[FieldKeys.id]
                    ? (<SkeletonComponent />)
                    : (
                        <div className={styles.form}>
                            <div className={styles.sections}>
                                <div className={styles.section}>
                                    {textField(FieldKeys.cllLongTemplate)}
                                    {textField(FieldKeys.cllShortTemplate)}
                                    {textField(FieldKeys.disclaimer)}
                                    {checkboxField(FieldKeys.isCotReportsEnabled)}
                                </div>
                                <div className={styles.section}>
                                    {textField(FieldKeys.cotReportsLimit)}
                                    {textField(FieldKeys.fullWordReportLimit)}
                                    {textField(FieldKeys.splitWordReportsLimit)}
                                </div>
                                <div className={`${styles.section} ${styles.imageEditor} ${styles.outputLogo}`}>
                                    {imageField(FieldKeys.outputLogo, FieldKeys.outputLogoWidth, FieldKeys.outputLogoHeight)}
                                </div>
                                <div className={`${styles.section} ${styles.imageEditor} ${styles.webLogo}`}>
                                    {imageField(FieldKeys.webIcon, FieldKeys.webIconWidth, FieldKeys.webIconHeight)}
                                </div>
                            </div>
                            <div className={styles.controlsContainer}>
                                <Button
                                    className="button"
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    disabled={isLoading || !formik.dirty}
                                    onClick={() => formik.resetForm()}
                                >
                                    Reset
                                </Button>
                                <Button
                                    className="button"
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    disabled={isLoading || !formik.dirty}
                                    onClick={() => {
                                        formik.submitForm()
                                            .catch(console.error);
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    )
            }
        </>
    );
}

export default OutputSpecification;
