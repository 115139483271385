import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './Header.module.css';

export function Header({ title, actionButtons, filters }) {
    return (
        <div className={styles.header}>
            <div className={styles.titleAndFilters}>
                {title && (<Typography className={styles.title} variant="h5">{title}</Typography>)}
                <div className={styles.filters}>
                    {filters}
                </div>
            </div>
            <div className={styles.actionButtons}>
                {actionButtons}
            </div>
        </div>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    actionButtons: PropTypes.array,
    filters: PropTypes.element,
};
