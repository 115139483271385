import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Pagination } from '@mui/material';
import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination';

/**
 *
 * @param headers is a list of header names (strings)
 * @param rows is a list of AvlTableCell objects lists.
 *
 * type AvlTableCell {
 *      align: string,
 *      style: {},
 *      data: {},
 * }
 * @param pageCount is a total count of pages
 * @param page is an index of the current page
 * @param onPageChange is a callback function on page change event
 * @param onChangeRowsPerPage is a callback function on rows per page change event
 * @param rowsPerPage is a number of rows per page amount
 * @param rowsCount is a number of rows amount
 * @param isRowsPerPageOptionsVisible is a boolean value. Set true, if you want to show rows per page indicator.
 *  False by default
 * @param rowsPerPageOptions is a list of options of rows per page indicator
 */
export function AvlTable({
    headers,
    rows,
    pageCount,
    page,
    onPageChange,
    onChangeRowsPerPage,
    rowsPerPage,
    rowsCount,
    isRowsPerPageOptionsVisible = false,
    rowsPerPageOptions = [10, 20, 30, 50, 100],
}) {
    const handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        onChangeRowsPerPage(rowsPerPage);
    };

    const normalizedHandlerPageChange = (event, page) => {
        onPageChange(event, page + 1);
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                headers.map((header, headerIndex) => (
                                    <TableCell
                                        key={headerIndex}
                                        align={header.align}
                                    >
                                        {header.label}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {
                                        row.cells.map((cell, cellIndex) => (
                                            <TableCell
                                                key={`${rowIndex}:${cellIndex}`}
                                                align={cell.align}
                                                style={cell.style}
                                            >
                                                {
                                                    row.isCellWrapped
                                                        ? (
                                                            <div
                                                                style={{
                                                                    minHeight: '42px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    flexDirection: 'column',
                                                                }}
                                                            >
                                                                {cell.data}
                                                            </div>
                                                        )
                                                        : cell.data
                                                }
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ marginTop: '20px' }}>
                {isRowsPerPageOptionsVisible
                    ? (
                        <TablePagination
                            showFirstButton
                            showLastButton
                            component="div"
                            rowsPerPageOptions={rowsPerPageOptions}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            onPageChange={normalizedHandlerPageChange}
                            count={rowsCount}
                            page={page - 1}
                        />
                    ) : (
                        <Pagination
                            showFirstButton
                            showLastButton
                            count={pageCount}
                            page={page}
                            siblingCount={1}
                            boundaryCount={1}
                            variant="outlined"
                            shape="rounded"
                            onChange={onPageChange}
                        />
                    )}
            </div>
        </>
    );
}

Table.propTypes = {
    headers: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    pageCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};
