import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';

export function AvlCheckbox({ label = '', value = false, onChange, isDisabled, ...props }) {
    const sx = !isDisabled
        ? {
            '.MuiFormControlLabel-label.Mui-disabled': {
                color: 'rgba(0, 0, 0, 0.87)',
            },
        }
        : undefined;

    return (
        <FormControlLabel
            disabled={props.disabled}
            onChange={onChange}
            name={props.name}
            key={props.key}
            checked={value}
            label={label}
            control={(
                <Checkbox
                    indeterminate={props.indeterminate}
                />
            )}
            sx={props.sx || sx}
        />
    );
}

AvlCheckbox.propTypes = {
    label: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    props: PropTypes.object,
};
