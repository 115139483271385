import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FieldKeys } from './MissingInvoicesTable';
import Button from '@mui/material/Button';
import styles from './MissingInvoicesTableFilters.module.css';
import { ThreeStatesCheckbox } from '../../../../Shared/Inputs/ThreeStatesCheckbox/ThreeStatesCheckbox';

const lowerFieldSxProp = {
    '& .MuiInputBase-root': {
        height: '36px',
    }, '& .MuiInputLabel-root': {
        transform: 'translate(12px, 7px) scale(1)',
    }, '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -12px) scale(0.75)',
    }, '& .MuiInputBase-input': {
        paddingTop: '8px', paddingBottom: '8px',
    }, '& .MuiCheckbox-root': {
        paddingTop: '6px', paddingBottom: '6px',
    },
};

export default function MissingInvoicesTableFilters({ invoices, onFilterChange }) {
    const [id, setId] = useState('');
    const [text, setText] = useState('');
    const [isTrial, setIsTrial] = useState(null);

    useEffect(() => {
        handleFilterUpdate({});
    }, [invoices]);

    const handleIdChange = (event) => {
        const id = event.target.value;
        setId(id);
        handleFilterUpdate({ idValue: id });
    };

    const handleTextChange = (event) => {
        const text = event.target.value;
        setText(text);
        handleFilterUpdate({ textValue: text });
    };

    const handleIsTrialChange = (isTrial) => {
        setIsTrial(isTrial);
        handleFilterUpdate({ isTrialValue: isTrial });
    };

    const handleReset = () => {
        setId('');
        setText('');
        setIsTrial(null);
        handleFilterUpdate({
            idValue: '',
            textValue: '',
            isTrial: null,
        });
    };

    const handleFilterChange = (id, text, isTrial) => {
        const filteredById = !id ? invoices : invoices.filter((invoice) => {
            const folderIdFilter = invoice[FieldKeys.folderId]?.includes(id);
            const documentIdFilter = invoice[FieldKeys.documentId]?.includes(id);
            const purchaseIdFilter = invoice[FieldKeys.purchaseId]?.includes(id);
            const transactionIdFilter = invoice[FieldKeys.transactionId]?.includes(id);
            const messageIdFilter = invoice[FieldKeys.messageId]?.includes(id);

            return !!folderIdFilter
                || !!documentIdFilter
                || !!purchaseIdFilter
                || !!transactionIdFilter
                || !!messageIdFilter;
        });
        const filteredByText = !text ? filteredById : filteredById.filter((invoice) => {
            const referenceFilter = invoice[FieldKeys.reference]?.includes(text);
            const oTypeFilter = invoice[FieldKeys.oType]?.includes(text);
            const userNameFilter = invoice[FieldKeys.userName]?.includes(text);
            const messageFilter = invoice[FieldKeys.message]?.includes(text);

            return !!referenceFilter
                || !!oTypeFilter
                || !!userNameFilter
                || !!messageFilter;
        });
        const filteredByIsTrial = isTrial === null ? filteredByText : filteredByText.filter((invoice) => {
            const isTrialFieldValue = invoice[FieldKeys.isTrial];

            return isTrialFieldValue === isTrial;
        });

        onFilterChange(filteredByIsTrial);
    };

    const handleFilterUpdate = ({ idValue, textValue, isTrialValue }) => {
        handleFilterChange(
            idValue === undefined ? id : idValue,
            textValue === undefined ? text : textValue,
            isTrialValue === undefined ? isTrial : isTrialValue,
        );
    };

    return (
        <div className={styles.controls}>
            <TextField
                label={'ID'}
                value={id}
                placeholder={'Search ID'}
                onChange={handleIdChange}
                sx={lowerFieldSxProp}
            />
            <TextField
                label={'Search Text'}
                value={text}
                placeholder={'Text'}
                onChange={handleTextChange}
                sx={lowerFieldSxProp}
            />
            <ThreeStatesCheckbox
                label={'Is Trial'}
                value={isTrial}
                onChange={(value) => handleIsTrialChange(value)}
            />
            <Button variant="outlined" onClick={handleReset}>Reset</Button>
        </div>
    );
}
