import './index.css';
import './styles.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'firebase/auth';
import { initScripts } from './Utils';
import packageVersion from '../package.json';
import { env } from './config';
import * as Sentry from '@sentry/react';
import * as settings from './settings';
import store from './store';
import { checkAuth, loading } from './Store/Actions/AuthenticationActions';
import { StrictMode, useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

Sentry.init({
    dsn: settings.SENTRY_DNS,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

// Window Variables
// ------------------------------------
window.version = packageVersion;
window.env = env;
initScripts();

store.dispatch(loading());
store.dispatch(checkAuth());
const root = createRoot(document.getElementById('root'));

const theme = createTheme({
    spacing: (factor) => {
        const computedSpacing = 8 * factor; // Default spacing calculation
        return Math.min(computedSpacing, 15) + 'px'; // Ensure it doesn't exceed 32px
    },
});
root.render(
    <StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </Provider>
    </StrictMode>,
);

