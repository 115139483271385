import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import styles from './AvlModal.module.css';

export function AvlModal({ onClose, isOpen, title, actionButtons, children, maxWidth = '900px' }) {
    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            sx={{
                '& .MuiPaper-root': {
                    width: '90vw',
                    maxWidth: maxWidth,
                },
            }}
        >
            {[
                !!title && (
                    <DialogTitle key="customized-dialog-title" id="customized-dialog-title" className={styles.header}>
                        {title}
                    </DialogTitle>
                ),
                !!onClose && (
                    <IconButton
                        key="close-button"
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )
            ]}
            <DialogContent dividers>
                {children}
            </DialogContent>
            {
                !!actionButtons?.length && (
                    <DialogActions>
                        {actionButtons}
                    </DialogActions>
                )
            }
        </Dialog>
    );
}

AvlModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    actionButtons: PropTypes.array,
    children: PropTypes.object.isRequired,
};
