import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAuditDataOfFolder } from '../../Services/Search.services';
import Grid from '@mui/material/Grid';
import { Pagination } from '@mui/material';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { datetimeToFormattedString } from '../../Utils/Utils';
import SkeletonComponent from '../SkeletonComponent';

const logColumns = [
    {
        id: 'performed_at',
        label: 'Performed\u00a0At',
        minWidth: 170,
        align: 'left',
        format: (value) => datetimeToFormattedString(value),
    },
    {
        id: 'folder_id',
        label: 'Folder\u00a0Id',
        align: 'left',
        minWidth: 170,
    },
    {
        id: 'app',
        label: 'App',
        align: 'center',
        minWidth: 50,
    },
    {
        id: 'user_name',
        label: 'User Name',
        minWidth: 170,
        align: 'left',
    },
    {
        id: 'operation',
        label: 'Operation',
        minWidth: 170,
        align: 'left',
    },
    {
        id: 'path',
        label: 'Path',
        minWidth: 170,
        align: 'left',
    },
    {
        id: 'status_code',
        label: 'Status\u00a0Code',
        minWidth: 50,
        align: 'center',
        format: (value) => Math.floor(value),
    },
];

function FolderAudits({ folderId }) {
    const [records, setRecords] = useState([]);
    const [limit, setLimit] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        getRecords(page);
    }, []);

    const getRecords = async (page) => {
        const paramUrl = makeParams(page);
        const response = await getAuditDataOfFolder(folderId, paramUrl);
        if (response.status === 200) {
            setRecords(response.data['folder_audit']);
            setLimit(response.data.limit);
            let count = Number(response.data.count);
            let limit = Number(response.data.limit);
            let pcount = Math.ceil((count / limit));
            setPageCount(pcount);
            setIsLoading(false);
        } else {
            setRecords([]);
            setLimit(10);
            setPageCount(1);
            setIsLoading(true);
        }
    };

    const handlePageChange = (event, value) => {
        setIsLoading(true);
        setPage(value);
        getRecords(value);
    };

    const makeParams = (pagination_page) => {
        let urlParam = '';
        urlParam = '?limit=' + limit;
        if (page) {
            urlParam += '&offset=' + ((pagination_page - 1) * limit);
        }
        return urlParam;
    };

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ mt: 2, mb: 2, width: '100% !important' }}
            id={'audit-logs-information-grid'}
        >
            {
                isLoading
                    ? (<SkeletonComponent />)
                    : (
                        <>
                            {
                                records.length ? (
                                    <>
                                        <TableContainer sx={{ maxHeight: '400px', height: '400px' }}>
                                            <Table sx={{ minWidth: 650 }} aria-label="user table">
                                                <TableHead>
                                                    <TableRow>
                                                        {logColumns.map((column, index) => (
                                                            <TableCell
                                                                key={column.id + index}
                                                                align={column.align}
                                                                style={{ minWidth: column.minWidth }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {records.map((row, index) => {
                                                        return (
                                                            <TableRow
                                                                key={index}
                                                                sx={{
                                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                                }}
                                                            >
                                                                {logColumns.map((column, index) => {
                                                                    const value = row[column.id];
                                                                    return (
                                                                        <TableCell
                                                                            key={column.id + index}
                                                                            align={column.align}
                                                                        >
                                                                            {column.format !== undefined
                                                                                ? column.format(value)
                                                                                : value}
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 1, mb: 2, ml: 2 }}>
                                            <Stack spacing={2}>
                                                {/*<Pagination count={3} showFirstButton showLastButton /> */}
                                                <Pagination
                                                    showFirstButton
                                                    showLastButton
                                                    count={pageCount}
                                                    page={page}
                                                    siblingCount={1}
                                                    boundaryCount={1}
                                                    variant="outlined"
                                                    shape="rounded"
                                                    onChange={handlePageChange}
                                                />
                                            </Stack>
                                        </Grid>
                                    </>
                                ) : (
                                    <Stack sx={{ width: '100% !important', height: '460px' }} spacing={2}>
                                        <Alert severity="warning">Records not found!</Alert>
                                    </Stack>
                                )
                            }
                        </>
                    )
            }
        </Grid>
    );
}

FolderAudits.propTypes = {
    folderId: PropTypes.string.isRequired,
};
export default FolderAudits;
