import { useState } from 'react';
import { UploadFileButton } from '../UploadFileButton/UploadFileButton';
import styles from './ImageUploaderAndResizer.module.css';
import { useImageHelper } from '../../../../Hooks/ImageHelper.hook';
import { ImageResizerAndFields } from '../ImageResizerAndFields/ImageResizerAndFields';
import { useCmAndPixelsConverter } from '../../../../Hooks/CmAndPixelsConverter.hook';

const defaultAspectRatio = 1;

export function ImageUploaderAndResizer({ ...props }) {
    const { unitToCm } = useCmAndPixelsConverter();
    const [aspectRatio, setAspectRatio] = useState(props.aspectRatio || defaultAspectRatio);
    const { getImageInBase64, normalyzeToBase64, getImageDimensions } = useImageHelper();

    const setUploadedImageInBase64 = async (file) => {
        const dimensions = { width: 1, height: 1 };

        try {
            const { width, height } = await getImageDimensions(file);
            dimensions.width = width;
            dimensions.height = height;
        } catch (error) {
            console.error(error);
        }

        const aspectRatio = dimensions.width / dimensions.height;
        const width = Math.min(unitToCm(dimensions.width), props.maxWidth);
        const height = width / aspectRatio;

        setAspectRatio(aspectRatio);
        props.onChangeWidth(width);
        props.onChangeHeight(height);
    };

    const handleChangeUploadingFile = async (file) => {
        props.onChangeImage(null);

        if (file) {
            const imageBase64 = await getImageInBase64(file)
                .catch(console.error);
            const base64WithoutPrefix = imageBase64.substring(imageBase64.indexOf(',') + 1);

            file['base64'] = base64WithoutPrefix;
            props.onChangeImage(base64WithoutPrefix);
            await setUploadedImageInBase64(file);
        }
    };

    const imageInBase64 = normalyzeToBase64(props.imageSrc || '');

    return (
        <div className={styles.uploadAndPreviewArea}>
            <div className={styles.imageErrorAndPreview}>
                <UploadFileButton
                    error={props.isImageError}
                    helperText={props.imageHelperText}
                    label={props.uploadButtonLabel}
                    onChange={handleChangeUploadingFile}
                />
                {
                    props.isImageError && (
                        <p className={styles.imageErrorText}>{props.imageHelperText}</p>
                    )
                }
            </div>
            <ImageResizerAndFields
                required={props.required}
                imageInBase64={imageInBase64}
                width={props.width}
                height={props.height}
                inputUnit={props.inputUnit}
                outputUnit={props.outputUnit}
                aspectRatio={aspectRatio}
                isAspectRatioIgnored={aspectRatio === defaultAspectRatio}
                onChangeWidth={props.onChangeWidth}
                onChangeHeight={props.onChangeHeight}
                isWidthError={props.isWidthError}
                isHeightError={props.isHeightError}
                widthFieldLabel={props.widthFieldLabel}
                heightFieldLabel={props.heightFieldLabel}
                imageHelperText={props.imageHelperText}
                widthHelperText={props.widthHelperText}
                heightHelperText={props.heightHelperText}
                minWidth={props.minWidth}
                maxWidth={props.maxWidth}
                step={props.step}
            />
        </div>
    );
}
