import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { AvlTextField } from '../FieldText/AvlTextField';

export function AvlAutocomplete({ label = '', options = [], onChange, isDisabled, ...props }) {
    const sx = !isDisabled
        ? {
            '.MuiChip-root.Mui-disabled': {
                opacity: '1',
            },
        }
        : undefined;

    return (
        <Autocomplete
            className={props.className}
            multiple={props.multiple}
            disabled={props.disabled}
            onBlur={props.onBlur}
            freeSolo={props.freeSolo}
            value={props.value}
            onChange={onChange}
            onInputChange={props.onInputChange}
            includeInputInList
            onClose={props.onClose}
            disableCloseOnSelect={props.disableCloseOnSelect}
            noOptionsText={props.noOptionsText}
            options={options}
            getOptionLabel={(option) => option.toString()}
            isOptionEqualToValue={(option, value) => {
                const isMultiple = props.isMultiple || false;

                return isMultiple
                    ? !!value?.length && value.includes(option)
                    : option === value;
            }}
            key={props.key}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option}
                        key={index}
                        {...getTagProps({ index })}
                    />
                ))}
            renderInput={(params) => (
                <AvlTextField
                    {...params}
                    label={label}
                    name={props.name}
                    required={props.required}
                    value={props.value || ''}
                    checked={props.checked}
                    error={props.error}
                    helperText={props.helperText}
                />
            )}
            sx={sx}
        />
    );
}

AvlAutocomplete.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    props: PropTypes.object,
};
