import axios from 'axios';
import { toast } from 'react-toastify';
import { getAuth } from 'firebase/auth';
import store from '../store';
import { checkAuth, loading } from '../Store/Actions/AuthenticationActions';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    validateStatus: function (status) {
        return status >= 200 && status < 400;
    },
});

axiosInstance.interceptors.request.use(async function (config) {
    const auth = getAuth();

    await auth.currentUser.getIdToken();
    const accessToken = localStorage.getItem('testtoken') || auth?.currentUser?.accessToken;

    if (!accessToken) {
        throw new Error('Auth token is missing');
    }

    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
});

let retryCount = 0;
const maxRetries = 3;
const retryDelayMs = 1000;

axiosInstance.interceptors.response.use((response) => response, async (error) => {
    if (axios.isCancel(error)) {
        return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        localStorage.removeItem('testtoken');
        store.dispatch(loading());
        originalRequest._retry = true;

        try {
            const auth = getAuth();
            await auth.currentUser.getIdToken(true);
            const accessToken = auth?.currentUser?.accessToken;

            if (!accessToken) {
                throw new Error('Auth token is missing');
            }

            store.dispatch(checkAuth());
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

            return axiosInstance(originalRequest);
        } catch (refreshError) {
            console.error('Token refreshing error:', refreshError);
            store.dispatch(error(`Error during token refreshing: ${refreshError.toString()}`));
        }
    }

    switch (error.response.status) {
        case 502:
            if (retryCount < maxRetries) {
                retryCount++;
                toast.warning(`Connection problem, retrying... (${retryCount}/${maxRetries})`, { toastId: 'retryConnection' });

                return new Promise((resolve) => setTimeout(() => resolve(axios(error.config)), retryDelayMs));
            } else {
                retryCount = 0;
                toast.error('You are having connection problems', { toastId: 'connection' });

                return Promise.reject(error);
            }
    }

    toast.error('You are having problems with your access points', { toastId: 'connectionAccess' });

    return Promise.reject(error);
});

export default axiosInstance;
