import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

export function AvlSwitch({ label = '', checked = false, onChange, onSwitch, ...props }) {
    const handleChange = (event) => {
        const value = event.target.checked;

        onChange?.(event);
        onSwitch?.(value);
    };

    return (
        <FormControlLabel
            key={props.key}
            name={props.name}
            onBlur={props.onBlur}
            onKeyUp={props.onKeyUp}
            disabled={props.disabled}
            label={label}
            checked={checked}
            control={(
                <Switch onChange={handleChange} />
            )}
        />
    );
}

AvlSwitch.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    onSwitch: PropTypes.func,
    props: PropTypes.object,
};
