import { addMinutes, format } from 'date-fns';
import { dateFormat, datetimeFormat } from '../Constants/Date.constant';

export const getTitle = (type) => {
    let title = '';

    switch (type) {
        case 'title-plan':
            title = 'Title Plans';
            break;
        case 'title-register':
            title = 'Title Registers';
            break;
        case 'linked-documents':
            title = 'Filed Copies';
            break;
        case 'linked-documents-analysis':
            title = 'Filed Copies Analysis';
            break;
        case 'digital-mapping':
            title = 'Mapping & Highways';
            break;
        case 'planning':
            title = 'Planning Reports';
            break;
        case 'digital-title-plan':
            title = 'Mapping - Individual Title Plans';
            break;
        case 'companies-house':
            title = 'Corporate Crosschecks';
            break;
        case 'compiled-aerial-plan':
            title = 'Mapping - Aerial Compiled Plan';
            break;
        case 'compiled-digital-title-plan':
            title = 'Mapping - OS Compiled Plans';
            break;
        case 'upload':
            title = 'Uploaded';
            break;
        case 'ownership':
            title = 'Company Name';
            break;
        case 'lease':
            title = 'Leases';
            break;
        case 'analysed':
            title = 'Analysed';
            break;
        case 'scottish-title':
            title = 'Scottish Titles';
            break;

        case 'unknown':
            title = 'Unknown Documents';
            break;
        case 'epc':
            title = 'EPC Reports';
            break;
        case 'title-number':
            title = 'Title Number';
            break;
        case 'address':
            title = 'Address';
            break;
        case 'company-number':
            title = 'Company Number';
            break;
        case 'land-registry':
            title = 'Purchased';
            break;
        case 'scoping-compiled-aerial-plan':
            title = 'Mapping - Scoping Compiled Plan';
            break;
        default:
            title = type;
            break;
    }

    return title;
};

export const getHeader = (type) => {
    let header = '';
    switch (type) {
        case 'documents':
            header = 'Documents Analysed';
            break;
        case 'purchases':
            header = 'Purchases';
            break;
        case 'scheduleOrders':
            header = 'Report Upgrades';
            break;
        case 'titleSearch':
            header = 'Title Searches';
            break;
    }
    return header;
};

const simplifyDateRange = (dateRange) => {
    const dates = dateRange.split(' - ');
    const startDate = dates[0].split(' ')[0];
    const endDate = dates[1].split(' ')[0];
    return `${startDate} - ${endDate}`;
};

export const transformData = (apiResponse) => {
    const rows = apiResponse.rows;

    if (!rows || !rows.length) {
        return [];
    }

    const types = ['documents', 'purchases', 'scheduleOrders', 'titleSearch'];

    let result = [];

    for (let row of rows) {
        for (let type of types) {
            if (row[type] && row[type].length) {
                for (let item of row[type]) {
                    let name = item.length > 2
                        ? `${getTitle(item[0])} ${getTitle(item[item.length - 2])}`
                        : getTitle(item[0]);
                    // let name = getTitle(rawName);
                    let numbers = item[item.length - 1];

                    result.push({
                        'Name': name,
                        'Numbers': numbers.toLocaleString('en-GB'),
                        'Type': getHeader(type),
                        'Period': simplifyDateRange(row.period),
                    });
                }
            }
        }
    }

    return result;
};

export const transformDataForOverallReport = (apiResponse) => {
    const rows = apiResponse.rows;

    if (!rows || !rows.length) {
        return [];
    }

    let result = [];

    for (let row of rows) {
        for (let key in row) {
            if (Object.prototype.hasOwnProperty.call(row[key], 'name')) { // Check if the current key is one of the categories we're interested in
                const item = row[key];

                result.push({
                    'name': item.name,
                    'numberOfActions': item.numberOfActions,
                    'hoursSaved': item.hoursSaved,
                    'costSaved': item.costSaved,
                    'period': apiResponse.period,
                });
            }
        }
    }

    return result;
};

export const datetimeToFormattedStringUtc = (date, emptyReplace = '-') => {
    if (!date) {
        return emptyReplace;
    }

    const dateWithoutTimezone = removeTimezoneOffset(new Date(date));

    return format(dateWithoutTimezone, datetimeFormat);
};

export const dateToFormattedStringUtc = (date, emptyReplace = '-') => {
    if (!date) {
        return emptyReplace;
    }

    const dateWithoutTimezone = removeTimezoneOffset(new Date(date));

    return format(dateWithoutTimezone, dateFormat);
};

export const datetimeToFormattedString = (date, emptyReplace = '-') => {
    return date ? format(new Date(date), datetimeFormat) : emptyReplace;
};

export const dateToFormattedString = (date, emptyReplace = '-') => {
    return date ? format(new Date(date), dateFormat) : emptyReplace;
};

export const extractDateFromIso = (iso) => {
    if (!iso) return iso;

    return iso.split('T')[0];
};

export const extractDateFromString = (dateString) => {
    const iso = new Date(dateString)
        .toISOString();

    return extractDateFromIso(iso);
};

export const addZeroTimeToIso = (iso) => {
    return `${extractDateFromString(iso)} 00:00:00`;
};

export const removeTimezoneOffset = (dateWithTimezone) => {
    return addMinutes(dateWithTimezone, dateWithTimezone.getTimezoneOffset());
};

export const toIsoIgnoreTimezone = (dateWithTimezone, isSecondsDisabled = false) => {
    const hours = dateWithTimezone.getHours()
        .toString()
        .padStart(2, '0');
    const minutes = dateWithTimezone.getMinutes()
        .toString()
        .padStart(2, '0');
    const seconds = dateWithTimezone.getSeconds()
        .toString()
        .padStart(2, '0');
    const milliseconds = dateWithTimezone.getMilliseconds()
        .toString()
        .padStart(3, '0');
    const year = dateWithTimezone.getFullYear()
        .toString()
        .padStart(2, '0');
    const month = (dateWithTimezone.getMonth() + 1)
        .toString()
        .padStart(2, '0');
    const day = dateWithTimezone.getDate()
        .toString()
        .padStart(2, '0');
    const date = `${year}-${month}-${day}`;

    return isSecondsDisabled
        ? `${date}T${hours}:${minutes}:00.000`                      // ISO: '2024-10-02T13:21:00.000'
        : `${date}T${hours}:${minutes}:${seconds}.${milliseconds}`; // ISO: '2024-10-02T13:21:21.378Z'
};
