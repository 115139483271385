import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

export function AvlChip({ ...props }) {
    const theme = useTheme();

    const handleDelete = (id) => {
        props.onDelete?.(id);
    };

    const handleClick = (id) => {
        props.onClick?.(id);
    };

    return (
        <Chip
            inputProps={props.inputProps}
            variant={props.variant || 'standard'}
            InputProps={props.InputProps}
            size="small"
            onDelete={!props.onDelete ? undefined : () => handleDelete(props.id)}
            onClick={!props.onClick ? undefined : () => handleClick(props.id)}
            label={props.label}
            style={{
                backgroundColor: props.color,
                color: theme.palette.getContrastText(props.color),
            }}
            sx={{
                '& .MuiChip-deleteIcon': {
                    color: theme.palette.getContrastText(props.color),

                    '&:hover': {
                        color: theme.palette.getContrastText(props.color),
                        opacity: 0.8,
                    },
                },
            }}
        />
    );
}
