import { getAuth, OAuthProvider, SAMLAuthProvider } from 'firebase/auth';
import { AuthActionTypes } from '../Store/Actions/AuthenticationTypes';
import firebase from '../config';
import { getUserInfo } from './Search.services';

export function createOAuthProvider(providerId) {
    let provider;

    if (providerId.startsWith('oidc')) {
        provider = new OAuthProvider(providerId);
        provider.setCustomParameters({ returnIdpCredential: false });
    } else {
        if (!providerId.startsWith('saml.')) {
            providerId = `saml.${providerId}`;
        }

        provider = new SAMLAuthProvider(providerId);
    }

    return provider;
}

export async function signInToFirebase(dispatch, signInMethodCallback) {
    dispatch({ type: AuthActionTypes.signInStart });
    const authentication = getAuth(firebase);

    try {
        const credentials = await signInMethodCallback(authentication);
        const currentUser = credentials.user;
        const idTokenResult = await currentUser.getIdTokenResult();

        if (!idTokenResult.claims?.console) {
            handleAuthError(dispatch, 'Invalid roles');
            return;
        }

        try {
            await getUserInfo(idTokenResult.claims.email);

            if (idTokenResult.claims.admin) dispatch({ type: AuthActionTypes.admin });

            if (idTokenResult.claims.console) {
                dispatch({
                    type: AuthActionTypes.successSignIn,
                    payload: idTokenResult.token,
                });
                window.location.replace('/');
            } else {
                dispatch({
                    type: AuthActionTypes.fail,
                    payload: 'Access denied',
                });
            }
        } catch (error) {
            handleAuthError(dispatch, error);
        }
    } catch (error) {
        handleFirebaseError(dispatch, error);
    }
}

export function handleAuthError(dispatch, message) {
    dispatch({
        type: AuthActionTypes.fail,
        payload: message,
    });
}

export function handleFirebaseError(dispatch, error) {
    const firebaseErrorCode = error.code;
    let errorMessage = '';

    switch (firebaseErrorCode) {
        case 'auth/user-not-found':
            errorMessage = 'User disabled or not found';
            break;
        case 'auth/network-request-failed':
            errorMessage = 'Authorisation request failed. Try again later';
            break;
    }

    dispatch({
        type: AuthActionTypes.fail,
        payload: errorMessage || firebaseErrorCode,
    });
}
