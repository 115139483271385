import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { createNewUser, getOrganizationInfo } from '../../../../../../Services/Search.services';
import SkeletonComponent from '../../../../../SkeletonComponent';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import CustomPaginationActionsTable from './CustomPaginationActionsTable';
import styles from './OrgAccountTableSingleAdd.module.css';
import { AvlTextField } from '../../../../../Shared/Inputs/FieldText/AvlTextField';

export default function OrgAccountTableSingleAdd({ orgName }) {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getOrganizationInfo(orgName)
            .then((res) => {
                if (res) {
                    setIsLoading(false);
                }
            });
    }, [orgName]);

    const ValidationSchema = yup.object({
        email: yup.string()
            .email()
            .required(),
        displayName: yup.string()
            .required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            displayName: '',
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            setUsers([...users, values]);
            toast.success('The user has been added to the List');
            formik.resetForm();
        },
    });

    const handleCreateUsers = () => {
        users.forEach((user) => {
            void createNewUser(user);
        });
    };

    const handleClearUsers = () => {
        setUsers([]);
    };

    return isLoading
        ? (<SkeletonComponent />)
        : (
            <div className={styles.formAndTable}>
                <form
                    className={styles.userForm}
                    onSubmit={formik.handleSubmit}
                    noValidate="novalidate"
                >
                    <AvlTextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <AvlTextField
                        required
                        id="displayName"
                        name="displayName"
                        label="Display Name"
                        value={formik.values.displayName}
                        onChange={formik.handleChange}
                        error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                        helperText={formik.touched.displayName && formik.errors.displayName}
                    />
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => formik.submitForm()}
                    >
                        Add
                    </Button>
                </form>
                {
                    !!users.length && (
                        <div className={styles.tableAndButton}>
                            <div className={styles.saveAndClearButtons}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={handleClearUsers}
                                >
                                    Clear
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        handleCreateUsers();
                                        handleClearUsers();
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                            <div className={styles.table}>
                                <CustomPaginationActionsTable rows={users} />
                            </div>
                        </div>
                    )
                }
            </div>
        );
}
