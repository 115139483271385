import * as yup from 'yup';
import { AvlTextField } from '../Inputs/FieldText/AvlTextField';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { AvlCheckbox } from '../Inputs/Checkbox/AvlCheckbox';
import { AvlAutocomplete } from '../Inputs/Autocomplete/AvlAutocomplete';
import styles from './OrgForm.module.css';
import PropTypes from 'prop-types';
import { UrlActionType, UrlButton } from '../UrlButton/UrlButton';
import {
    RegexMatcher,
} from '../../BodyComponent/Organizations/Tabs/OrgDetailsTab/OrgDetailsContent/RegexMatcher/RegexMatcher';
import { useEffect } from 'react';

const optionsForAcceptableDocuments = [
    'title-register',
    'scottish-title',
    'title-plan',
];

const optionsForTitlesDefaultExtras = [
    'app-enquiry',
    'discharge',
];

const optionsForTitleRegisterSchedules = [
    'compiled-digital-title-plan',
    'linked-documents-analysis',
    'digital-title-plan',
    'linked-documents',
    'digital-mapping',
    'companies-house',
    'app-enquiry',
    'title-plan',
    'discharge',
    'planning',
    'epc',
];

const optionsForApps = [
    'notices',
    'titles',
    'leases',
];

const defaultValues = {
    organisation: '',
    displayName: '',
    xeroId: '',
    oneTitleCost: 0,
    dayOfMonthInvoicing: '',
    matterNumberPattern: '.+',
    matterNumberErrorMessage: 'Client/Matter Number is required',
    projectNamePattern: '.+',
    projectNameErrorMessage: 'Project Name is required',
    acceptableDocuments: ['title-register', 'scottish-title', 'title-plan'],
    titlesDefaultExtras: ['app-enquiry', 'discharge'],
    titleRegisterSchedules: [
        'companies-house',
        'title-plan',
        'digital-mapping',
        'planning',
        'linked-documents',
        'linked-documents-analysis',
        'epc',
    ],
    apps: ['titles'],
    isSchedulesEnabled: true,
    isTrial: true,
    isRosEnabled: false,
    isSearchesEnabled: false,
    isFlatOneTitleCost: false,
    isLandRegistryEnabled: true,

    // Additional fields values
    dayOfMonthsInvoicing: '',
    directDebit: '',
    hourlyRate: '',
    monthlyFee: '',
    subscriptionStartedAt: '',
    subscriptionRenewalAt: '',
    tierName: '',
    trialEndedAt: '',
    trialStartedAt: '',
    xeroContact: '',
};

const FieldKeys = {
    organisation: 'organisation',
    displayName: 'displayName',
    xeroId: 'xeroId',
    oneTitleCost: 'oneTitleCost',
    dayOfMonthsInvoicing: 'dayOfMonthsInvoicing',
    matterNumberPattern: 'matterNumberPattern',
    matterNumberErrorMessage: 'matterNumberErrorMessage',
    projectNamePattern: 'projectNamePattern',
    projectNameErrorMessage: 'projectNameErrorMessage',
    acceptableDocuments: 'acceptableDocuments',
    titlesDefaultExtras: 'titlesDefaultExtras',
    titleRegisterSchedules: 'titleRegisterSchedules',
    apps: 'apps',
    isSchedulesEnabled: 'isSchedulesEnabled',
    isTrial: 'isTrial',
    isRosEnabled: 'isRosEnabled',
    isSearchesEnabled: 'isSearchesEnabled',
    isFlatOneTitleCost: 'isFlatOneTitleCost',
    isLandRegistryEnabled: 'isLandRegistryEnabled',

    // Additional fields: the fields are useless (transparent) for edit and add operations;
    directDebit: 'directDebit',
    dayOfMonthInvoicing: 'dayOfMonthInvoicing',
    monthlyFee: 'monthlyFee',
    hourlyRate: 'hourlyRate',
    subscriptionRenewalAt: 'subscriptionRenewalAt',
    subscriptionStartedAt: 'subscriptionStartedAt',
    tierName: 'tierName',
    trialEndedAt: 'trialEndedAt',
    trialStartedAt: 'trialStartedAt',
    xeroContact: 'xeroContact',
};

const FieldOptions = {
    [FieldKeys.organisation]: {
        label: 'Organisation Key',
        isViewField: true,
        isForAddModeRequired: true,
        isRequired: true,
        type: 'text',
    },
    [FieldKeys.displayName]: {
        label: 'Display Name',
        isRequired: true,
        type: 'text',
    },
    [FieldKeys.xeroId]: {
        label: 'Xero ID',
        isRequired: true,
        type: 'text',
    },
    [FieldKeys.oneTitleCost]: {
        label: 'One title cost',
        isRequired: false,
        type: 'number',
        step: '0.01',
    },
    [FieldKeys.dayOfMonthsInvoicing]: {
        label: 'Day of months invoicing',
        isRequired: false,
        isViewField: true,
        type: 'number',
        step: '1',
    },
    [FieldKeys.isFlatOneTitleCost]: {
        label: 'Is flat one title cost',
        isRequired: false,
        type: 'checkbox',
    },
    [FieldKeys.matterNumberPattern]: {
        label: 'Matter number pattern',
        isRequired: false,
        type: 'text',
    },
    [FieldKeys.matterNumberErrorMessage]: {
        label: 'Matter number error message',
        isRequired: false,
        type: 'text',
    },
    [FieldKeys.projectNamePattern]: {
        label: 'Project number pattern',
        isRequired: false,
        type: 'text',
    },
    [FieldKeys.projectNameErrorMessage]: {
        label: 'Project number error message',
        isRequired: false,
        type: 'text',
    },
    [FieldKeys.apps]: {
        label: 'Apps',
        isRequired: true,
        type: 'autocomplete',
        options: optionsForApps,
    },
    [FieldKeys.acceptableDocuments]: {
        label: 'Acceptable documents',
        isRequired: false,
        type: 'autocomplete',
        options: optionsForAcceptableDocuments,
    },
    [FieldKeys.titlesDefaultExtras]: {
        label: 'Title default extras',
        isRequired: false,
        type: 'autocomplete',
        options: optionsForTitlesDefaultExtras,
    },
    [FieldKeys.titleRegisterSchedules]: {
        label: 'Title register schedules',
        isRequired: false,
        type: 'autocomplete',
        options: optionsForTitleRegisterSchedules,
    },
    [FieldKeys.isSchedulesEnabled]: {
        label: 'Is schedules enabled',
        isRequired: false,
        type: 'checkbox',
    },
    [FieldKeys.isLandRegistryEnabled]: {
        label: 'Is land register enabled',
        isRequired: false,
        type: 'checkbox',
    },
    [FieldKeys.isTrial]: {
        label: 'Is trial',
        isRequired: false,
        type: 'checkbox',
    },
    [FieldKeys.isRosEnabled]: {
        label: 'Is ROS enabled',
        isRequired: false,
        type: 'checkbox',
    },
    [FieldKeys.isSearchesEnabled]: {
        label: 'Is searches enabled',
        isRequired: false,
        type: 'checkbox',
    },

    // Additional fields options
    [FieldKeys.dayOfMonthInvoicing]: {
        label: 'Day of month invoicing',
        isRequired: false,
        type: 'number',
        step: '1',
    },
    [FieldKeys.monthlyFee]: {
        label: 'Monthly fee',
        isRequired: false,
        type: 'number',
        step: '1',
    },
    [FieldKeys.hourlyRate]: {
        label: 'Hourly rate',
        isRequired: false,
        type: 'number',
        step: '1',
    },
    [FieldKeys.subscriptionStartedAt]: {
        label: 'Subscription started date',
        isRequired: false,
        isViewField: true,
        type: 'date',
    },
    [FieldKeys.subscriptionRenewalAt]: {
        label: 'Subscription renewal date',
        isRequired: false,
        isViewField: true,
        type: 'date',
    },
    [FieldKeys.tierName]: {
        label: 'Tier name',
        isRequired: false,
        isViewField: true,
        type: 'text',
    },
    [FieldKeys.trialStartedAt]: {
        label: 'Trial start date',
        isRequired: false,
        isViewField: true,
        type: 'date',
    },
    [FieldKeys.trialEndedAt]: {
        label: 'Trial end date',
        isRequired: false,
        isViewField: true,
        type: 'date',
    },
    [FieldKeys.xeroContact]: {
        label: 'Xero contact',
        isRequired: false,
        isViewField: true,
        type: 'date',
    },
};

export function OrgForm(
    {
        onSave,
        initialValues = defaultValues,
        onDirtyChange,
        isAddMode = false,
        isEditable = isAddMode,
        isViewFieldsVisible = false,
        isLoading = false,
    },
) {
    const validationSchema = yup.object({
        organisation:
            isAddMode
            && yup
                .string('Enter your organisation name (str)')
                .required('Organisation name is required'),
        displayName: yup
            .string('Please write the Display name (str)')
            .required('Display name is required'),
        xeroId: yup
            .string('Please write the Xero ID (str)')
            .required('Xero ID is required'),
        oneTitleCost:
            yup.mixed()
                .nullable()
                .when('type', {
                    is: (type) => type,
                    then: yup
                        .number('Please write the One Title Cost (number)')
                        .test(
                            'is-decimal',
                            'invalid decimal eg: 10.98',
                            (value) => (value + '').match(/^\d*\.?\d*$/),
                        ),
                    else: yup.string()
                        .nullable(true),
                }),
        matterNumberPattern: yup
            .string('Please write the Matter Number Pattern (str)')
            .required('Matter Number Pattern is required'),
        matterNumberErrorMessage: yup
            .string('Please write the Matter Number Error Message (str)')
            .required('Matter Number Error Message is required'),
        projectNamePattern: yup
            .string('Please write the Project Name Pattern (str)')
            .required('Project Name Pattern is required'),
        projectNameErrorMessage: yup
            .string('Please write the Project Name Error Message (str)')
            .required('Project Name Error Message is required'),
        acceptableDocuments: yup.array(),
        titlesDefaultExtras: yup.array(),
        titleRegisterSchedules: yup.array(),
        apps: yup
            .array()
            .required('Apps is required')
            .min(1, 'At least one app is required'),
        isSchedulesEnabled: yup
            .boolean('Please write the Is Schedules Enabled (boolean)')
            .required('Is Schedules Enabled is required'),
        isRosEnabled: yup
            .boolean('Please write the Is ROS Enabled (boolean)')
            .required('Is ROS Enabled is required'),
        isSearchesEnabled: yup
            .boolean('Please write the Is Searches Enabled (boolean)')
            .required('Is Searches Enabled is required'),
        isTrial: yup
            .boolean('Please write the Is Trial (boolean)')
            .required('Is Trial is required'),
        dayOfMonthInvoicing: yup
            .number('Please write the Day of Month Invoicing (number 1-31)')
            .max(31, 'Day of month invoicing must be 31 or less')
            .min(1, 'Day of month invoicing must be 1 or more')
            .nullable()
            .when('$isFilled', (isFilled, schema) => isFilled ? schema.required('Day of month is required') : schema),
        monthlyFee: yup
            .number('Please write the Monthly Fee (number)')
            .nullable()
            .min(0, 'Monthly Fee must be 0 or positive'),
        hourlyRate: yup
            .number('Please write the Hourly Rate (number)')
            .nullable()
            .min(0, 'Monthly Fee must be 0 or positive'),
        isFlatOneTitleCost: yup
            .boolean('Please write the Is Flat One Title Cost (boolean)')
            .required('Is Flat One Title Cost is required'),
        isLandRegistryEnabled: yup
            .boolean('Please write the Is Land Registry Enabled (boolean)')
            .required('Is Land Registry Enabled is required'),
    });

    const formik = useFormik({
        initialValues: {
            ...defaultValues,
            ...initialValues,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const results = {};

            for (const fieldKey in values) {
                const options = FieldOptions[fieldKey];
                const isViewField = !options || !!options.isViewField;
                const isForAddModeRequired = isAddMode && options?.isForAddModeRequired;

                if (!isViewField || isForAddModeRequired) {
                    results[fieldKey] = values[fieldKey];
                }
            }

            const handledEmptyValuesInResult = handleEmptyValues(results);
            onSave?.(handledEmptyValuesInResult);
        },
    });

    const handleEmptyValues = (rawValues) => {
        const values = { ...rawValues };

        values.apps = values.apps.join(';');
        values.isSchedulesEnabled ||= false;
        values.isTrial ||= false;
        values.isFlatOneTitleCost ||= false;
        values.isLandRegistryEnabled ||= false;
        values.dayOfMonthInvoicing ||= null;
        values.oneTitleCost ||= null;

        if (!values[FieldKeys.monthlyFee] && values[FieldKeys.monthlyFee] !== 0) {
            values[FieldKeys.monthlyFee] = null;
        }

        if (!values[FieldKeys.hourlyRate] && values[FieldKeys.hourlyRate] !== 0) {
            values[FieldKeys.hourlyRate] = null;
        }

        return values;
    };

    const textField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const isError = formik.touched[fieldKey] ? !!formik.errors[fieldKey] : false;
        const helperText = formik.touched[fieldKey] && formik.errors[fieldKey];

        return (
            <AvlTextField
                className={styles.field}
                disabled={!isEditable}
                required={params.isRequired}
                label={params.label}
                name={fieldKey}
                type={params.type}
                step={params.step}
                value={formik.values[fieldKey] ?? ''}
                onChange={formik.handleChange}
                error={isError}
                helperText={helperText}
            />
        );
    };

    const viewField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const value = initialValues[fieldKey] ?? defaultValues[fieldKey];
        const isDataExists = !!value || value === 0;

        if (!isViewFieldsVisible) return false;

        return (
            <AvlTextField
                disabled
                className={styles.field}
                label={params.label}
                name={fieldKey}
                type={isDataExists ? params.type : ''}
                step={isDataExists ? params.step : undefined}
                value={isDataExists ? value : 'No data available'}
            />
        );
    };

    const autocompleteField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const error = formik.touched[fieldKey] ? formik.errors[fieldKey] : undefined;
        const helperText = formik.touched[fieldKey] && formik.errors[fieldKey];

        return (
            <AvlAutocomplete
                multiple
                disabled={!isEditable}
                required={params.isRequired}
                label={params.label}
                name={fieldKey}
                options={params.options}
                value={formik.values[fieldKey]}
                onChange={(event, value) => formik.setFieldValue(fieldKey, value)}
                error={error}
                helperText={helperText}
            />
        );
    };

    const checkboxField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const checked = formik.values[fieldKey] ? formik.values[fieldKey] : false;
        const error = formik.touched[fieldKey] ? formik.errors[fieldKey] : undefined;

        return (
            <AvlCheckbox
                disabled={!isEditable}
                label={params.label}
                name={fieldKey}
                value={formik.values[fieldKey]}
                onChange={formik.handleChange}
                checked={checked}
                error={error}
            />
        );
    };

    useEffect(() => {
        onDirtyChange?.(formik.dirty);
    }, [formik.dirty, onDirtyChange]);

    return (
        <div className={styles.form}>
            <div className={styles.controlsContainer}>
                {isEditable && formik.dirty
                    && (
                        <>
                            <Button
                                className="button"
                                variant="outlined"
                                size="small"
                                color="primary"
                                disabled={isLoading}
                                onClick={() => formik.resetForm()}
                            >
                                Reset
                            </Button>
                            <Button
                                className="button"
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={isLoading}
                                onClick={() => {
                                    formik.submitForm()
                                        .catch(console.error);
                                }}
                            >
                                {isAddMode ? 'Create' : 'Save'}
                            </Button>
                        </>
                    )}
            </div>
            <div className={styles.sections}>
                <div className={styles.section}>
                    {isAddMode && textField(FieldKeys.organisation)}
                    {textField(FieldKeys.displayName)}
                    <div className={styles.fieldWithControl}>
                        {textField(FieldKeys.xeroId)}
                        {
                            initialValues.xeroContact
                            && (
                                <UrlButton
                                    buttonStyles={{
                                        link: { width: '32px', height: '32px' },
                                    }}
                                    action={UrlActionType.openInNewTab}
                                    url={initialValues.xeroContact}
                                />
                            )
                        }
                    </div>
                    {viewField(FieldKeys.subscriptionStartedAt)}
                    {viewField(FieldKeys.subscriptionRenewalAt)}
                    {initialValues[FieldKeys.isTrial] && viewField(FieldKeys.trialStartedAt)}
                    {initialValues[FieldKeys.isTrial] && viewField(FieldKeys.trialEndedAt)}
                    {checkboxField(FieldKeys.isTrial)}
                </div>
                <div className={styles.section}>
                    {textField(FieldKeys.matterNumberPattern)}
                    {textField(FieldKeys.matterNumberErrorMessage)}
                    {textField(FieldKeys.projectNamePattern)}
                    {textField(FieldKeys.projectNameErrorMessage)}
                    <RegexMatcher
                        className={styles.field}
                        regex={formik.values.matterNumberPattern}
                        label="Matter number to match"
                    />
                </div>
                <div className={styles.section}>
                    {textField(FieldKeys.oneTitleCost)}
                    {viewField(FieldKeys.dayOfMonthsInvoicing)}
                    {textField(FieldKeys.dayOfMonthInvoicing)}
                    {textField(FieldKeys.monthlyFee)}
                    {textField(FieldKeys.hourlyRate)}
                    {viewField(FieldKeys.tierName)}
                    {checkboxField(FieldKeys.isFlatOneTitleCost)}
                </div>
                <div className={`${styles.section} ${styles.wideSection}`}>
                    {autocompleteField(FieldKeys.apps)}
                    {autocompleteField(FieldKeys.acceptableDocuments)}
                    {autocompleteField(FieldKeys.titlesDefaultExtras)}
                    {autocompleteField(FieldKeys.titleRegisterSchedules)}
                    <div className={styles.checkboxesGroup}>
                        {checkboxField(FieldKeys.isSchedulesEnabled)}
                        {checkboxField(FieldKeys.isSearchesEnabled)}
                    </div>
                    <div className={styles.checkboxesGroup}>
                        {checkboxField(FieldKeys.isLandRegistryEnabled)}
                        {checkboxField(FieldKeys.isRosEnabled)}
                    </div>
                </div>
            </div>
        </div>
    );
}

OrgForm.propTypes = {
    onSave: PropTypes.func,
    initialValues: PropTypes.object,
    isAddMode: PropTypes.bool,
    isEditable: PropTypes.bool,
    isLoading: PropTypes.bool,
    isViewFieldsVisible: PropTypes.bool,
    onDirtyChange: PropTypes.func,
};
