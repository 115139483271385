import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import PropTypes from 'prop-types';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

function FileOptions({ image, description, formatList, onClickFunction, folderId, fileName }) {
    const handleClick = (e) => {
        e.preventDefault();
        onClickFunction({ folderId, fileName, formatList });
    };
    return (
        <Card
            sx={{
                width: '300px',
                height: '100px',
            }}
            onClick={(e) => handleClick(e)}

        >
            <CardActionArea
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '10px',
                }}
            >
                <img
                    height="40px"
                    width="40px"
                    src={process.env.PUBLIC_URL + image}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h7" component="div">
                        {description}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {formatList.join(', ')}
                    </Typography>
                </CardContent>
                {/*show this icon when we hover*/}
                <DownloadOutlinedIcon
                    style={{
                        color: 'grey',
                    }}

                />
            </CardActionArea>
        </Card>
    );
}

FileOptions.propTypes = {
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};


export default FileOptions;
