import createStore from './Store/CreateStore';


const initialState = window.___INITIAL_STATE__ || {
    auth: {
        isAuthenticated: null,
        isLoading: false,
        isAdmin: null,
        isConsole: null,
        token: null,
        error: null,
    }
};
const store = createStore(initialState);


export default store;
