import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styles from './ListWithOtherOrgsIssues.module.css';
import { UrlActionType, UrlButton } from '../../Shared/UrlButton/UrlButton';

export function ListWithOtherOrgsIssues({ issueName, items }) {
    return (
        <div className={styles.section}>
            <div>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{ fontWeight: 700 }}
                >
                    {issueName}
                </Typography>
            </div>
            <div className={styles.organisations}>
                {
                    items.map((item, index) => (
                        <Typography key={index} className={styles.organisation}>
                            <UrlButton
                                description={'Search it in a new tab'}
                                url={`/?q=${item}`}
                                action={UrlActionType.openInNewTab}
                            />
                            {item}
                        </Typography>
                    ))
                }
            </div>
        </div>
    );
}

ListWithOtherOrgsIssues.propTypes = {
    issueName: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
};
