import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { createNewUser, getOrganizationInfo } from '../../../../../../Services/Search.services';
import SkeletonComponent from '../../../../../SkeletonComponent';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as XLSX from 'xlsx';
import { InfoMark } from '../../../../../Shared/InfoMark/InfoMark';
import styles from './OrgAccountTableBulkAdd.module.css';
import CustomPaginationActionsTable from './CustomPaginationActionsTable';

export default function OrgAccountTableBulkAdd({ orgName }) {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const arrayValidationSchema = yup.array()
        .of(
            yup.object()
                .shape({
                    email: yup.string()
                        .email()
                        .required(),
                    displayName: yup.string()
                        .required(),
                })
                .required('Required'));

    useEffect(() => {
        getOrganizationInfo(orgName)
            .then((res) => {
                if (res !== null) {
                    setIsLoading(false);
                }
            });
    }, [orgName]);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result;
                let readedData = XLSX.read(binaryStr, { type: 'binary' });
                readedData.SheetNames.forEach(function (sheetName) {
                    // Here is your object
                    let XL_row_object = XLSX.utils.sheet_to_row_object_array(readedData.Sheets[sheetName]);
                    let json_object = JSON.stringify(XL_row_object);
                    // validate with yup arrayValidationSchema
                    arrayValidationSchema.validate(JSON.parse(json_object))
                        .then((res) => {
                            toast.success('Excel Data Validated');
                            setUsers(JSON.parse(json_object));
                        })
                        .catch((err) => {
                            toast.error(`Bad Excel ${err}`);
                            setUsers([]);
                        });
                    // For every dictionary in the list add a key value of uploaded: false
                });
            };
            reader.readAsArrayBuffer(file);
        });

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const { ref, ...rootProps } = getRootProps();

    const handleCreateUsers = () => {
        users.forEach((user) => {
            void createNewUser(user);
        });
    };

    const handleClearUsers = () => {
        setUsers([]);
    };

    return isLoading
        ? (<SkeletonComponent />)
        : (
            <div className={styles.uploadAreaAndTable}>
                {
                    !users.length
                        ? (
                            <div className={styles.uploadAreaWrapper}>
                                <Paper className={styles.uploadArea} {...rootProps}>
                                    <InfoMark classNames={[styles.infoMark]}>
                                        The format consists of two columns: &apos;email&apos;(A1) and
                                        &apos;displayName&apos;(A2). Each row represents a user entry,
                                        where the
                                        &apos;email&apos; column contains the user&apos;s email address,
                                        and the
                                        &apos;displayName&apos; column contains the user&apos;s display
                                        name.
                                    </InfoMark>
                                    <CloudUploadIcon fontSize="large" style={{ marginRight: '10px' }} />
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive
                                            ? (<p>Drop the Excel files here...</p>)
                                            : (<p>Drag &apos;n&apos; drop some Excel files here, or click to select files</p>)
                                    }
                                </Paper>
                            </div>
                        ) : (
                            <div className={styles.tableAndButton}>
                                <div className={styles.saveAndClearButtons}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        onClick={handleClearUsers}
                                    >
                                        Clear
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            handleCreateUsers();
                                            handleClearUsers();
                                        }}
                                    >
                                        Save
                                    </Button>
                                </div>
                                <div className={styles.table}>
                                    <CustomPaginationActionsTable rows={users} />
                                </div>
                            </div>
                        )
                }
            </div>
        );
}
