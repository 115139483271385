import Grid from '@mui/material/Grid';
import { Alert, Pagination, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { getInvoicingData } from '../../../../../../Services/Search.services';
import SkeletonComponent from '../../../../../SkeletonComponent';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FeedIcon from '@mui/icons-material/Feed';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { datetimeToFormattedString } from '../../../../../../Utils/Utils';

function OrgInvoicingTable({ orgName, onOpenEditPage }) {
    const [invoicingData, setInvoicingData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const makeParams = (pagination_page) => {
        let urlParam = 'offset=' + offset;
        urlParam = 'limit=' + limit;
        if (page) {
            urlParam += '&offset=' + (pagination_page - 1);
        }

        return urlParam;
    };

    const getRecords = (page) => {
        const urlParam = makeParams(page);

        getInvoicingData(orgName, urlParam)
            .then((res) => {
                const dataResp = res;
                const count = Number(dataResp.count);
                const limit = Number(dataResp.limit);
                const pcount = Math.ceil((count / limit));

                setInvoicingData(res.invoicing);
                setOffset(dataResp.offset);
                setLimit(dataResp.limit);
                setPageCount(pcount);
                setLoading(false);
            });
    };

    useEffect(() => {
        getRecords(page);
    }, [orgName]);

    const handlePageChange = (event, value) => {
        setLoading(true);
        setPage(value);
        getRecords(value);
    };

    return (
        <>
            {loading
                ? (<SkeletonComponent />)
                : (
                    invoicingData.length > 0
                        ? (
                            <Grid container item spacing={1} xs={12} sm={12} md={12} sx={{ ml: 2, mt: 2, mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="user table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Id</TableCell>
                                                <TableCell>Account</TableCell>
                                                <TableCell align='center'>Enabled</TableCell>
                                                <TableCell>Kind</TableCell>
                                                <TableCell>CC(Emails)</TableCell>
                                                <TableCell>To(Email)</TableCell>
                                                <TableCell align='center'>Day Of Month</TableCell>
                                                <TableCell align='center'>Frequency</TableCell>
                                                <TableCell align='center'>Frequency Months</TableCell>
                                                <TableCell>Last At</TableCell>
                                                <TableCell>Start Invoicing At</TableCell>
                                                <TableCell align='center'>Subject Template</TableCell>
                                                <TableCell align='center'>Body Template</TableCell>
                                                <TableCell align='center'>Edit Invoicing</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoicingData.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>{row.id || '-'}</TableCell>
                                                    <TableCell component="th" scope="row">{row.account}</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: '20px' }}>
                                                        {row.enabled ? '✓' : '×'}
                                                    </TableCell>
                                                    <TableCell>{row.kind || '-'}</TableCell>
                                                    <TableCell>{row.emailsCc?.replaceAll(';', '; ') || '-'}</TableCell>
                                                    <TableCell>{row.emailsTo || '-'}</TableCell>
                                                    <TableCell align="center">{row.dayOfMonth || '-'}</TableCell>
                                                    <TableCell align="center">{row.frequency || '-'}</TableCell>
                                                    <TableCell align="center">{row.frequencyMonths || '-'}</TableCell>
                                                    <TableCell>{datetimeToFormattedString(row.lastAt)}</TableCell>
                                                    <TableCell>{datetimeToFormattedString(row.startInvoicingAt)}</TableCell>
                                                    <TableCell align="center">
                                                        {row.subjectTpl ? (
                                                            <Tooltip title={row.subjectTpl}>
                                                                <IconButton>
                                                                    <FeedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : '-'}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.bodyTpl ? (
                                                            <Tooltip title={row.bodyTpl}>
                                                                <IconButton>
                                                                    <FeedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : '-'}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                onClick={() => {
                                                                    const invoicingData = {
                                                                        id: row.id,
                                                                        spec: row.spec,
                                                                        kind: row.kind,
                                                                        bodyTpl: row.bodyTpl,
                                                                        account: row.account,
                                                                        enabled: row.enabled,
                                                                        emailsCc: row.emailsCc,
                                                                        emailsTo: row.emailsTo,
                                                                        frequency: row.frequency,
                                                                        subjectTpl: row.subjectTpl,
                                                                        dayOfMonth: row.dayOfMonth,
                                                                        startInvoicingAt: row.startInvoicingAt,
                                                                    };

                                                                    onOpenEditPage?.(invoicingData);
                                                                }}
                                                            >
                                                                <ModeEditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid item xs={12} sm={12} md={12} sx={{ mt: 1, mb: 2 }}>
                                    <Stack spacing={2}>
                                        <Pagination
                                            showFirstButton
                                            showLastButton
                                            count={pageCount}
                                            page={page}
                                            siblingCount={1}
                                            boundaryCount={1}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handlePageChange}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        ) : (
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="warning">Records not found!</Alert>
                            </Stack>
                        )
                )}
        </>
    );
}

export default OrgInvoicingTable;
