import firebase from '../../config';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { getUserInfo } from '../../Services/Search.services';
import { AuthActionTypes } from './AuthenticationTypes';
import {
    createOAuthProvider,
    handleAuthError,
    handleFirebaseError,
    signInToFirebase,
} from '../../Services/Auth.services';

export const error = (errorMessage) => async (dispatch) => {
    handleAuthError(dispatch, errorMessage);
};

export const loading = () => async (dispatch) => {
    dispatch({ type: AuthActionTypes.loading });
};

export const signInToFirebaseByPassword = (email, password) => async (dispatch) => {
    await signInToFirebase(dispatch, (authentication) => signInWithEmailAndPassword(authentication, email, password));
};

export const signInToFirebaseBySso = (providerId) => async (dispatch) => {
    const provider = createOAuthProvider(providerId);
    await signInToFirebase(dispatch, (authentication) => signInWithPopup(authentication, provider));
};

export const signOutFromFirebase = () => async (dispatch) => {
    dispatch({ type: AuthActionTypes.logoutStart });
    const authentication = getAuth(firebase);

    try {
        await signOut(authentication);
        window.location.replace('/login');
        dispatch({ type: AuthActionTypes.logout });
    } catch (error) {
        handleFirebaseError(dispatch, error);
    }
};

export const checkAuth = () => async (dispatch) => {
    const authentication = getAuth(firebase);

    try {
        authentication.onAuthStateChanged(async (user) => {
            if (!user) {
                dispatch({ type: AuthActionTypes.logout });
                return;
            }

            try {
                await getUserInfo(user.email);
            } catch (error) {
                dispatch({ type: AuthActionTypes.logout });
                handleAuthError(dispatch, error);
            }

            try {
                const idTokenResult = await user.getIdTokenResult();
                const isAdmin = !!idTokenResult.claims.admin;
                const hasAccess = !!idTokenResult.claims.console;

                if (!hasAccess) {
                    handleAuthError(dispatch, 'Access denied');
                    if (!window.location.href.includes('/login')){
                        window.location.replace('/login');
                    }

                    return;
                }

                dispatch({
                    type: AuthActionTypes.refreshToken,
                    payload: user.accessToken,
                });

                if (isAdmin) dispatch({ type: AuthActionTypes.admin });
            } catch (error) {
                handleFirebaseError(dispatch, error);
            }
        });
    } catch (error) {
        handleFirebaseError(dispatch, error);
    }
};
