export const env = 'local';


// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
// import * as firebase from "firebase/app";
// import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';

// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
};

const firebase = initializeApp(firebaseConfig);

// Finally, export it to use it throughout your app
export default firebase;
