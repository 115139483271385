import PropTypes from 'prop-types';
import { AvlTextField } from '../FieldText/AvlTextField';
import { useCmAndPixelsConverter } from '../../../../Hooks/CmAndPixelsConverter.hook';
import { ImageResizer } from '../ImageResizer/ImageResizer';
import styles from './ImageResizerAndFields.module.css';

export const Unit = {
    px: 'px',
    cm: 'cm',
};

const UnitFix = {
    [Unit.px]: 0,
    [Unit.cm]: 2,
};

export function ImageResizerAndFields(
    {
        uploadButtonLabel = 'Choose file',
        width,
        height,
        imageInBase64,
        aspectRatio,
        isAspectRatioIgnored = false,
        outputUnit = Unit.px,
        inputUnit = Unit.px,
        onChangeImage,
        onChangeWidth,
        onChangeHeight,
        minWidth,
        maxWidth,
        ...props
    },
) {
    const { pixelToCm, unitToPixels } = useCmAndPixelsConverter();

    const minWidthInPx = unitToPixels(minWidth, inputUnit);
    const maxWidthInPx = unitToPixels(maxWidth, inputUnit);
    const widthInPx = unitToPixels(width, inputUnit);
    const heightInPx = unitToPixels(height, inputUnit);

    const getWidthByHeight = (height) => height * aspectRatio;

    const getHeightByWidth = (width) => width / aspectRatio;

    const handleChangeWidthField = (e) => {
        const widthFromField = +e.target.value;

        if (isAspectRatioIgnored) {
            onChangeWidth?.(widthFromField);
            return;
        }

        const calculatedHeight = widthFromField / aspectRatio;

        const width = widthFromField.toFixed(UnitFix[outputUnit]);
        const height = calculatedHeight.toFixed(UnitFix[outputUnit]);

        onChangeWidth?.(+width);
        onChangeHeight?.(+height);
    };

    const handleChangeHeightField = (e) => {
        const heightFromField = +e.target.value;

        if (isAspectRatioIgnored) {
            onChangeHeight?.(heightFromField);
            return;
        }

        const calculatedWidth = heightFromField * aspectRatio;

        const width = calculatedWidth.toFixed(UnitFix[outputUnit]);
        const height = heightFromField.toFixed(UnitFix[outputUnit]);

        onChangeWidth?.(+width);
        onChangeHeight?.(+height);
    };

    const handleChangeWidthByImageResizer = (pixels) => {
        const widthIncludeUnit = outputUnit === Unit.cm
            ? pixelToCm(pixels)
            : pixels;

        onChangeWidth?.(widthIncludeUnit);
        onChangeHeight?.(getHeightByWidth(widthIncludeUnit));
    };

    const handleChangeHeightByImageResizer = (pixels) => {
        const heightIncludeUnit = outputUnit === Unit.cm
            ? pixelToCm(pixels)
            : pixels;

        onChangeWidth?.(getWidthByHeight(heightIncludeUnit));
        onChangeHeight?.(heightIncludeUnit);
    };

    return (
        <div className={styles.resizerAndFields}>
            <ImageResizer
                aspectRatio={aspectRatio}
                imageInBase64={imageInBase64}
                widthInPx={widthInPx}
                heightInPx={heightInPx}
                onChangeWidth={handleChangeWidthByImageResizer}
                onChangeHeight={handleChangeHeightByImageResizer}
                onChangeImage={onChangeImage}
                maxWidthInPx={maxWidthInPx}
                maxHeightInPx={(maxWidthInPx / aspectRatio) || maxWidthInPx}
                minWidthInPx={minWidthInPx}
                minHeightInPx={(minWidthInPx / aspectRatio) || minWidthInPx}
            />
            <div className={styles.fields}>
                <AvlTextField
                    key="image-resizer-width"
                    className={styles.field}
                    required={props.required}
                    label={props.widthFieldLabel}
                    type="number"
                    step={props.step}
                    value={+width.toFixed(UnitFix[inputUnit])}
                    onChange={handleChangeWidthField}
                    error={props.isWidthError}
                    helperText={props.widthHelperText}
                    min={!imageInBase64 ? 0 : minWidth}
                    max={!imageInBase64 ? 9999999 : maxWidth}
                />
                <AvlTextField
                    key="image-resizer-height"
                    className={styles.field}
                    required={props.required}
                    label={props.heightFieldLabel}
                    type="number"
                    step={props.step}
                    value={+height.toFixed(UnitFix[inputUnit])}
                    onChange={handleChangeHeightField}
                    error={props.isHeightError}
                    helperText={props.heightHelperText}
                    min={!imageInBase64 ? 0 : minWidth}
                    max={!imageInBase64 ? 9999999 : maxWidth}
                />
            </div>
        </div>
    );
}

ImageResizerAndFields.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    imageInBase64: PropTypes.string.isRequired,
    uploadButtonLabel: PropTypes.string,
    onChangeImage: PropTypes.func,
    onChangeWidth: PropTypes.func,
    onChangeHeight: PropTypes.func,
    props: PropTypes.object,
};
