import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { NavLink, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import firebase from '../../config';
import { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

export default function ResetPassword() {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [firebaseError, setFirebaseError] = useState('');
    const navigate = useNavigate();

    const validationSchema = yup.object({
        email: yup
            .string()
            .email('Please wright valid email. Ex. email@gmail.com')
            .required('Email is required'),
    });

    const handleSubmit = (values) => {
        const email = values['email'];
        const authentication = getAuth(firebase);

        setIsButtonDisabled(true);
        setFirebaseError('');

        sendPasswordResetEmail(authentication, email)
            .then(() => {
                toast.success('Password reset link has been sent to the email address!');
                navigate('/login');
            })
            .catch((error) => {
                setIsButtonDisabled(false);

                if (error.code === 'auth/wrong-password') {
                    setFirebaseError('Wrong email or password');
                }

                if (error.code === 'auth/user-not-found') {
                    setFirebaseError('Please check the Email');
                }
            });
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <Typography component="h1" variant="h5">
                Request reset password
            </Typography>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '400px',
                    width: '100%',
                    marginTop: '8px',
                }}
            >
                {!!firebaseError && (
                    <Stack sx={{ width: '100%', padding: '20px 0' }} spacing={2}>
                        <Alert severity="error">{firebaseError}</Alert>
                    </Stack>
                )}
                <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={formik.handleChange}
                    autoFocus
                    error={!!formik.errors.email}
                    helperText={formik.errors.email}
                />
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isButtonDisabled}
                    onClick={() => formik.handleSubmit()}
                >
                    Request
                </Button>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        alignItems: 'center',
                    }}
                >
                    <NavLink style={{ textDecoration: 'none' }} to="/login" variant="body">
                        Back to login by password
                    </NavLink>
                    <NavLink style={{ textDecoration: 'none' }} to="/sso" variant="body">
                        Back to login by SSO
                    </NavLink>
                </div>
            </div>
        </>
    );
}
