import { useMemo, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { MaterialReactTable, MRT_EditActionButtons, useMaterialReactTable } from 'material-react-table';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DifferenceIcon from '@mui/icons-material/Difference';
import ConfirmationModal from '../../Shared/ConfirmationModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    createFeatureFlag,
    deleteFeatureFlag,
    getFeatureFlags,
    updateFeatureFlag,
} from '../../../Services/Search.services';
import {
    dateToFormattedString,
    extractDateFromIso,
    toIsoIgnoreTimezone,
} from '../../../Utils/Utils';
import styles from '../../Shared/InvoicingForm/InvoicingForm.module.css';
import { dateFormat } from '../../../Constants/Date.constant';
import { AvlDatePickerField } from '../../Shared/Inputs/DatePicker/AvlDatePickerField';

export function FeatureFlagsTable() {
    const [validationErrors, setValidationErrors] = useState({});
    const [editedValues, setEditedValues] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');

    const openDeleteFeatureFlagConfirmModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedRow(null);
    };

    const handleModalConfirm = async () => {
        if (selectedRow) {
            try {
                await deleteFeatureFlagData({ featureFlagId: selectedRow.original.id });
                // Handle success
            } catch (error) {
                // Handle error
            }
        }
        handleModalClose();
    };

    const dateField = (label, value, onChange) => {
        return (
            <AvlDatePickerField
                className={`${styles.field} ${styles.dateField}`}
                format={dateFormat}
                required={false}
                label={label}
                value={value}
                onChange={onChange}
            />
        );
    };

    const dateTimeTableEditField = ({ cell, column }) => {
        const cellValue = cell.getValue();
        const label = column.columnDef.header;
        const value = cellValue
            ? new Date(extractDateFromIso(new Date(cellValue)
                .toISOString()))
            : null;

        return dateField(
            label,
            value,
            (newDate) => {
                setEditedValues({ ...editedValues, [column.id]: newDate });
            },
        );
    };

    const colorBasedOnCurrentDatePositionInRange = (cell) => {
        const validFrom = cell.row.getValue('valid_from');
        const validTo = cell.row.getValue('valid_to');
        const isFieldsUnset = !validFrom && !validTo;

        if (isFieldsUnset) return 'black';

        const today = new Date();
        const startPoint = validFrom && new Date(validFrom);
        const endPoint = validTo && new Date(validTo);
        const isTodayBeforeRange = startPoint && today < startPoint;
        const isTodayAfterRange = endPoint && endPoint < today;
        const isTodayInRange = (!startPoint || startPoint < today) && (!endPoint || today < endPoint);

        if (isTodayBeforeRange) return 'orange';
        if (isTodayAfterRange) return 'red';
        if (isTodayInRange) return 'green';
    };

    const dateTimeTableCell = ({ cell }) => {
        const date = cell.getValue()
            ? new Date(cell.getValue())
                .toISOString()
            : null;

        return (
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                }}
            >
                {date && (
                    <Box
                        component="span"
                        sx={{
                            display: 'block',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            opacity: '0.55',
                            backgroundColor: colorBasedOnCurrentDatePositionInRange(cell),
                        }}
                    />
                )}
                {dateToFormattedString(date, '')}
            </Box>
        );
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,
                enableGlobalFilter: false,
                size: 80,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                muiEditTextFieldProps: ({ cell }) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.column.id],
                    helperText: validationErrors?.[cell.column.id],
                    onBlur: (event) => {
                        const newValue = event.currentTarget.value;
                        const validationError = validateRequired(newValue);
                        setValidationErrors({
                            ...validationErrors,
                            [cell.column.id]: validationError,
                        });
                    },
                }),
            },
            {
                accessorKey: 'user_name',
                header: 'User Name',
                muiEditTextFieldProps: ({ cell }) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.column.id],
                    helperText: validationErrors?.[cell.column.id],
                    onBlur: (event) => {
                        const newValue = event.currentTarget.value;
                        const validationError = validateRequired(newValue);
                        setValidationErrors({
                            ...validationErrors,
                            [cell.column.id]: validationError,
                        });
                    },
                }),
            },
            {
                accessorKey: 'organisation',
                header: 'Organisation',
                muiEditTextFieldProps: ({ cell }) => ({
                    type: 'email',
                    required: true,
                    error: !!validationErrors?.[cell.column.id],
                    helperText: validationErrors?.[cell.column.id],
                    onBlur: (event) => {
                        const validationError = validateEmail(event.currentTarget.value);
                        setValidationErrors({
                            ...validationErrors,
                            [cell.column.id]: validationError,
                        });
                    },
                }),
            },
            {
                accessorKey: 'is_enabled',
                header: 'Is Enabled',
                enableGlobalFilter: false,
                enableEditing: true,
                editSelectOptions: [
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false },
                ],
                editVariant: 'select',
                filterVariant: 'checkbox',
                muiEditTextFieldProps: ({ cell }) => ({
                    required: true,
                    error: !!validationErrors?.[cell.column.id],
                    helperText: validationErrors?.[cell.column.id],
                    onBlur: (event) => {
                        const newValue = event.target.value;
                        const validationError = validateBooleanRequired(newValue);
                        setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            [cell.column.id]: validationError,
                        }));
                    },
                }),
                Cell: ({ cell }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            maxWidth: '80px',
                        }}
                    >
                        <Checkbox checked={!!cell.getValue()} readOnly />
                    </Box>
                ),
            },
            {
                accessorKey: 'message',
                header: 'Message',
                muiEditTextFieldProps: ({ cell }) => ({
                    type: 'message',
                    required: false,
                    error: !!validationErrors?.[cell.column.id],
                    helperText: validationErrors?.[cell.column.id],
                    onBlur: (event) => {
                        const validationError = validateMessage(event.currentTarget.value);
                        setValidationErrors({
                            ...validationErrors,
                            [cell.column.id]: validationError,
                        });
                    },
                }),
            },
            {
                accessorKey: 'valid_from',
                header: 'Valid From',
                editVariant: 'datetime',
                filterVariant: 'datetime',
                Edit: dateTimeTableEditField,
                Cell: dateTimeTableCell,
            },
            {
                accessorKey: 'valid_to',
                header: 'Valid To',
                editVariant: 'datetime',
                filterVariant: 'datetime',
                Edit: dateTimeTableEditField,
                Cell: dateTimeTableCell,
            },
        ],
        [editedValues, validationErrors],
    );

    // call CREATE hook
    const { mutateAsync: createFeatureFlagData, isPending: isCreatingFeatureFlag } =
        useCreateFeatureFlag(pagination, globalFilter);

    // call READ hook
    const {
        data: featureFlagsData = [],
        isError: isLoadingUsersError,
        isFetching: isFetchingUsers,
        isLoading: isLoadingUsers,
    } = useFeatureFlags(pagination, globalFilter);

    // call UPDATE hook
    const { mutateAsync: updateFeatureFlagData, isPending: isUpdatingFeatureFlag } =
        useUpdateFeatureFlag(pagination, globalFilter);

    // call DELETE hook
    const { mutateAsync: deleteFeatureFlagData, isPending: isDeletingFeatureFlag } =
        useDeleteFeatureFlag(pagination, globalFilter);

    const validateFields = (fields) => {
        const name = fields['name'];
        const userName = fields['user_name'];
        const organisation = fields['organisation'];
        const isEnabled = fields['is_enabled'];
        const message = fields['message'];

        const errors = {
            'name': validateRequired(name),
            'user_name': validateRequired(userName),
            'organisation': validateRequired(organisation),
            'is_enabled': validateBooleanRequired(isEnabled),
            'message': validateMessage(message),
        };

        for (const key in errors) {
            const error = errors[key];

            if (!error) delete errors[key];
        }

        setValidationErrors({ ...validationErrors, ...errors, });

        return !Object.keys(errors).length;
    };

    const handleCreateFeatureFlag = async ({ values, table }) => {
        try {
            const updatedValues = { ...values };
            const validFrom = editedValues['valid_from'];
            const validTo = editedValues['valid_to'];
            updatedValues['valid_from'] = validFrom && toIsoIgnoreTimezone(validFrom);
            updatedValues['valid_to'] = validTo && toIsoIgnoreTimezone(validTo);

            if (!validateFields(updatedValues)) return;

            await createFeatureFlagData({ featureFlagData: updatedValues });
            table.setCreatingRow(null);
            setEditedValues({});
            // Optional: Show success message
        } catch (error) {
            console.error('An error occurs during feature flag creating', error);
            // Handle error, e.g., show an error message
        }
    };

    const handleSaveFeatureFlags = async ({ values, table }) => {
        const isErrorExists = Object.values(validationErrors)
            .some((error) => !!error);

        if (isErrorExists) return;

        try {
            const updatedValues = { ...values };
            const validFrom = editedValues['valid_from'];
            const validTo = editedValues['valid_to'];
            updatedValues['valid_from'] = validFrom && toIsoIgnoreTimezone(validFrom);
            updatedValues['valid_to'] = validTo && toIsoIgnoreTimezone(validTo);

            if (!validateFields(updatedValues)) return;

            await updateFeatureFlagData({ featureFlagId: values.id, requestData: updatedValues });
            table.setEditingRow(null);
            setEditedValues({});

            // Optional: Show success message or handle UI updates
        } catch (error) {
            console.error('handleSaveFeatureFlags caused an error', error);
            // Handle error
        }
    };

    const table = useMaterialReactTable({
        columns,
        enableColumnFilters: false,
        data: featureFlagsData.data?.items ?? [],
        createDisplayMode: 'modal',
        editDisplayMode: 'modal',
        enableEditing: true,
        enableRowActions: true,
        positionActionsColumn: 'last',
        pageCount: featureFlagsData?.data?.total ? Math.ceil(featureFlagsData?.data?.total / pagination.pageSize) : 0,
        rowCount: featureFlagsData?.data?.total ?? 0,
        onPaginationChange: setPagination,
        manualPagination: true,
        getRowId: (row) => row.id,
        initialState: {
            columnVisibility: {
                id: false,
            }
        },
        muiToolbarAlertBannerProps: isLoadingUsersError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        muiPaginationProps: {},
        onGlobalFilterChange: setGlobalFilter,
        state: {
            pagination,
            globalFilter,
            isLoading: isLoadingUsers,
            isSaving: isCreatingFeatureFlag || isUpdatingFeatureFlag || isDeletingFeatureFlag,
            showAlertBanner: isLoadingUsersError,
            showProgressBars: isFetchingUsers,
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onCreatingRowSave: handleCreateFeatureFlag,
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveFeatureFlags,
        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h5">Create New Flag</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h5">Edit Flag</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Duplicate">
                    <IconButton
                        onClick={() => table.setCreatingRow(row)}
                    >
                        <DifferenceIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteFeatureFlagConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderBottomToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                {Object.values(validationErrors)
                    .some((error) => !!error) && (
                    <Typography color="error">Fix errors before submitting</Typography>
                )}
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => table.setCreatingRow(true)}
            >
                Create Feature Flag
            </Button>
        ),
    });

    return (
        <>
            <MaterialReactTable table={table} />
            <ConfirmationModal
                open={isModalOpen}
                handleClose={handleModalClose}
                handleConfirm={handleModalConfirm}
                title="Confirm Deletion"
                content="Are you sure you want to delete this feature flag?"
            />
        </>
    );
}

function useCreateFeatureFlag(pagination, globalFilter) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ featureFlagData }) => {
            let keys = Object.keys(featureFlagData);
            keys.forEach((key) => {
                if (featureFlagData[key] === undefined || featureFlagData[key] === null || featureFlagData[key] === '') {
                    delete featureFlagData[key];
                }
            });
            const response = await createFeatureFlag(featureFlagData);
            // return response.data; // Assuming the response data is what you want to work with
        },
        onMutate: async (newFeatureFlag) => {
            // Optionally handle optimistic update
            await queryClient.cancelQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
            const previousFeatureFlags = queryClient.getQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter]);
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], (oldQueryData) => {
                return {
                    ...oldQueryData,
                    items: [...oldQueryData.data.items, { ...newFeatureFlag }],
                };
            });

            return { previousFeatureFlags };
        },
        onError: (err, newFeatureFlag, context) => {
            // Rollback on error
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], context.previousFeatureFlags);
        },
        onSettled: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
        },
    });
}

function useFeatureFlags(pagination, globalFilter) {
    return useQuery({
        queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter],
        queryFn: async () => {
            return await getFeatureFlags(pagination.pageIndex * pagination.pageSize, pagination.pageSize, globalFilter);
        },
        refetchOnWindowFocus: false,
    });
}

function useUpdateFeatureFlag(pagination, globalFilter) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ featureFlagId, requestData }) => {
            await updateFeatureFlag(featureFlagId, requestData);
        },
        onMutate: async (updatedFeatureFlag) => {
            // Optionally handle optimistic update
            await queryClient.cancelQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
            const previousFeatureFlags = queryClient.getQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter]);
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], (oldQueryData) => {
                // Modify according to your data structure
                return {
                    ...oldQueryData,
                    items: oldQueryData.data.items.map((flag) =>
                        flag.id === updatedFeatureFlag.featureFlagId
                            ? { ...flag, ...updatedFeatureFlag.requestData }
                            : flag,
                    ),
                };
            });

            return { previousFeatureFlags };
        },
        onError: (err, updatedFeatureFlag, context) => {
            // Rollback on error
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], context.previousFeatureFlags);
        },
        onSettled: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
        },
    });
}

function useDeleteFeatureFlag(pagination, globalFilter) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ featureFlagId }) => {
            await deleteFeatureFlag(featureFlagId);
        },
        onMutate: async (featureFlagId) => {
            // Optionally handle optimistic update
            await queryClient.cancelQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
            const previousFeatureFlags = queryClient.getQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter]);
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], (oldQueryData) => {
                // Modify according to your data structure
                return {
                    ...oldQueryData,
                    items: oldQueryData.data.items.filter((flag) => flag.id !== featureFlagId),
                };
            });

            return { previousFeatureFlags };
        },
        onError: (err, featureFlagId, context) => {
            // Rollback on error
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], context.previousFeatureFlags);
        },
        onSettled: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
        },
    });
}

const validateBooleanRequired = (value) => typeof value === 'boolean' ? null : 'Required';

const validateRequired = (value) => !value?.length ? 'Required' : null;

const validateEmail = validateRequired;

const validateMessage = (value) => {

};
