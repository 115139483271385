import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import LoadingOverly from '../LoadingOverly';
import { Alert, Button } from '@mui/material';
import { searchEmailRecords, searchRecords } from '../../../Services/Search.services';
import styles from './SearchBar.module.css';

function SearchBar({ onOptionSelected, placeholder, query }) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState(query || '');
    const [resulType, setResulType] = useState('');
    const [selectedSearchObj, setSelectedSearchObj] = useState([]);
    const [hideResult, setHideResult] = useState(false);
    const [emptyAlert, setEmptyAlert] = useState(false);
    const [firebaseAlert, setFirebaseAlert] = useState(false);
    const [cursor, setCursor] = useState(0);
    const ref = useRef(null);
    const searchRef = useRef(null);

    const typeIcons = {
        'user': <PersonOutlineIcon />,
        'company': <CorporateFareIcon />,
        'folder': <FolderOpenOutlinedIcon />,
    };

    const getOptions = (searchQuery) => {
        searchRecords(searchQuery)
            .then((res) => {
                if (!res) return;

                setHideResult(false);
                setCursor(0);

                if (!searchQuery) {
                    setFilteredData([]);
                } else if (Array.isArray(res)) {
                    setFilteredData(res);
                } else {
                    setFilteredData([]);
                }
            });
    };

    useEffect(() => {
        searchRef.current.focus();

        if (query) getOptions(query);
    }, []);

    const searchItemList = () => {
        const searchWord = wordEntered;

        if (searchWord !== '') {
            searchEmailRecords(searchWord)
                .then((res) => {
                    if (res.status === 200) {
                        const item = res.data;
                        if (item) {
                            setResulType(item.type);
                            setHideResult(true);
                            setSelectedSearchObj(item);
                            onOptionSelected(item);
                        }
                    } else if (res.response.status === 404) {
                        setFirebaseAlert(true);
                        setTimeout(() => {
                            setFirebaseAlert(false);
                        }, 5000);
                    }
                });
        }
    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered('');
    };

    const closeSearchResult = () => {
        setFilteredData([]);
    };

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        setResulType('');

        if (searchWord) {
            getOptions(searchWord);
        } else {
            clearInput();
        }
    };

    const getSelectedData = (e, selectedOption) => {
        // setTimeout is called to update the useState immediately
        setResulType(selectedOption.type);
        setWordEntered(selectedOption.data);
        setHideResult(true);
        // after setSelectedSearchObj, trigger ref.current.click();
        setSelectedSearchObj(selectedOption);
        //document.querySelector(".searchInputs input").focus();
        // ref.current.click();
        showSearchResult(selectedOption.type, selectedOption);
    };

    const showSearchResult = (resulType, selectedSearchObj) => {
        if (resulType !== 'user' && resulType !== 'company' && resulType !== 'folder') {
            setEmptyAlert(true);
            setTimeout(() => {
                setEmptyAlert(false);
            }, 5000);
        }

        onOptionSelected(selectedSearchObj);
    };

    const selectOptionAbove = () => {
        const nextCursorPosition = cursor - 1;

        if (nextCursorPosition < 0) {
            return;
        }

        setCursor(nextCursorPosition);
    };

    const selectOptionBelow = () => {
        const nextCursorPosition = cursor + 1;

        if (nextCursorPosition > filteredData.length - 1) {
            return;
        }

        setCursor(nextCursorPosition);
    };

    const handleKeyDown = (e) => {
        const arrowUp = 38;
        const arrowDown = 40;
        const enter = 13;
        const escape = 27;

        if (e.keyCode === arrowUp) {
            e.preventDefault(); // To prevent cursor moves in search input
            selectOptionAbove();
        } else if (e.keyCode === arrowDown) {
            e.preventDefault(); // To prevent cursor moves in search input
            selectOptionBelow();
        } else if (e.keyCode === enter) {
            const selectedRecord = filteredData[cursor];
            if (selectedRecord) {
                getSelectedData('', selectedRecord);
            } else if (!selectedRecord) {
                searchItemList();
            }
        } else if (e.keyCode === escape) {
            closeSearchResult();
        }
    };

    return (
        <>
            <LoadingOverly />
            <div className={styles.searchContainer}>
                {firebaseAlert === true && (<Alert severity="warning">User is not on Firebase</Alert>)}
                {emptyAlert === true && (<Alert severity="warning">Please select an option!</Alert>)}
                <div className={styles.inputAndButtonRoot}>
                    <div className={styles.inputAndButton}>
                        <div className={styles.inputAndClearButtonArea}>
                            <input
                                type="text"
                                className={styles.searchInput}
                                placeholder={placeholder}
                                value={wordEntered}
                                onChange={handleFilter}
                                ref={searchRef}
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                            <div className={styles.clearButtonContainer}>
                                {(!!wordEntered || filteredData.length > 0)
                                    && (
                                        <CloseIcon className={styles.clearButton} onClick={clearInput} />
                                    )}
                            </div>
                        </div>
                        <Button
                            ref={ref}
                            className={styles.searchButton}
                            variant="contained"
                            onClick={() => showSearchResult(resulType, selectedSearchObj)}
                        >
                            <SearchIcon />
                        </Button>
                    </div>
                    {filteredData.length !== 0 && hideResult === false && (
                        <div className={styles.optionsContainer}>
                            {filteredData.slice(0, 15)
                                .map((value, key) => (
                                    <a
                                        className={cursor === key
                                            ? `${styles.option} ${styles.active}`
                                            : styles.option}
                                        key={key}
                                        onClick={(e) => getSelectedData(e, value)}
                                        data-data={value.data}
                                        data-type={value.type}
                                        data-id={value.id}
                                    >
                                        {typeIcons[value.type || 'user']}
                                        <p className={styles.optionText}>{value.display}</p>
                                    </a>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default SearchBar;
