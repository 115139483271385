import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { enableUser, getUserInfo, getUserInfoWithId, suspendUser } from '../../../Services/Search.services';
import { toast } from 'react-toastify';
import SkeletonComponent from '../../SkeletonComponent';
import { useSelector } from 'react-redux';
import { UrlActionType, UrlButton } from '../../Shared/UrlButton/UrlButton';
import { Header } from '../../Shared/Header/Header';
import styles from './UserDetailsTable.module.css';
import { datetimeToFormattedString } from '../../../Utils/Utils';
import { AvlSwitch } from '../../Shared/Inputs/Switch/AvlSwitch';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

export default function UserDetailsTable({ userId, email }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isUserStatusLoading, setIsUserStatusLoading] = useState(false);
    const [userDetails, setUserDetails] = useState([]);
    const [isUserDisabled, setIsUserDisabled] = useState(null);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    const handleStatus = (isEnabled) => {
        let actionPromise = null;
        setIsUserStatusLoading(true);

        if (isEnabled) {
            actionPromise = enableUser(userId)
                .then(() => {
                    toast.success('User enabled successfully.');
                });
        } else {
            actionPromise = suspendUser(userId)
                .then(() => {
                    toast.warn('User suspended.');
                });
        }

        actionPromise.finally(() => {
            setIsUserDisabled(!isEnabled);
            setIsUserStatusLoading(false);
        });
    };

    useEffect(() => {
        getUserInfoWithId(userId)
            .then((res) => {
                if (res.status === 200) {
                    setUserDetails(res.data);
                    setIsUserDisabled(res.data.disabled);
                    setIsLoading(false);
                } else {
                    if ((res && res.stack && res.message)) {
                        if (res.request.status === 404) {
                            getUserInfo(email)
                                .then((res) => {
                                    if (res.status === 200) {
                                        setUserDetails(res.data);
                                        setIsUserDisabled(res.data.disabled);
                                        setIsLoading(false);
                                    } else {
                                        if ((res && res.stack && res.message)) {
                                            setUserDetails(null);
                                            setIsLoading(false);
                                        }
                                    }
                                });
                        }
                    } else {
                        //  probably access point related problem
                        setUserDetails(null);
                        setIsLoading(false);
                        toast.warn('Probably something went wrong at the access point. Please try again later.');
                    }
                }
            })
            .catch(() => {
                setUserDetails(null);
                setIsLoading(false);
            });
        //eslint-disable-next-line
    }, [email, userId, isUserDisabled]);

    return (
        <Paper
            sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
        >
            <div className={styles.header}>
                <Header title="User Details" />
            </div>
            {isLoading
                ? (<SkeletonComponent />)
                : (
                    <Grid container>
                        {userDetails
                            ? (
                                <Grid item xs={12} sm container key={userDetails.displayName}>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Grid item xs>
                                            <div>
                                                <strong>
                                                    Display Name:
                                                    {' '}
                                                </strong>
                                                {userDetails.displayName ? userDetails.displayName : '-'}
                                            </div>
                                        </Grid>
                                        <Grid item xs>
                                            <div>
                                                <strong>Email: </strong>
                                                {userDetails.email ? userDetails.email : '-'}
                                            </div>
                                        </Grid>
                                        <Grid item xs>
                                            <div>
                                                <strong>
                                                    Organisation:
                                                    {' '}
                                                </strong>
                                                {userDetails.organisation ? userDetails.organisation : '-'}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Grid item xs>
                                            <div>
                                                <strong>
                                                    First Sign-in:
                                                    {' '}
                                                </strong>
                                                {datetimeToFormattedString(userDetails.firstSignIn === 'Unknown'
                                                    ? null
                                                    : userDetails.firstSignIn)}
                                            </div>
                                        </Grid>
                                        <Grid item xs>
                                            <div>
                                                <strong>
                                                    Last Sign-in:
                                                    {' '}
                                                </strong>
                                                {datetimeToFormattedString(userDetails.lastSignIn === 'Unknown'
                                                    ? null
                                                    : userDetails.lastSignIn)}
                                            </div>
                                        </Grid>
                                        <Grid item xs>
                                            <div>
                                                <strong>
                                                    Sign-in Method:
                                                    {' '}
                                                </strong>
                                                {userDetails.signInMethod ? userDetails.signInMethod : '-'}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Grid item xs>
                                            <div>
                                                <strong style={{ marginRight: '6px' }}>
                                                    Sign-up Link:
                                                </strong>
                                                {userDetails.signUpLink
                                                    ? (
                                                        <UrlButton
                                                            url={userDetails.signUpLink}
                                                            action={UrlActionType.openInNewTab}
                                                        />
                                                    )
                                                    : 'Not Available'}
                                            </div>
                                        </Grid>
                                        <Grid item xs>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px',
                                                    height: '24px',
                                                }}
                                            >
                                                <strong>Status:</strong>
                                                <div style={{ minWidth: '83px' }}>
                                                    {
                                                        isUserDisabled
                                                            ? (<span style={{ color: '#c51031' }}>Suspended</span>)
                                                            : (<span style={{ color: '#8ac291' }}>Enabled</span>)
                                                    }
                                                </div>
                                                <AvlSwitch
                                                    checked={!isUserDisabled}
                                                    disabled={!isAdmin || isUserStatusLoading}
                                                    onSwitch={handleStatus}
                                                />
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: green[500],
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-30px',
                                                        display: isUserStatusLoading ? 'block' : 'none',
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                            : (
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert severity="warning">Records not found!</Alert>
                                </Stack>
                            )}
                    </Grid>
                )}
        </Paper>
    );
}
