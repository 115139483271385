import { AvlTable } from '../../../../Shared/Table/Table';
import { useEffect, useMemo, useState } from 'react';
import SkeletonComponent from '../../../../SkeletonComponent';
import { AvlCheckbox } from '../../../../Shared/Inputs/Checkbox/AvlCheckbox';
import styles from './MissingInvoicesTable.module.css';

export const FieldKeys = {
    purchaseId: 'purchaseId',
    transactionId: 'transactionId',
    messageId: 'messageId',
    reference: 'reference',
    userName: 'userName',
    organisation: 'organisation',
    isTrial: 'isTrial',
    fee: 'fee',
    orderedAt: 'orderedAt',
    lastExpectedInvoiceDate: 'lastExpectedInvoiceDate',
    oType: 'oType',
    folderId: 'folderId',
    documentId: 'documentId',
    message: 'message',
};

const Columns = {
    [FieldKeys.purchaseId]: 'Purchase ID',
    [FieldKeys.transactionId]: 'Transaction ID',
    [FieldKeys.messageId]: 'Message ID',
    [FieldKeys.folderId]: 'Folder ID',
    [FieldKeys.documentId]: 'Document ID',
    [FieldKeys.organisation]: 'Organisation',
    [FieldKeys.isTrial]: 'Is Trial',
    [FieldKeys.reference]: 'Reference',
    [FieldKeys.userName]: 'User',
    [FieldKeys.fee]: 'Fee',
    [FieldKeys.orderedAt]: 'Ordered At',
    [FieldKeys.lastExpectedInvoiceDate]: 'Last Expected Invoice Date',
    [FieldKeys.oType]: 'OType',
    [FieldKeys.message]: 'Message',
};

export function MissingInvoicesTable({ isLoading, invoices }) {
    const [countRowsPerPage, setCountRowsPerPage] = useState(20);
    const pageCount = useMemo(() => {
        return Math.ceil(invoices.length / countRowsPerPage);
    }, [invoices, countRowsPerPage]);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleTableRows, setVisibleRows] = useState([]);

    const tableHeaders = [
        {
            label: Columns[FieldKeys.purchaseId],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.transactionId],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.messageId],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.folderId],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.documentId],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.organisation],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.isTrial],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.reference],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.userName],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.fee],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.orderedAt],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.lastExpectedInvoiceDate],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.oType],
            align: 'left',
        },
        {
            label: Columns[FieldKeys.message],
            align: 'left',
        },
    ];

    const convertToCells = (row, rowIndex) => [
        {
            data: row[FieldKeys.purchaseId] || 'N/A',
        },
        {
            data: row[FieldKeys.transactionId] || 'N/A',
        },
        {
            data: row[FieldKeys.messageId] || 'N/A',
        },
        {
            data: row[FieldKeys.folderId] || 'N/A',
        },
        {
            data: row[FieldKeys.documentId] || 'N/A',
        },
        {
            data: row[FieldKeys.organisation] || 'N/A',
        },
        {
            style: { textAlign: 'center' },
            data: (
                <AvlCheckbox
                    isDisabled
                    value={row[FieldKeys.isTrial]}
                />
            ),
        },
        {
            data: row[FieldKeys.reference] || 'N/A',
        },
        {
            data: row[FieldKeys.userName] || 'N/A',
        },
        {
            data: row[FieldKeys.fee] || 'N/A',
        },
        {
            style: { minWidth: '90px' },
            data: row[FieldKeys.orderedAt] || 'N/A',
        },
        {
            style: { minWidth: '180px' },
            data: row[FieldKeys.lastExpectedInvoiceDate] || 'N/A',
        },
        {
            style: { minWidth: '80px' },
            data: row[FieldKeys.oType] || 'N/A',
        },
        {
            data: row[FieldKeys.message] || 'N/A',
        },
    ];

    const showRows = (page, countRowsPerPage, invoices) => {
        const firstPageRowIndex = (page - 1) * countRowsPerPage;
        const pageInvoices = invoices.slice(firstPageRowIndex, firstPageRowIndex + countRowsPerPage);
        const tableRows = pageInvoices.map((row, rowIndex) => ({
            cells: convertToCells(row, rowIndex),
        }));

        setVisibleRows(tableRows);
    };

    useEffect(() => {
        showRows(currentPage, countRowsPerPage, invoices);
    }, [invoices.length]);

    const handleChangePage = (event, page) => {
        setCurrentPage(page);
        showRows(page, countRowsPerPage, invoices);
    };

    const handleChangeRowsPerPagePage = (rowsPerPage) => {
        const initialPage = 1;
        setCountRowsPerPage(rowsPerPage);
        setCurrentPage(initialPage);
        showRows(initialPage, rowsPerPage, invoices);
    };

    const content = invoices?.length
        ? (
            <AvlTable
                headers={tableHeaders}
                rows={visibleTableRows}
                page={currentPage}
                onPageChange={handleChangePage}
                pageCount={pageCount}
                isRowsPerPageOptionsVisible
                rowsPerPage={countRowsPerPage}
                rowsCount={invoices.length}
                onChangeRowsPerPage={handleChangeRowsPerPagePage}
            />
        ) : (
            <div className={styles.noContentMessage}>
                Missing invoices not found
            </div>
        );

    return isLoading
        ? (
            <SkeletonComponent />
        )
        : content;
}
