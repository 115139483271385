import styles from './ShowMore.module.css';
import { Collapse } from '@mui/material';
import { useState } from 'react';

export function ShowMore({ children, collapsedSize = 40 }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const labelText = isExpanded
        ? 'Hide'
        : 'Show more';
    const isLongList = children?.length > 2;

    return isLongList
        ? (
            <>
                <Collapse in={isExpanded} collapsedSize={collapsedSize}>
                    {children}
                </Collapse>
                <p className={styles.label} onClick={() => setIsExpanded(!isExpanded)}>
                    {labelText}
                </p>
            </>
        )
        : children;
}
