import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { getOrgStats } from '../../../../Services/Search.services';
import SkeletonComponent from '../../../SkeletonComponent';
import { Alert, Stack } from '@mui/material';
import { getTitle } from '../../../../Utils/Utils';
import { Filters } from '../../../Shared/Filters/Filters';
import { format } from 'date-fns';
import { dateFormat } from '../../../../Constants/Date.constant';
import { Header } from '../../../Shared/Header/Header';
import styles from './OrgStatsTab.module.css';

const StatisticType = {
    documentAnalysis: 'documentAnalysis',
    purchases: 'purchases',
    reportUpgrades: 'reportUpgrades',
    topFiveUsers: 'topFiveUsers',
    titleSearch: 'titleSearch',
    leasesAnalysed: 'leasesAnalysed',
    topFiveLeaseUsers: 'topFiveLeaseUsers',
};

export function OrgStatsTab({ orgName }) {
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [documents, setDocuments] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [scheduleOrders, setScheduleOrders] = useState([]);
    const [topFiveUsers, setTopFiveUsers] = useState([]);
    const [titleSearch, setTitleSearch] = useState([]);
    const [leasesAnalysed, setLeasesAnalysed] = useState([]);
    const [topFiveLeaseUsers, setTopFiveLeaseUsers] = useState([]);
    const [random, setRandom] = useState(1);

    const makeParams = () => {
        let urlParam = '';
        if (startDate) {
            urlParam += '&startDate=' + startDate;
        }
        if (endDate) {
            urlParam += '&endDate=' + endDate;
        }
        if (urlParam !== '') {
            urlParam = urlParam.substring(1);
        }
        return urlParam;
    };

    const getFilterFormValue = (filterValues) => {
        const startDate = filterValues.startDate;
        const endDate = filterValues.endDate;
        const formattedStartDate = startDate && format(startDate, dateFormat);
        const formattedEndDate = endDate && format(endDate, dateFormat);

        setLoading(true);
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setRandom(Math.random());
    };

    useEffect(() => {
        getRecords();
    }, [startDate, endDate, orgName, random]);

    const getRecords = () => {
        const urlParam = makeParams();

        getOrgStats(orgName, urlParam)
            .then((statistic) => {
                if (statistic) {
                    const alteredDocuments = statistic.documents.map((document) => {
                        const type = document[0];
                        const secondType = document[1];
                        const count = document[2];
                        const title = getTitle(type);
                        const secondTypeTitle = getTitle(secondType);

                        if (title) {
                            return secondTypeTitle
                                ? [title, secondTypeTitle, count]
                                : [title, secondType, count];
                        } else {
                            return secondTypeTitle
                                ? [type, secondTypeTitle, count]
                                : [type, secondType, count];
                        }
                    });
                    const alteredPurchases = statistic.purchases.map((purchase) => {
                        const type = purchase[0];
                        const count = purchase[1];
                        const title = getTitle(type);

                        return title ? [title, count] : [type, count];
                    });

                    //  scheduleOrders have planning eg [["planning",220]] take that out if it exist and put it to the second place of the array
                    const scheduleOrders = statistic.scheduleOrders || [];
                    const scheduleOrdersPlannig = [];
                    scheduleOrders.forEach((row, index) => {
                        if (row[0] === 'companies-house') {
                            scheduleOrders.splice(index, 1);
                            scheduleOrders.unshift(row);
                        }
                    });
                    scheduleOrders.forEach((row, index) => {
                        if (row[0] === 'planning') {
                            scheduleOrders.splice(index, 1);
                            scheduleOrders.unshift(row);
                        }
                    });
                    const alteredScheduleOrders = statistic.scheduleOrders.map((crosscheck) => {
                        const type = crosscheck[0];
                        const count = crosscheck[1];
                        const title = getTitle(type);

                        return title ? [title, count] : [type, count];
                    });
                    const alteredTitleSearch = statistic.titleSearch.map((titleSearch) => {
                        const type = titleSearch[0];
                        const count = titleSearch[1];
                        const title = getTitle(type);

                        return title ? [title, count] : [type, count];
                    });

                    const leaseDocuments = statistic.leases?.documents || [];
                    const leaseTopUsers = statistic.leases?.topUsers || [];
                    const leasesAnalysed = leaseDocuments.map((document) => {
                        const type = document[0];
                        const secondType = document[1];
                        const count = document[2];
                        const title = getTitle(type);
                        const secondTypeTitle = getTitle(secondType);

                        if (title) {
                            return secondTypeTitle
                                ? [title, secondTypeTitle, count]
                                : [title, secondType, count];
                        } else {
                            return secondTypeTitle
                                ? [type, secondTypeTitle, count]
                                : [type, secondType, count];
                        }
                    });

                    setDocuments(alteredDocuments || []);
                    setPurchases(alteredPurchases || []);
                    setScheduleOrders(alteredScheduleOrders || []);
                    setTopFiveUsers(statistic.topUsers || []);
                    setTitleSearch(alteredTitleSearch || []);
                    setLeasesAnalysed(leasesAnalysed || []);
                    setTopFiveLeaseUsers(leaseTopUsers || []);
                    setLoading(false);
                }
            });
    };

    const statisticCard = (title, data, statisticType) => {
        const listItem = (row, statisticType) => {
            switch (statisticType) {
                case StatisticType.documentAnalysis:
                    return row[1] === 'Uploaded' || row[1] === 'Purchased'
                        ? (
                            <>
                                <ListItemText primary={`${row[0]} ${row[1]}`} />
                                <ListItemText primary={row[2].toLocaleString('en-GB')} align="right" />
                            </>
                        )
                        : (
                            <>
                                <ListItemText primary={row[0] + ' & ' + row[1]} />
                                <ListItemText primary={row[2].toLocaleString('en-GB')} align="right" />
                            </>
                        );
                case StatisticType.leasesAnalysed:
                    return (
                        <>
                            <ListItemText primary={`${row[0]} ${row[1]}`} />
                            <ListItemText primary={row[2].toLocaleString('en-GB')} align="right" />
                        </>
                    );
                case StatisticType.purchases:
                case StatisticType.reportUpgrades:
                case StatisticType.topFiveUsers:
                case StatisticType.titleSearch:
                case StatisticType.topFiveLeaseUsers:
                default:
                    return (
                        <>
                            <ListItemText primary={row[0]} />
                            <ListItemText primary={row[1].toLocaleString('en-GB')} align="right" />
                        </>
                    );
            }
        };

        return (
            <Paper elevation={3} className={styles.card}>
                <Typography
                    className={styles.cardHeader}
                    ml={2}
                    mt={2}
                    variant="h6"
                    gutterBottom
                    component="div"
                >
                    {title}
                </Typography>
                {loading
                    ? (<SkeletonComponent />)
                    : (
                        <List>
                            {
                                data.length > 0
                                    ? data.map((row, index) => (
                                        <ListItem disablePadding key={index}>
                                            <ListItemButton>
                                                {listItem(row, statisticType)}
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                    : (
                                        <Stack sx={{ width: '100%' }} spacing={2}>
                                            <Alert severity="warning">Records not found!</Alert>
                                        </Stack>
                                    )
                            }
                        </List>
                    )}
            </Paper>
        );
    };

    return (
        <>
            <div className="header">
                <Header
                    title="Organisation Stats"
                    filters={(
                        <Filters
                            isDateRange
                            onFiltersChange={getFilterFormValue}
                        />
                    )}
                />
            </div>
            <div className={styles.cardsWrapper}>
                <div className={styles.cards}>
                    {statisticCard('Documents Analysed', documents, StatisticType.documentAnalysis)}
                    {statisticCard('Purchases', purchases, StatisticType.purchases)}
                    {statisticCard('Report Upgrades', scheduleOrders, StatisticType.reportUpgrades)}
                    {statisticCard('Top Five Users', topFiveUsers, StatisticType.topFiveUsers)}
                    {statisticCard('Title Search', titleSearch, StatisticType.titleSearch)}
                    {statisticCard('Leases Analysed', leasesAnalysed, StatisticType.leasesAnalysed)}
                    {statisticCard('Top Five Lease Users', topFiveLeaseUsers, StatisticType.topFiveLeaseUsers)}
                </div>
            </div>
        </>
    );
}
