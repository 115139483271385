import * as ProtoTypes from 'prop-types';

const AppsNaming = {
    title: ['title', 'titles'],
    lease: ['lease', 'leases'],
    notice: ['notice', 'notices'],
    esg: ['esg', 'esgs'],
};

const AppsImages = {
    title: '/images/icon-title-logo.svg',
    lease: '/images/icon-lease-logo.svg',
    notice: '/images/icon-notice-logo.svg',
    esg: '/images/icon-esg-logo.svg',
};

export function Apps({ apps = [] }) {
    const normalizeAppName = (app) => {
        for (const appKey in AppsNaming) {
            const nameVariants = AppsNaming[appKey];

            if (nameVariants.includes(app)) {
                return appKey;
            }
        }

        return null;
    };

    const normalizedAppNames = apps.map((name) => normalizeAppName(name));
    const uniqueAndFilteredAppNames = Array.from(new Set(normalizedAppNames))
        .filter((value) => !!value);

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
            {
                uniqueAndFilteredAppNames.map((appName) => (
                    <img
                        key={appName}
                        src={`${process.env.PUBLIC_URL}${AppsImages[appName]}`}
                        alt={`${appName} logo icon`}
                    />
                ))
            }
        </div>
    );
}

Apps.propTypes = {
    apps: ProtoTypes.array.isRequired,
};
