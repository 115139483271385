import { Button } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Header } from '../../../../Shared/Header/Header';
import OrgAccountTableBulkAdd from './OrgAccountsContent/OrgAccountTableBulkAdd';
import OrgAccountTableSingleAdd from './OrgAccountsContent/OrgAccountTableSingleAdd';
import OrgAccountTable from './OrgAccountsContent/OrgAccountTable';
import { Filters } from '../../../../Shared/Filters/Filters';
import ScrollDialog from '../../../../Shared/ScrollDialog';

const ContentType = {
    accounts: 0,
    bulkCreator: 1,
    singleCreator: 2,
};

export function OrgAccountsTab({ orgName }) {
    const [currentContentType, setCurrentContentType] = useState(ContentType.accounts);
    const [filterQuery, setFilterQuery] = useState('');
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    const getFilterFormValue = (filterValues) => {
        setFilterQuery(filterValues.text);
    };

    const contents = {
        [ContentType.accounts]: {
            title: 'Accounts',
            filters: (
                <Filters
                    isText
                    onFiltersChange={getFilterFormValue}
                />
            ),
            body: <OrgAccountTable orgName={orgName} filterQuery={filterQuery} />,
            actionButtons: [
                <Button
                    key="org-accounts-sinle-add-button"
                    variant="contained"
                    size="small"
                    color="primary"
                    sx={{ mr: 1, ml: 1 }}
                    onClick={() => setCurrentContentType(ContentType.singleCreator)}
                >
                    Add Manually
                </Button>,
                isAdmin && (
                    <Button
                        key="org-accounts-bulk-add-button"
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => setCurrentContentType(ContentType.bulkCreator)}
                    >
                        Add by File
                    </Button>
                ),
                <ScrollDialog
                    key="org-accounts-enable-disable-dialog-buttons"
                    organisationName={orgName}
                />,
            ],
        },
        [ContentType.singleCreator]: {
            title: 'Add Accounts Manually',
            filters: false,
            body: <OrgAccountTableSingleAdd orgName={orgName} />,
            actionButtons: [
                <Button
                    key="org-accounts-back-button"
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => setCurrentContentType(ContentType.accounts)}
                >
                    Back
                </Button>,
            ],
        },
        [ContentType.bulkCreator]: {
            title: 'Add Accounts By File',
            filters: false,
            body: <OrgAccountTableBulkAdd orgName={orgName} />,
            actionButtons: [
                <Button
                    key="org-accounts-back-button"
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => setCurrentContentType(ContentType.accounts)}
                >
                    Back
                </Button>,
            ],
        },
    };

    const currentContent = contents[currentContentType];

    return (
        <>
            <div className="header">
                <Header
                    title={currentContent.title}
                    actionButtons={currentContent.actionButtons}
                />
            </div>
            {currentContent.body}
        </>
    );
}
