import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import './Users.css';
import { getUserFolders } from '../../../Services/Search.services';

import { Button, Card, CardContent } from '@mui/material';
import { Header } from '../../Shared/Header/Header';
import { Filters } from '../../Shared/Filters/Filters';
import { dateToFormattedString } from '../../../Utils/Utils';
import { Columns, ProjectsTable } from '../../Shared/ProjectsTable/ProjectsTable';
import { useNavigate } from 'react-router-dom';

export default function UserSearchTable({ userId, email, folderId }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [textSearch, setTextSearch] = useState(folderId || '');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [pageCount, setPageCount] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getRecords(page);
    }, [textSearch, startDate, endDate, userId]);

    const refreshRecords = () => {
        getRecords(page);
    };

    const handlePageChange = (event, value) => {
        setIsLoading(true);
        setPage(value);
        getRecords(value);
    };

    const setFilterFormValue = (filterValues) => {
        const startDate = filterValues.startDate;
        const endDate = filterValues.endDate;
        const formattedStartDate = dateToFormattedString(startDate, '') || null;
        const formattedEndDate = dateToFormattedString(endDate, '') || null;

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setTextSearch(filterValues.text);
        setOffset(0);
        setPage(1);
    };

    const getRequestParams = (pagination_page) => {
        let params = {};
        let paramUrl = '&limit=' + limit;

        params['offset'] = offset;
        params['limit'] = limit;

        if (userId) {
            params['userId'] = userId;
        }
        if (email) {
            paramUrl += '&email=' + email;
        }
        if (textSearch) {
            paramUrl += '&query=' + textSearch;
            params['query'] = textSearch;
        }
        if (startDate) {
            paramUrl += '&startDate=' + startDate;
            params['startDate'] = startDate;
        }
        if (endDate) {
            paramUrl += '&endDate=' + endDate;
            params['endDate'] = endDate;
        }
        if (page) {
            //duzelt
            // paramUrl += "&offset=" + (pagination_page - 1) * limit;
            // params["page"] = (page - 1);
            paramUrl += '&offset=' + (pagination_page - 1);
            params['page'] = (page - 1);
        }

        return paramUrl;
    };

    const getRecords = (page) => {
        setIsLoading(true);
        const paramUrl = getRequestParams(page);

        getUserFolders(userId, email, paramUrl)
            .then((res) => {
                if ((res && res.stack && res.message)) {
                    setRecords([]);
                    setOffset(0);
                    setLimit(10);
                    setPageCount(1);
                } else {
                    const dataResp = res.data;
                    const count = Number(dataResp.count);
                    const limit = Number(dataResp.limit);
                    const pcount = Math.ceil((count / limit));

                    setRecords(dataResp.folders);
                    setOffset(dataResp.offset);
                    setLimit(dataResp.limit);
                    setPageCount(pcount);
                }
            })
            .finally(() => setIsLoading(false))
            .catch(() => {
                setRecords([]);
                setOffset(0);
                setLimit(10);
                setPageCount(1);
            });
    };

    const handleBackToAllUserProjects = () => {
        navigate(`/search/users/${email}/ids/${userId}/`);
    };

    return (
        <Card component={Paper} style={{ marginTop: '10px' }}>
            <CardContent>
                {folderId
                    ? (
                        <Header
                            title={`Project ${folderId}`}
                            actionButtons={[
                                <Button
                                    key="user-project-show-all-projects-button"
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={handleBackToAllUserProjects}
                                >
                                    Show all user projects
                                </Button>,
                            ]}
                        />
                    ) : (
                        <Header
                            title="User Projects"
                            filters={(
                                <Filters
                                    isText
                                    isDateRange
                                    onFiltersChange={setFilterFormValue}
                                />
                            )}
                        />
                    )}
                <ProjectsTable
                    page={page}
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                    isLoading={isLoading}
                    records={records}
                    onRefreshRecords={refreshRecords}
                    projectsOwnerEmail={email}
                    hiddenColumns={[
                        Columns.username,
                        Columns.titlesAmount,
                        Columns.generate,
                    ]}
                />
            </CardContent>
        </Card>
    );
}
