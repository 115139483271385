import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { signOutFromFirebase } from '../../Store/Actions/AuthenticationActions';
import { connect } from 'react-redux';
import { forwardRef, useState } from 'react';
import styles from './Header.module.css';

const TopNavBar = forwardRef(function TopNavBar({ signOut, auth }, ref ) {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const isAdmin = true;

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuLink = (route) => () => {
        navigate(route);
    };

    const handleLogout = () => {
        // debugger;
        signOut();
    };

    const handleProfile = () => {

    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const pages = [
        { id: 1, name: 'Search', route: '/' },
        // { id: 2, name: 'Dashboard', route: '/dashboard' },
        // { id: 3, name: 'Filed Documents', route: '/filed-documents' },
        // { id: 2, name: 'Add Organisation', route: '/add-organisation' },
    ];

    if (isAdmin) {
        pages.push({ id: 2, name: 'Add Organisation', route: '/add-organisation' });
        pages.push({ id: 4, name: 'Service Status', route: '/service-status' });
        pages.push({ id: 5, name: 'Check Organisations', route: '/check-organisations' });
        pages.push({ id: 6, name: 'Configurations', route: '/overall-configurations' });
        pages.push({ id: 7, name: 'Statistics', route: '/overall-statistics' });
        pages.push({ id: 8, name: 'Reports', route: '/reports' });
        pages.push({ id: 9, name: 'Feature Flags', route: '/feature-flags' });
    }

    const settings = [
        { id: 1, label: 'Profile', click: handleProfile },
        { id: 2, label: 'Logout', click: handleLogout },
    ];

    return (
        <AppBar ref={ref} position="fixed" style={{ zIndex: 998, alignItems: 'center' }}>
            <Toolbar className={styles.header}>
                <div className={styles.mobileMenuButton}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >

                        {pages.map((page) => (
                            <MenuItem key={page.id} onClick={handleMenuLink(page.route)}>
                                <Typography textAlign="center">{page.name}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                <div className={styles.logo}>
                    <img
                        src={process.env.PUBLIC_URL + '/images/white-logo.svg'}
                        style={{ height: '37px', cursor: 'pointer' }}
                        alt=""
                        onClick={handleLogoClick}
                    />
                </div>
                <div className={styles.navRoutes}>
                    {pages.map((page) => (
                        <Button
                            className={styles.route}
                            key={page.id}
                            onClick={handleMenuLink(page.route)}
                        >
                            {page.name}
                        </Button>
                    ))}
                </div>
                <div className={styles.settings}>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="Avail" src={process.env.PUBLIC_URL + '/images/girl-from-hole.webp'} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting.id} onClick={setting.click}>
                                <Typography textAlign="center">{setting.label}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
});

const dispatchSignOutFromFirebase = (dispatch) => ({
    signOut: () => dispatch(signOutFromFirebase()),
});

const mapStateToProps = (state) => ({
    auth: state.auth,

});

export default connect(mapStateToProps, dispatchSignOutFromFirebase, null, { forwardRef: true })(TopNavBar);
