import OrganizationPage from '../Components/BodyComponent/Organizations/OrganizationPage';
import { useParams } from 'react-router-dom';
import SearchBarWrapper from '../Components/BodyComponent/SearchBar/SearchBarWrapper';

function OrgInfo() {
    const { orgName } = useParams();
    return (
        <SearchBarWrapper
            component={<OrganizationPage orgName={orgName} />}
        />
    );
}
OrgInfo.propTypes = {};
export default OrgInfo;
