import PropTypes from 'prop-types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import Tooltip from '@mui/material/Tooltip';
import styles from './UrlButton.module.css';

export const UrlActionType = {
    openInNewTab: 0,
    copyToClipboard: 1,
};

export function UrlButton({ url, action, description = '', buttonStyles }) {
    const ButtonOfAction = {
        [UrlActionType.openInNewTab]: (
            <Tooltip title={description || 'Open in a new tab'}>
                <a
                    className={styles.button}
                    style={buttonStyles?.link}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Link"
                >
                    <IconButton
                        style={buttonStyles?.button}
                        aria-label="Link"
                        color="primary"
                    >
                        <OpenInNewIcon />
                    </IconButton>
                </a>
            </Tooltip>
        ),
        [UrlActionType.copyToClipboard]: (
            <Tooltip title={description || 'Copy to the clipboard'}>
                <IconButton
                    className={styles.button}
                    style={buttonStyles?.button}
                    onClick={() => navigator.clipboard.writeText(url)}
                    aria-label="Link"
                    color="primary"
                >
                    <ContentCopyRoundedIcon />
                </IconButton>
            </Tooltip>
        ),
    };

    return ButtonOfAction[action];
}

UrlButton.propTypes = {
    url: PropTypes.string.isRequired,
    action: PropTypes.number.isRequired,
    description: PropTypes.string,
};
