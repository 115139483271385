import { AuthActionTypes } from '../Actions/AuthenticationTypes';

const initialState = {
    isAuthenticated: null,
    isLoading: false,
    isAdmin: null,
    isConsole: null,
    token: null,
    error: null,
};


const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AuthActionTypes.signInStart:
            return {
                ...state,
                isAuthenticated: false,
                isLoading: true,
                isAdmin: null,
                isConsole: null,
                token: null,
                error: null,
            };
        case AuthActionTypes.successSignIn:
        case AuthActionTypes.refreshToken:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                isConsole: true,
                token: action.payload,
                error: null,
            };
        case AuthActionTypes.fail:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                token: null,
                error: action.payload
            };
        case AuthActionTypes.loading:
            return {
                ...state,
                isLoading: true,
            };
        case AuthActionTypes.logoutStart:
            return {
                ...state,
                isAuthenticated: null,
                isLoading: true,
                isAdmin: null,
                isConsole: null,
                token: null,
                error: null,
            };
        case AuthActionTypes.logout:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                isAdmin: null,
                isConsole: null,
                token: null,
                error: null,
            };
        case AuthActionTypes.admin:
            return {
                ...state,
                isAdmin: true,
            };
        default:
            return state;
    }
};

export default authReducer;
