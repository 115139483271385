import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAvailableReports, getReport } from '../../Services/Search.services';
import FileOptions from './FileOptions';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import SkeletonComponent from '../SkeletonComponent';

function GenerateFolderReports({ folderId }) {
    const [availableReports, setAvailableReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let ignore = false;

        async function startFetching() {
            const json = await getAvailableReports(folderId);
            if (!ignore) {
                if (json !== null) {
                    setAvailableReports(json);
                    setIsLoading(false);
                }
            }
        }

        startFetching();
        return () => {
            ignore = true;
        };
    }
    , []);

    return (
        <div
            className="GenerateFolderReports"
            style={{
                overflow: 'auto',
                width: '100%',
                height: '100%',
                margin: 0,
                padding: 0,
                '&::-webkit-scrollbar': { display: 'none' },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
            }}

        >
            {
                isLoading
                    ? (<SkeletonComponent />)
                    : (
                        <Container
                            style={{ width: '100%!important', maxWidth: '100%' }}
                            maxWidth={false}
                        >
                            <Grid container spacing={1} style={{ width: '100%' }}>
                                {
                                    availableReports.map(
                                        ((report, index) => {
                                            return (
                                                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                                    <FileOptions
                                                        image={'/images/' + report.icon + '.png'}
                                                        description={report.name}
                                                        formatList={report.formats}
                                                        onClickFunction={getReport}
                                                        folderId={folderId}
                                                        fileName={report.key}
                                                    />
                                                </Grid>
                                            );
                                        }))
                                }
                            </Grid>
                        </Container>
                    )
            }
        </div>
    );
}

GenerateFolderReports.propTypes = {
    folderId: PropTypes.string.isRequired,
};
export default GenerateFolderReports;
