import React from 'react';
import PropTypes from 'prop-types';
import { PopUpInspectBox } from '../StyledComponents/PopUpInspectBox';
import { CloseDiv } from '../StyledComponents/CloseDiv';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import Container from '@mui/material/Container';

function PopUp({
    openPopUp,
    closePopUpFunction,
    PopUpComponent,
    organisation,
    folderId,
    refreshRecords,
    projectsCurrentUser,
    selectedProjectId,
    serviceId
}) {
    return (
        <Modal
            id={'modalInspect--'}
            open={openPopUp}
            onClose={closePopUpFunction}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            // sx={{
            //     "& > .MuiBackdrop-root": {
            //         backgroundColor: "transparent",
            //     }
            // }}
        >
            <PopUpInspectBox
                id={'modalInspect--2'}
                sx={{
                    m: 1, position: 'relative',
                    //     "& > .MuiBackdrop-root": {
                    //         backgroundColor: "transparent"
                    //     },
                }}
            >
                <CloseDiv>
                    <Container
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            maxWidth: '100% !important',
                            padding: '10px !important',
                            margin: '0px !important'

                        }}

                    >
                        <CloseIcon
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: 'rgb(197,16,49)'
                                }
                            }}
                            onClick={closePopUpFunction}
                        />
                    </Container>
                </CloseDiv>
                <Container
                    sx={{
                        minHeight: '500px', '&::-webkit-scrollbar': { display: 'none' }, overflow: 'auto',
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                        maxWidth: '100% !important'
                    }}
                >

                    {organisation ? (
                        <PopUpComponent
                            organisation={organisation}
                            ClosePopUpFunction={closePopUpFunction}
                        />
                    )
                        : (
                            <PopUpComponent
                                folderId={folderId}
                                refreshRecords={refreshRecords}
                                projectsCurrentUser={projectsCurrentUser}
                                selectedProjectId={selectedProjectId}
                                ClosePopUpFunction={closePopUpFunction}
                                serviceId={serviceId}
                            />
                        )}
                </Container>
            </PopUpInspectBox>
        </Modal>
    );
}
PopUp.propTypes = {
    openPopUp: PropTypes.bool.isRequired,
    closePopUpFunction: PropTypes.func,
    PopUpComponent: PropTypes.elementType.isRequired,
    organisation: PropTypes.string,
    refreshRecords: PropTypes.func,
    projectsCurrentUser: PropTypes.string,
    selectedProjectId: PropTypes.string,
    serviceId: PropTypes.string,
};
export default PopUp;
