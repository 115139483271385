import PropTypes from 'prop-types';
import { AvlChip } from '../../Shared/Inputs/AvlChip/AvlChip';
import styles from './OrgWithIssues.module.css';
import { UrlActionType, UrlButton } from '../../Shared/UrlButton/UrlButton';

export function OrgWithIssues({ issues, orgName, labels, isFullWidth = false }) {
    const findLabelByKeyValue = (keyValue) => {
        return labels.find((label) => label.keyValue === keyValue);
    };

    return (
        <div className={styles.orgIssuesCard} style={isFullWidth ? { width: '100%' } : undefined}>
            <div className={styles.orgName}>
                <UrlButton
                    buttonStyles={{
                        link: {
                            width: '20px',
                            height: '20px',
                        },
                    }}
                    url={`/search/organisations/${orgName}/`}
                    action={UrlActionType.openInNewTab}
                />
                {orgName}
            </div>
            <div className={styles.orgIssuesList}>
                {
                    issues
                        .filter((keyValue) => keyValue !== 'organisation')
                        .map(findLabelByKeyValue)
                        .filter((label) => !!label)
                        .map((label) => (
                            <AvlChip
                                key={`issue-label-${label.keyValue}`}
                                id={label.keyValue}
                                label={label.name}
                                color={label.color}
                            />
                        ))
                }
            </div>
        </div>
    );
}

OrgWithIssues.propTypes = {
    orgName: PropTypes.string.isRequired,
    issues: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
};
