import { useEffect, useState } from 'react';
import styles from './ImageResizer.module.css';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import PropTypes from 'prop-types';

const DraggingType = {
    horizontal: 'horizontal',
    vertical: 'vertical',
};

export function ImageResizer(
    {
        widthInPx,
        heightInPx,
        imageInBase64,
        onChangeWidth,
        onChangeHeight,
        minWidthInPx,
        maxWidthInPx,
        minHeightInPx,
        maxHeightInPx,
    },
) {
    const [isDragging, setIsDragging] = useState(false);
    const [draggingType, setDraggingType] = useState(DraggingType.horizontal);
    const [startDraggingPoint, setStartDraggingPoint] = useState({
        x: 0,
        y: 0,
    });

    const getMouseMoveDistance = (e) => {
        return {
            x: startDraggingPoint.x - e.clientX,
            y: startDraggingPoint.y - e.clientY,
        };
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        const diff = getMouseMoveDistance(e);

        switch (draggingType) {
            case DraggingType.vertical: {
                const width = widthInPx - diff.x;

                if (width >= minWidthInPx && width <= maxWidthInPx) {
                    handleChangeWidth(width);
                }

                break;
            }
            case DraggingType.horizontal: {
                const height = heightInPx - diff.y;

                if (height >= minHeightInPx && height <= maxHeightInPx) {
                    handleChangeHeight(height);
                }

                break;
            }
        }
    };

    const handleMouseDownHorizontal = (e) => {
        handleMouseDown(e);
        setDraggingType(DraggingType.horizontal);
    };

    const handleMouseDownVertical = (e) => {
        handleMouseDown(e);
        setDraggingType(DraggingType.vertical);
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartDraggingPoint({
            x: e.clientX,
            y: e.clientY,
        });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleChangeWidth = (pixels) => {
        const width = pixels.toFixed(0);
        onChangeWidth?.(+width);
    };

    const handleChangeHeight = (pixels) => {
        const height = pixels.toFixed(0);
        onChangeHeight?.(+height);
    };

    const setGrabCursor = () => {
        document.body.style.cursor = 'grabbing';
    };

    const unsetCursor = () => {
        document.body.style.cursor = 'unset';
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            setGrabCursor();
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            unsetCursor();
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    return (
        <div className={styles.preview}>
            {
                !!imageInBase64 && (
                    <div
                        key="image-preview"
                        className={styles.frame}
                        style={{
                            width: `${widthInPx}px`,
                            height: `${heightInPx}px`,
                            minWidth: `${minWidthInPx}px`,
                            minHeight: `${minHeightInPx}px`,
                            maxWidth: `${maxWidthInPx}px`,
                            maxHeight: `${maxHeightInPx}px`,
                        }}
                    >
                        <img
                            className={styles.image}
                            src={imageInBase64}
                            alt="Base64 Image"
                        />
                        <div
                            className={`${styles.verticalDragPlace} ${isDragging ? styles.grabbing : styles.grab}`}
                            onMouseDown={handleMouseDownVertical}
                        >
                            <DragHandleIcon className={styles.verticalDragIcon} color="action" />
                        </div>
                        <div
                            className={`${styles.horizontalDragPlace} ${isDragging
                                ? styles.grabbing
                                : styles.grab}`}
                            onMouseDown={handleMouseDownHorizontal}
                        >
                            <DragHandleIcon color="action" />
                        </div>
                    </div>
                )
            }
        </div>
    );
}

ImageResizer.propTypes = {
    widthInPx: PropTypes.number.isRequired,
    heightInPx: PropTypes.number.isRequired,
    imageInBase64: PropTypes.string.isRequired,
    minWidthInPx: PropTypes.number.isRequired,
    maxWidthInPx: PropTypes.number.isRequired,
    minHeightInPx: PropTypes.number.isRequired,
    maxHeightInPx: PropTypes.number.isRequired,
    onChangeWidth: PropTypes.func,
    onChangeHeight: PropTypes.func,
};
