import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../../../Orgs.css';
import { orgUsersList } from '../../../../../../Services/Search.services';
import SkeletonComponent from '../../../../../SkeletonComponent';
import { Alert, Grid, Pagination, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { UrlActionType, UrlButton } from '../../../../../Shared/UrlButton/UrlButton';
import { datetimeToFormattedString } from '../../../../../../Utils/Utils';

export default function OrgAccountTable({ orgName, filterQuery = '' }) {
    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const makeParams = (pagination_page) => {
        let urlParam = 'limit=' + limit;

        if (filterQuery) {
            urlParam += '&query=' + filterQuery;
        }

        if (page) {
            urlParam += '&offset=' + (pagination_page - 1) * limit;
        }

        return urlParam;
    };

    const getRecords = (page) => {
        const urlParam = makeParams(page);
        orgUsersList(orgName, urlParam)
            .then((res) => {
                if ((res && res.stack && res.message)) {
                    setRecords([]);
                    setLimit(10);
                    setPageCount(1);
                    setIsLoading(false);
                } else {
                    let dataResp = res.data;
                    setRecords(dataResp.users);
                    let count = Number(dataResp.count);
                    let limit = Number(dataResp.limit);
                    let pcount = Math.ceil((count / limit));
                    setPageCount(pcount);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setRecords([]);
                setLimit(10);
                setPageCount(1);
                setIsLoading(false);
            });
    };

    const handlePageChange = (event, value) => {
        setIsLoading(true);
        setPage(value);
        getRecords(value);
    };

    useEffect(() => {
        setIsLoading(true);
        setPage(1);
        getRecords(1);
    }, [filterQuery, orgName]);

    return (
        <>
            {isLoading
                ? (<SkeletonComponent />)
                : (
                    records.length > 0
                        ? (
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="user table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>First Sign In</TableCell>
                                            <TableCell>Last Sign In</TableCell>
                                            <TableCell>Sign In Method</TableCell>
                                            <TableCell align='center'>Sign Up Link</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {records.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row.name || '-'}</TableCell>
                                                <TableCell>{row.email || '-'}</TableCell>
                                                <TableCell>{datetimeToFormattedString(row.firstSignIn)}</TableCell>
                                                <TableCell>{datetimeToFormattedString(row.lastSignIn)}</TableCell>
                                                <TableCell>{row.signInMethod || '-'}</TableCell>
                                                <TableCell align='center'>
                                                    <UrlButton
                                                        url={row.signUpLink}
                                                        action={UrlActionType.copyToClipboard}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                        : (
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="warning">Records not found!</Alert>
                            </Stack>
                        )
                )}
            <Grid container item spacing={2} xs={12} sm={12} md={12} sx={{ ml: 2, mt: 2, mb: 2 }}>
                <Stack spacing={2}>
                    <Pagination
                        showFirstButton
                        showLastButton
                        count={pageCount}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange}
                    />
                </Stack>
            </Grid>
        </>
    );
}

OrgAccountTable.propTypes = {
    orgName: PropTypes.string.isRequired,
    filterQuery: PropTypes.string,
};
