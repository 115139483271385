import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OrgAccountActivateDialog from '../BodyComponent/Organizations/Tabs/OrgDetailsTab/OrgDetailsContent/OrgAccountActivateDialog';
import { checkUserStatus, disableUsersInOrganisation } from '../../Services/Search.services';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';

function ScrollDialog({ organisationName }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isEnablingMode, setIsEnablingMode] = useState(true);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const descriptionElementRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [isOpen]);

    const handleClickOpen = (isEnable) => () => {
        setIsEnablingMode(isEnable);
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const disableAccounts = (disabled) => {
        setIsButtonLoading(true);
        disableUsersInOrganisation(organisationName, disabled)
            .then((res) => {
                checkUserStatus(res)
                    .then(() => {
                        setIsButtonLoading(false);
                        setIsOpen(false);
                    });
            });
    };

    return (
        <>
            <Button
                className="button"
                variant="contained"
                size="small"
                color="primary"
                onClick={handleClickOpen(true)}
            >
                Enable accounts
            </Button>
            <Button
                className="button"
                variant="contained"
                size="small"
                color="primary"
                onClick={handleClickOpen(false)}
            >
                Disable accounts
            </Button>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    {isEnablingMode ? 'Disabled Firebase Users' : 'Enabled Firebase Users'}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <OrgAccountActivateDialog
                        organisationName={organisationName}
                        enabled={!isEnablingMode}
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button
                            disabled={isButtonLoading}
                            onClick={() => disableAccounts(!isEnablingMode)}
                        >
                            {' '}
                            {isEnablingMode ? 'Enable Users' : 'Disable Users'}
                        </Button>
                        {isButtonLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ScrollDialog;
