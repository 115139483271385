import { useState } from 'react';
import PropTypes from 'prop-types';
import { AvlTextField } from '../../../../../../Shared/Inputs/FieldText/AvlTextField';

export function RegexMatcher({ className, regex, label }) {
    const [isRegexMatch, setIsRegexMatch] = useState(true);
    const [userValue, setUserValue] = useState('');

    const handleChange = (e) => {
        const field = e.target;
        const value = field.value;
        let isValueMatch = true;

        try {
            isValueMatch = !!value.match(`^${regex}$`);
        } catch (e) {
            isValueMatch = false;
        }

        if (!value) {
            field.style.color = 'black';
        } else if (isValueMatch) {
            field.style.color = 'green';
        } else {
            field.style.color = 'red';
        }

        setUserValue(value);
        setIsRegexMatch(isValueMatch);
    };

    return (
        <div>
            <AvlTextField
                spellcheck={false}
                className={className}
                label={label || 'Value to check'}
                value={userValue}
                onChange={(e) => handleChange(e)}
                helperText={`Check if your row matches to "${regex}" regex`}
            />
        </div>
    );
}

RegexMatcher.propTypes = {
    className: PropTypes.string,
    regex: PropTypes.string.isRequired,
    label: PropTypes.string,
};
