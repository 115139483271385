import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { checkOrganisationsStatus } from '../../Services/Search.services';
import SkeletonComponent from '../../Components/SkeletonComponent';
import { Header } from '../../Components/Shared/Header/Header';
import { ListWithOtherOrgsIssues } from '../../Components/BodyComponent/OrgsCheck/ListWithOtherOrgsIssues';
import { AvlSwitch } from '../../Components/Shared/Inputs/Switch/AvlSwitch';
import styles from './OrgsCheck.module.css';
import { MissingOrgFields } from '../../Components/BodyComponent/OrgsCheck/MissingOrgFields';

const MidContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '20px',
    padding: '20px',
}));

function OrgsCheck() {
    const [isLoading, setIsLoading] = useState(true);
    const [isTrial, setIsTrial] = useState(false);
    const [orgData, setOrgData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [folderData, setFolderData] = useState([]);
    const [aliasData, setAliasData] = useState([]);

    const fetchData = (isTrial) => {
        setIsLoading(true);

        checkOrganisationsStatus(isTrial)
            .then((data) => {
                setOrgData(data['orgs']);
                setUserData(data['check_user_profile_orgs']);
                setFolderData(data['check_folder_orgs']);
                setAliasData(data['check_organisation_aliases']);
            })
            .finally(() => setIsLoading(false));
    };

    const handleChangeIsTrial = (isTrial) => {
        setIsTrial(isTrial);
        fetchData(isTrial);
    };

    useEffect(() => {
        fetchData(isTrial);
    }, []);

    return (
        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2, mb: 2 }}>
            <Card component={Paper}>
                <CardContent>
                    <div className="header">
                        <Header
                            title="Check Organisations"
                            actionButtons={[(
                                <AvlSwitch
                                    key="form"
                                    label="Show trial"
                                    checked={isTrial}
                                    onSwitch={handleChangeIsTrial}
                                />
                            )]}
                        />
                    </div>
                    {
                        isLoading
                            ? (<SkeletonComponent />)
                            : (
                                <MidContainer className={styles.errorSections}>
                                    <MissingOrgFields
                                        label="Organisation fields that are missing"
                                        orgData={orgData}
                                    />
                                    <div className={styles.otherErrorsSection}>
                                        {userData?.length && (
                                            <div className={styles.otherError}>
                                                <ListWithOtherOrgsIssues
                                                    issueName="User Profile Organisations that don&apos;t
                                            exist in the plan table"
                                                    items={userData}
                                                />
                                            </div>
                                        )}
                                        {folderData?.length && (
                                            <div className={styles.otherError}>
                                                <ListWithOtherOrgsIssues
                                                    issueName="Folder Organisations that don&apos;t exist
                                            in the plan table"
                                                    items={folderData}
                                                />
                                            </div>
                                        )}
                                        {aliasData?.length && (
                                            <div className={styles.otherError}>
                                                <ListWithOtherOrgsIssues
                                                    issueName="Aliased Organisations that don&apos;t exist
                                            in Plan Table"
                                                    items={aliasData}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </MidContainer>
                            )
                    }
                </CardContent>
            </Card>
        </Grid>
    );
}

export default OrgsCheck;
