import { useState } from 'react';
import { Box, Card, CardContent, Grid, Paper, Tab, Tabs } from '@mui/material';
import { OrgInvoicesTab } from './Tabs/OrgInvoicesTab/OrgInvoicesTab';
import { OrgAccountsTab } from './Tabs/OrgAccountsTab/OrgAccountsTab';
import { OrgDetailsTab } from './Tabs/OrgDetailsTab/OrgDetailsTab';
import { OrgProjectsTab } from './Tabs/OrgProjectsTab';
import { OrgStatsTab } from './Tabs/OrgStatsTab';
import { OrgReportsTab } from './Tabs/OrgReportsTab';

const PageTabs = {
    organisationDetails: 0,
    invoices: 1,
    projects: 2,
    accounts: 3,
    stats: 4,
    reports: 5,
};

export default function OrganizationPage({ orgName }) {
    const [tabId, setTabId] = useState(0);

    const handleTabChange = (event, newTabId) => {
        setTabId(newTabId);
    };

    let currentTabComponent;
    switch (tabId) {
        case PageTabs.organisationDetails:
            currentTabComponent = <OrgDetailsTab key={orgName} orgName={orgName} />;
            break;
        case PageTabs.invoices:
            currentTabComponent = <OrgInvoicesTab key={orgName} orgName={orgName} />;
            break;
        case PageTabs.projects:
            currentTabComponent = <OrgProjectsTab key={orgName} orgName={orgName} />;
            break;
        case PageTabs.accounts:
            currentTabComponent = <OrgAccountsTab key={orgName} orgName={orgName} />;
            break;
        case PageTabs.stats:
            currentTabComponent = <OrgStatsTab key={orgName} orgName={orgName} />;
            break;
        case PageTabs.reports:
            currentTabComponent = <OrgReportsTab key={orgName} orgName={orgName} />;
            break;
    }

    return (
        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2, mb: 2 }}>
            <Card component={Paper}>
                <CardContent>
                    <Box sx={{ width: '100%' }}>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Tabs
                                onChange={handleTabChange}
                                value={tabId}
                                aria-label="Tabs where each tab needs to be selected manually"
                            >
                                <Tab label="Organisation Details" />
                                <Tab label="Invoices" />
                                <Tab label="Projects" />
                                <Tab label="Accounts" />
                                <Tab label="Stats" />
                                <Tab label="Reports" />
                            </Tabs>
                        </Box>
                    </Box>
                    {currentTabComponent}
                </CardContent>
            </Card>
        </Grid>
    );
}
