import { UrlActionType, UrlButton } from './UrlButton/UrlButton';
import { Alert, Stack } from '@mui/material';
import { AvlTable } from './Table/Table';
import PropTypes from 'prop-types';
import { Apps } from './Apps';
import { AvlCheckbox } from './Inputs/Checkbox/AvlCheckbox';
import { ShowMore } from './ShowMore/ShowMore.jsx';

const FieldKeys = {
    displayName: 'display_name',
    organisation: 'organisation',
    apps: 'apps',
    tierName: 'tier_name',
    isHmlrEnabled: 'hmlr_enabled',
    isSearchesEnabled: 'searches',
    isDisabled: 'is_disabled',
    isTrial: 'is_trial',
    trialStartedAt: 'trial_started_at',
    trialEndedAt: 'trial_ended_at',

    // Nests
    imanageConfig: 'imanage_config',
    featureFlags: 'feature_flags',

    featureFlagsKeys: {
        isEnabled: 'is_enabled',
        name: 'name',
        userName: 'user_name',
    },
    imanageConfigKeys: {
        url: 'imanage_url',
        isAllHaveAccess: 'all_have_access',
        activeUsers: 'active_users',
    },
};

export const Columns = {
    [FieldKeys.organisation]: 'Organisation',
    [FieldKeys.isHmlrEnabled]: 'HMLR',
    [FieldKeys.isSearchesEnabled]: 'Searches',
    [FieldKeys.isDisabled]: 'Is Disabled',
    [FieldKeys.displayName]: 'Display Name',
    [FieldKeys.apps]: 'Apps',
    [FieldKeys.isTrial]: 'Is Trial',
    [FieldKeys.featureFlags]: 'Feature Flags',
    [FieldKeys.imanageConfigKeys.url]: 'iManage URL',
    [FieldKeys.imanageConfigKeys.isAllHaveAccess]: 'Is all have access (iManage)',
    [FieldKeys.imanageConfigKeys.activeUsers]: 'Active Users (iManage)',
};

export function OverallConfigurationsTable({ configs, page, pageCount, onPageChange }) {
    const centeredHeaders = [
        FieldKeys.isSearchesEnabled,
        FieldKeys.isTrial,
        FieldKeys.isHmlrEnabled,
        FieldKeys.imanageConfigKeys.isAllHaveAccess,
    ];
    const tableHeaders = Object.values(Columns)
        .map((columnName) => ({
            label: columnName,
            align: centeredHeaders.includes(columnName) ? 'center' : 'left',
        }));

    const convertToCells = (row, rowIndex) => [
        {
            style: { verticalAlign: 'top' },
            data: row[FieldKeys.organisation] || '',
        },
        {
            style: { textAlign: 'center', verticalAlign: 'top', },
            data: (
                <AvlCheckbox
                    value={row[FieldKeys.isHmlrEnabled]}
                />
            ),
        },
        {
            style: { textAlign: 'center', verticalAlign: 'top', },
            data: (
                <AvlCheckbox
                    value={row[FieldKeys.isSearchesEnabled]}
                />
            ),
        },
        {
            style: { textAlign: 'center', verticalAlign: 'top', },
            data: typeof row[FieldKeys.isDisabled] === 'boolean' ? (
                <AvlCheckbox
                    value={row[FieldKeys.isDisabled]}
                />
            ) : 'None',
        },
        {
            style: { verticalAlign: 'top' },
            data: row[FieldKeys.displayName] || '',
        },
        {
            style: { minWidth: '110px', verticalAlign: 'top', },
            data: row.apps?.length
                ? (
                    <Apps apps={row.apps} />
                )
                : 'No subscriptions',
        },
        {
            style: { textAlign: 'center', verticalAlign: 'top', },
            data: (
                <AvlCheckbox
                    isDisabled
                    value={row[FieldKeys.isTrial]}
                />
            ),
        },
        {
            style: { minWidth: '450px', verticalAlign: 'top', },
            data: row[FieldKeys.featureFlags]?.length
                ? (
                    <ShowMore collapsedSize={80}>
                        {
                            row[FieldKeys.featureFlags].map((flag, index) => (
                                <div key={index}>
                                    <AvlCheckbox
                                        isDisabled
                                        label={`${flag[FieldKeys.featureFlagsKeys.name]}: [${flag[FieldKeys.featureFlagsKeys.userName]}]`}
                                        value={flag[FieldKeys.featureFlagsKeys.isEnabled]}
                                    />
                                </div>
                            ))
                        }
                    </ShowMore>
                )
                : 'No flags',
        },
        {
            style: { verticalAlign: 'top' },
            data: row[FieldKeys.imanageConfig][FieldKeys.imanageConfigKeys.url]
                ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'nowrap' }}>
                            {row[FieldKeys.imanageConfig][FieldKeys.imanageConfigKeys.url]}
                        </div>
                        <UrlButton
                            buttonStyles={{
                                link: { width: '32px', height: '32px' },
                            }}
                            action={UrlActionType.openInNewTab}
                            url={row[FieldKeys.imanageConfig][FieldKeys.imanageConfigKeys.url]}
                        />
                    </div>
                )
                : 'None',
        },
        {
            style: { textAlign: 'center', verticalAlign: 'top', },
            data: (
                <AvlCheckbox
                    isDisabled
                    value={row[FieldKeys.imanageConfig][FieldKeys.imanageConfigKeys.isAllHaveAccess]}
                />
            ),
        },
        {
            style: { verticalAlign: 'top' },
            data: row[FieldKeys.imanageConfig][FieldKeys.imanageConfigKeys.activeUsers]?.join(', ') || '',
        },
    ];

    const tableRows = configs.map((row, rowIndex) => ({
        cells: convertToCells(row, rowIndex),
        isCellWrapped: true,
    }));

    return configs.length > 0
        ? (
            <AvlTable
                headers={tableHeaders}
                rows={tableRows}
                page={page}
                pageCount={pageCount}
                onPageChange={onPageChange}
            />
        )
        : (
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="warning">Records not found!</Alert>
            </Stack>
        );
}

OverallConfigurationsTable.propTypes = {
    configs: PropTypes.array.isRequired,
    pageCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};
