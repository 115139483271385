import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import enGB from 'date-fns/locale/en-gb';

export function AvlDatePickerField({ label = '', value, onChange, ...props }) {
    const sx = {
        '.MuiInputAdornment-root': {
            marginRight: '10px',
        },
    };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enGB}
        >
            <DatePicker
                slotProps={{
                    textField: {
                        variant: 'standard',
                        required: props.required,
                    },
                }}
                helperText={props.helperText}
                className={props.className}
                disabled={props.disabled}
                onChange={onChange}
                format={props.format}
                error={props.error}
                sx={props.sx || sx}
                name={props.name}
                key={props.key}
                id={props.id}
                label={label}
                value={value}
            />
        </LocalizationProvider>
    );
}

AvlDatePickerField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    props: PropTypes.object,
};
