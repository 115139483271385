import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { AvlTextField } from '../../Shared/Inputs/FieldText/AvlTextField';
import styles from './MissingOrgFieldsPicker.module.css';
import { AvlChip } from '../../Shared/Inputs/AvlChip/AvlChip';
import Button from '@mui/material/Button';

function MissingOrgFieldsPicker({ labels, onChange, selectedLabels, initialLabels = [] }) {
    const [isInputFocused, setIsInputFocused] = useState(false);
    const theme = useTheme();

    const handleDelete = (key) => {
        const filteredValues = selectedLabels.filter((label) => label.keyValue !== key);
        onChange(filteredValues);
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
    };

    const handleInputBlur = () => {
        setIsInputFocused(false);
    };

    const handleInputChange = (event, newValue) => {
        onChange(newValue);
    };

    const handleReset = () => {
        onChange(initialLabels);
    };

    const handleComparison = (option, value) => {
        return option.keyValue === value.keyValue;
    };

    return (
        <div className={styles.inputAndButton}>
            <Autocomplete
                multiple
                disableCloseOnSelect
                className={styles.input}
                value={selectedLabels}
                open={isInputFocused}
                isOptionEqualToValue={handleComparison}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                renderTags={(value, getTagProps) => (
                    <div className={styles.labelsList}>
                        {
                            value.map((label, index) => (
                                <AvlChip
                                    key={`autocomplete-label-${label.keyValue}`}
                                    id={label.keyValue}
                                    onDelete={handleDelete}
                                    label={label.name}
                                    color={label.color}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    </div>
                )}
                noOptionsText="Label not found"
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Box
                            component={DoneIcon}
                            sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                            style={{
                                visibility: selected ? 'visible' : 'hidden',
                            }}
                        />
                        <Box
                            component="span"
                            sx={{
                                width: 14,
                                height: 14,
                                flexShrink: 0,
                                borderRadius: '3px',
                                mr: 1,
                                mt: '2px',
                            }}
                            style={{ backgroundColor: option.color }}
                        />
                        <Box
                            sx={{
                                flexGrow: 1,
                                '& span': {
                                    color:
                                        theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                                },
                            }}
                        >
                            {option.name}
                            <br />
                            <span>{option.description}</span>
                        </Box>
                        <Box
                            component={CloseIcon}
                            sx={{ opacity: 0.6, width: 18, height: 18 }}
                            style={{
                                visibility: selected ? 'visible' : 'hidden',
                            }}
                        />
                    </li>
                )}
                options={[...labels].sort((a, b) => {
                    // Display the selected labels first.
                    let ai = selectedLabels.indexOf(a);
                    ai = ai === -1 ? selectedLabels.length + labels.indexOf(a) : ai;
                    let bi = selectedLabels.indexOf(b);
                    bi = bi === -1 ? selectedLabels.length + labels.indexOf(b) : bi;
                    return ai - bi;
                })}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <AvlTextField
                        {...params}
                        autoFocus
                        placeholder="Filter labels"
                    />
                )}
            />
            <Button
                size="small"
                variant="contained"
                onClick={handleReset}
            >
                Reset
            </Button>
        </div>
    );
}

export default MissingOrgFieldsPicker;
