import { Unit } from '../Components/Shared/Inputs/ImageResizerAndFields/ImageResizerAndFields';

export function useCmAndPixelsConverter() {
    const cmPerPixel = () => {
        const inchesPerCm = 2.54;
        const pixelsPerInch = window.devicePixelRatio * 96;

        return inchesPerCm / pixelsPerInch;
    };

    const cmToPixel = (cm) => cm / cmPerPixel();

    const pixelToCm = (px) => px * cmPerPixel();

    const unitToPixels = (value, unit) => unit === Unit.cm ? cmToPixel(value) : value;

    const unitToCm = (value, unit) => unit === Unit.px ? pixelToCm(value) : value;

    return { cmPerPixel, cmToPixel, pixelToCm, unitToPixels, unitToCm };
}
