export function useImageHelper() {
    const getImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
                resolve({
                    width: this.width,
                    height: this.height,
                });
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        });
    };

    const normalyzeToBase64 = (image) => {
        const beginningOfBase64 = 'data:image/png;base64,';

        if (!image) return '';

        return image?.startsWith(beginningOfBase64) ? image : `${beginningOfBase64}${image}`;
    };

    const getImageInBase64 = (file) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
        });
    };

    return { getImageDimensions, normalyzeToBase64, getImageInBase64 };
}
