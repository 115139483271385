import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getOrganisationUsers } from '../../../../../../Services/Search.services';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Container, Typography } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

function OrgAccountActivateDialog({ organisationName, enabled }) {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        getOrganisationUsers(
            organisationName,
            enabled
        )
            .then((response) => {
                setUsers(response['users']);
                setLoading(false);

            });

    }, []);

    return (
        <>
            {loading ? (
                <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress
                        sx={{ backgroundColor: '#fff', color: '#4a78cb', }}
                        color="inherit"
                    />
                </Container>
            ) : (
                <>
                    {users?.length > 0 ? (
                        <>
                            {users.map((user, index) => (
                                <Container
                                    key={'index' + index}
                                    style={{
                                        padding: 0,
                                    }}
                                >
                                    <Accordion key={index}>
                                        <AccordionSummary
                                            expandIcon={<UnfoldMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                style={{
                                                    fontWeight: 600,


                                                }}
                                            >
                                                {user.email}
                                            </Typography>
                                        </AccordionSummary>
                                        {Object.keys(user)
                                            .map((key, index) => {
                                                if (key !== 'email') {
                                                    return (
                                                        <AccordionDetails key={index}>
                                                            <Typography>
                                                                <strong>{key}</strong>
                                                                :
                                                                {user[key].toString()}
                                                            </Typography>
                                                        </AccordionDetails>
                                                    );
                                                }
                                            })}
                                    </Accordion>
                                </Container>

                            ))}
                        </>
                    ) : (
                        <Typography
                            style={{
                                fontWeight: 600,


                            }}
                        >
                            {enabled ? 'There are no enabled users in this organisation' :
                                'There are no disabled users in this organisation'}
                        </Typography>
                    )}
                </>
            )}
        </>
    );
}
OrgAccountActivateDialog.propTypes = {
    organisationName: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
};
export default OrgAccountActivateDialog;
