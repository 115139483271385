import { useEffect, useState } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { getServiceStatus } from '../Services/Search.services';
import SkeletonComponent from '../Components/SkeletonComponent';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import FileCopy from '@mui/icons-material/FileCopy';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FeedIcon from '@mui/icons-material/Feed';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import PopUp from '../Components/Shared/PopUp';
import PopUpAddServiceComponent from '../Components/BodyComponent/ServiceStatus/PopUpAddServiceComponent';
import PopUpEditServiceComponent from '../Components/BodyComponent/ServiceStatus/PopUpEditServiceComponent';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DeleteService from '../Components/Shared/DeleteService';
import { datetimeToFormattedStringUtc, removeTimezoneOffset, toIsoIgnoreTimezone } from '../Utils/Utils';
import { Header } from '../Components/Shared/Header/Header';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckIcon from '@mui/icons-material/Check';

function ServiceStatus() {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedServiceId, setSelectedServiceId] = useState('');
    const [openEditing, setOpenEditing] = useState(false);
    const [openDeleting, setOpenDeleting] = useState(false);
    const [openAdding, setOpenAdding] = useState(false);
    const [rowValues, setRowValues] = useState({});
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getServiceStatus();
                if (res.status === 200) {
                    setServices(res.data);
                    setLoading(false);
                } else {
                    setLoading(true);
                }
            } catch (error) {
                setLoading(true);
            }
        };
        void fetchData();
    }, []);

    const getServices = async () => {
        try {
            const res = await getServiceStatus();
            if (res.status === 200) {
                setServices(res.data);
                setLoading(false);
            } else {
                setLoading(true);
            }
        } catch (error) {
            setLoading(true);
        }
    };

    const getDateStatus = (endAt, startAt) => {
        const currentDate = new Date();
        const endDate = new Date(endAt);
        const startDate = new Date(startAt);

        if (startDate.getTime() < currentDate.getTime() && currentDate.getTime() < endDate.getTime()) {
            // return "red"; // the date has passed
            return (
                <Tooltip title="Service status: Currently on Service.">
                    <KeyboardDoubleArrowDownIcon
                        style={{
                            backgroundColor: 'rgba(208, 52, 44, 0.5)',
                            borderRadius: '50%',
                            padding: '4px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                        }}
                    />
                </Tooltip>
            );
        } else if (startDate.getTime() > currentDate.getTime()) {
            // return "yellow"; // the date is within two weeks
            return (
                <Tooltip title="Service status: Will be on Service in the future.">
                    <KeyboardDoubleArrowRightIcon
                        style={{
                            backgroundColor: 'rgba(238, 210, 2, 0.5)',
                            borderRadius: '50%',
                            padding: '4px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                        }}
                    />
                </Tooltip>
            );
        } else {
            return (
                <Tooltip title="Service status: Passed.">
                    <KeyboardDoubleArrowLeftIcon
                        style={{
                            backgroundColor: 'rgba(0, 128, 0, 0.5)',
                            borderRadius: '50%',
                            padding: '4px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                        }}
                    />
                </Tooltip>
            );
            // return "green"; // the date is more than two weeks away
        }
    };

    const handleAddNewService = () => {
        const initValues = {
            'is_down': true,
            'end_at': new Date()
                .toISOString(),
            'start_at': new Date()
                .toISOString(),
        };

        setInitialValues(initValues);
        setOpenAdding(true);
    };

    const handleDuplicate = (row) => {
        const oldEndAt = row['end_at'];
        const oldStartAt = row['start_at'];
        const oldEndAtDate = removeTimezoneOffset(new Date(oldEndAt));
        const oldStartAtDate = removeTimezoneOffset(new Date(oldStartAt));
        const values = {
            ...row,
            'end_at': toIsoIgnoreTimezone(oldEndAtDate),
            'start_at': toIsoIgnoreTimezone(oldStartAtDate),
        };

        setSelectedServiceId(row.id);
        setInitialValues(values);
        setOpenAdding(true);
    };

    return (
        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2, mb: 2 }}>
            <Card component={Paper}>
                <CardContent>
                    <div className="header">
                        <Header
                            title="Service Status"
                            actionButtons={[
                                <Button
                                    key="add-new-service-button"
                                    variant="contained"
                                    sx={{ ml: 2 }}
                                    onClick={handleAddNewService}
                                >
                                    Add New Service
                                </Button>,
                            ]}
                        />
                        <PopUp
                            openPopUp={openAdding}
                            closePopUpFunction={() => setOpenAdding(false)}
                            PopUpComponent={() => PopUpAddServiceComponent({
                                getServices,
                                setLoading,
                                setOpenAdding,
                                initialValues,
                            })}
                        />
                    </div>
                    {loading ? (<SkeletonComponent />) : (
                        <Grid container item>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="user table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Service Status</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="center">Is Down</TableCell>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Start At</TableCell>
                                            <TableCell>End At</TableCell>
                                            <TableCell align="center">Message</TableCell>
                                            <TableCell align="center">Edit</TableCell>
                                            <TableCell align="center">Delete</TableCell>
                                            <TableCell align="center">Duplicate</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {services.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{getDateStatus(row.end_at, row.start_at)}</TableCell>
                                                <TableCell>{row.name || '-'}</TableCell>
                                                <TableCell align="center">
                                                    {
                                                        row.is_down
                                                            ? (<CheckIcon />)
                                                            : (<NotInterestedIcon />)
                                                    }
                                                </TableCell>
                                                <TableCell>{row.id || '-'}</TableCell>
                                                <TableCell style={{ minWidth: '90px' }}>
                                                    {datetimeToFormattedStringUtc(row.start_at)}
                                                </TableCell>
                                                <TableCell style={{ minWidth: '90px' }}>
                                                    {datetimeToFormattedStringUtc(row.end_at)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.message
                                                        ? (
                                                            <Tooltip title={row.message}>
                                                                <IconButton>
                                                                    <FeedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title={row.message}>
                                                                <IconButton>
                                                                    <SpeakerNotesOffIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Edit">
                                                        <IconButton
                                                            id={'button_edit' + row.id}
                                                            onClick={() => {
                                                                setSelectedServiceId(row.id);
                                                                setRowValues(row);
                                                                setOpenEditing(true);
                                                            }}
                                                        >
                                                            <ModeEditOutlineIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            id={'button_delete' + row.id}
                                                            onClick={() => {
                                                                setSelectedServiceId(row.id);
                                                                setOpenDeleting(true);
                                                            }}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Duplicate">
                                                        <IconButton
                                                            id={'buttonDuplicate' + row.id}
                                                            onClick={() => handleDuplicate(row)}
                                                        >
                                                            <FileCopy />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                </CardContent>
            </Card>
            <PopUp
                openPopUp={openEditing}
                closePopUpFunction={() => setOpenEditing(false)}
                PopUpComponent={() => PopUpEditServiceComponent({
                    getServices,
                    setLoading,
                    setOpenEditing,
                    selectedServiceId,
                    rowValues,
                })}
            />
            <PopUp
                openPopUp={openDeleting}
                closePopUpFunction={() => setOpenDeleting(false)}
                PopUpComponent={DeleteService}
                serviceId={selectedServiceId}
                refreshRecords={getServices}
            />
        </Grid>
    );
}

ServiceStatus.propTypes = {};
export default ServiceStatus;
