import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import PropTypes from 'prop-types';
import styles from './SearchBarWrapper.module.css';

function SearchBarWrapper({ component, query }) {
    const navigate = useNavigate();
    const handleOptionSelect = (selectedOption) => {
        const type = selectedOption.type;
        const data = selectedOption.data;
        const entityId = selectedOption.id;

        if (type === 'user') {
            navigate(`/search/users/${data}/ids/${entityId}/`);
        } else if (type === 'company') {
            navigate(`/search/organisations/${data}/`);
        } else if (type === 'folder') {
            const folderId = selectedOption.display;
            navigate(`/search/users/${data}/ids/${entityId}/folders/${folderId}`);
        }
    };

    return (
        <>
            <div className={styles.searchWrapper}>
                <SearchBar
                    query={query}
                    onOptionSelected={handleOptionSelect}
                    placeholder="Enter the search keyword..."
                    data={[]}
                />
            </div>
            {component}
        </>
    );
}

SearchBarWrapper.propTypes = {
    component: PropTypes.object,
};
export default SearchBarWrapper;
