import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { signInToFirebaseByPassword } from '../../Store/Actions/AuthenticationActions';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

function SignIn({ signInToAvail, authState }) {
    const authError = authState?.error;

    const handleSubmit = (values) => {
        const email = values['email'];
        const password = values['password'];

        signInToAvail(email, password);
    };

    const validationSchema = yup.object({
        email: yup
            .string()
            .email('Please wright valid email. Ex. email@gmail.com')
            .required('Email is required'),
        password: yup
            .string()
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <Typography component="h1" variant="h5">Sign in by password</Typography>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '400px',
                    width: '100%',
                    marginTop: '8px',
                }}
            >
                {!!authError && (
                    <Stack sx={{ width: '100%', padding: '20px 0' }} spacing={2}>
                        <Alert severity="error">{authError}</Alert>
                    </Stack>
                )}
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={formik.handleChange}
                    error={!!formik.errors.email}
                    helperText={formik.errors.email}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={formik.handleChange}
                    error={!!formik.errors.password}
                    helperText={formik.errors.password}
                />
                <Button
                    style={{ marginTop: '50px' }}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={formik.handleSubmit}
                >
                    Sign In
                </Button>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        alignItems: 'center',
                    }}
                >
                    <NavLink style={{ textDecoration: 'none' }} to="/sso" variant="body">
                        Log in by SSO
                    </NavLink>
                    <NavLink style={{ textDecoration: 'none' }} to="/reset-password" variant="body">
                        Forgot password
                    </NavLink>
                </div>
            </div>
        </>
    );
}

SignIn.propTypes = {};

const dispatchSignInProps = (dispatch) => {
    return {
        signInToAvail: (username, password) => dispatch(signInToFirebaseByPassword(username, password)),
    };
};

const mapStateToProps = (state) => ({
    authState: state.auth,
});

export default connect(mapStateToProps, dispatchSignInProps)(SignIn);
