import { Button, Card, CardContent, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Header } from '../Components/Shared/Header/Header';
import { useEffect, useState } from 'react';
import SkeletonComponent from '../Components/SkeletonComponent';
import { getOverallConfigurations, refreshCache } from '../Services/Search.services';
import { toast } from 'react-toastify';
import { OverallConfigurationsTable } from '../Components/Shared/OverallConfigurationsTable';

export function OverallConfigurations() {
    const [isLoading, setIsLoading] = useState(true);
    const [overallConfigurations, setOverallConfigurations] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getOverallConfigurations()
            .then((configurations) => {
                setOverallConfigurations(configurations.configs);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const handleCacheRefresh = () => {
        setIsLoading(true);

        refreshCache()
            .then((isSuccess) => {
                if (isSuccess) {
                    toast.success('Cache is cleared');
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2, mb: 2 }}>
            <Card component={Paper}>
                <CardContent>
                    <div className="header">
                        <Header
                            title="Overall Configurations"
                            actionButtons={[
                                <Button
                                    key="refresh-cache-button"
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={handleCacheRefresh}
                                >
                                    Clear cache
                                </Button>,
                            ]}
                        />
                    </div>
                    {
                        isLoading
                            ? <SkeletonComponent />
                            : (
                                <OverallConfigurationsTable
                                    configs={overallConfigurations}
                                    page={page}
                                    pageCount={1}
                                    onPageChange={handlePageChange}
                                />
                            )
                    }
                </CardContent>
            </Card>
        </Grid>
    );
}
