import PropTypes from 'prop-types';
import { AvlCheckbox } from '../Checkbox/AvlCheckbox';
import { useState } from 'react';

const CheckboxValues = {
    on: true,
    off: false,
    unknown: null,
};

const convertValueToCheckboxValue = {
    [true]: 'on',
    [false]: 'off',
    [0]: 'off',
    [null]: 'unknown',
    [undefined]: 'unknown',
};

export function ThreeStatesCheckbox({ name, label = '', value, onChange, isDisabled, ...props }) {
    const checkboxValue = CheckboxValues[convertValueToCheckboxValue[value]];

    const handleChange = () => {
        let nextValue = CheckboxValues.unknown;

        switch (checkboxValue) {
            case CheckboxValues.on:
                nextValue = CheckboxValues.unknown;
                break;
            case CheckboxValues.off:
                nextValue = CheckboxValues.on;
                break;
            case CheckboxValues.unknown:
                nextValue = CheckboxValues.off;
                break;
        }

        onChange(nextValue, name);
    };

    return (
        <AvlCheckbox
            label={label}
            name={name}
            value={checkboxValue}
            onChange={handleChange}
            indeterminate={checkboxValue === null}
            error={props.error}
        />
    );
}

ThreeStatesCheckbox.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    props: PropTypes.object,
};
