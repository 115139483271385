import { styled } from '@mui/system';

export const CloseDiv = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        width: '100%',
        height: '50px',
        // backgroundColor: '#ffffff',
        backgroundColor: '#e0edf9',
        // backgroundColor: '#f5f5f5',
        // borderTopLeftRadius: '20px',
        // borderTopRightRadius: '20px',
        borderBottom: '1px solid',
    };
});