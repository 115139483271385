import { useParams } from 'react-router-dom';
import UserDetails from '../Components/BodyComponent/Users/UserDetails';
import SearchBarWrapper from '../Components/BodyComponent/SearchBar/SearchBarWrapper';

function UserInfo() {
    const { userEmail, userId, folderId } = useParams();

    return (
        <SearchBarWrapper
            component={
                <UserDetails userId={userId} email={userEmail} folderId={folderId} />
            }
        />
    );
}
UserInfo.propTypes = {};
export default UserInfo;
