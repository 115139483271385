import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import styles from './MissingInvoicesTab.module.css';
import {
    getMissingInvoices,
    getMissingInvoicesTaskId,
    startMissingInvoicesTask,
} from '../../../../../Services/Search.services';
import { MissingInvoicesTable } from './MissingInvoicesTable';
import { toast } from 'react-toastify';
import { datetimeToFormattedString } from '../../../../../Utils/Utils';
import MissingInvoicesTableFilters from './MissingInvoicesTableFilters';

export function MissingInvoicesTab() {
    const [isLoading, setIsLoading] = useState(true);
    const [missingInvoices, setMissingInvoices] = useState([]);
    const [filteredMissingInvoices, setFilteredMissingInvoices] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [lastUpdate, setLastUpdate] = useState(null);

    const pollMissingInvoicesEndpoint = useCallback((taskId) => {
        const pollingDelayMs = 1000;

        getMissingInvoices(taskId)
            .then((response) => {
                if (response.status === 200) {
                    setMissingInvoices(response.data?.['missingInvoices'] || []);
                    setIsLoading(false);
                } else {
                    setTimeout(() => pollMissingInvoicesEndpoint(taskId), pollingDelayMs);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 404) {
                        toast.error('Missing Invoices not found');
                        setMissingInvoices([]);

                        return;
                    }
                }

                toast.error('Oops, something went wrong');
                setIsLoading(false);
            });
    }, []);

    const loadMissingInvoicesTaskId = useCallback(async () => {
        const { longTaskId: taskId, startedAt } = await getMissingInvoicesTaskId()
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 404) {
                        void generateMissingInvoices();
                    }
                }
            });

        if (taskId) {
            const dateTimeString = datetimeToFormattedString(startedAt, null);

            setLastUpdate(dateTimeString);
            pollMissingInvoicesEndpoint(taskId);
        }
    }, [pollMissingInvoicesEndpoint]);

    const generateMissingInvoices = async () => {
        const isSuccess = await startMissingInvoicesTask();
        setIsSuccess(!!isSuccess);

        if (isSuccess) {
            void loadMissingInvoicesTaskId();
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        void loadMissingInvoicesTaskId();
    }, [loadMissingInvoicesTaskId]);

    const handleRefreshClick = async () => {
        setIsLoading(true);
        await generateMissingInvoices();
    };

    const handleFilterChange = (filteredInvoices) => {
        setFilteredMissingInvoices(filteredInvoices);
    };

    return (
        <div>
            <div className={styles.buttonTimeLabelContainer}>
                <div className={styles.buttonWrapper}>
                    <Button
                        variant="contained"
                        sx={isSuccess && {
                            bgcolor: green[500],
                            '&:hover': {
                                bgcolor: green[700],
                            },
                        }}
                        disabled={isLoading}
                        onClick={handleRefreshClick}
                    >
                        Refresh Missing Invoices
                    </Button>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </div>
                {
                    !!lastUpdate && (
                        <div className={styles.lastUpdateTimeLabel}>
                            <p>{`Last update: ${lastUpdate}`}</p>
                        </div>
                    )
                }
            </div>
            {lastUpdate && (
                <div className={styles.tableFilters}>
                    {missingInvoices.length > 0 && (
                        <MissingInvoicesTableFilters
                            invoices={missingInvoices}
                            onFilterChange={handleFilterChange}
                        />
                    )}
                    <div className={styles.table}>
                        <MissingInvoicesTable
                            isLoading={isLoading}
                            invoices={filteredMissingInvoices}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
