import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Grid, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { styled } from '@mui/system';
import { useFormik } from 'formik';
import { editServiceStatus } from '../../../Services/Search.services';
import * as yup from 'yup';
import { DateTimePicker } from '@mui/x-date-pickers';
import enGB from 'date-fns/locale/en-gb';
import { datetimeFormat } from '../../../Constants/Date.constant';
import { toIsoIgnoreTimezone, removeTimezoneOffset } from '../../../Utils/Utils';

const FormContainer = styled('form')(() => ({
    overflow: 'auto',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    '&::-webkit-scrollbar': { display: 'none' },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
}));

function PopUpEditServiceComponent({ getServices, setLoading, setOpenEditing, selectedServiceId, rowValues }) {
    const validationSchema = yup.object()
        .shape({
            message: yup.string(),
            isDown: yup.boolean(),
            endAt: yup.date(),
            startAt: yup.date(),
        });

    const formikEdit = useFormik({
        enableReinitialize: true,
        initialValues: {
            message: rowValues.message,
            isDown: rowValues.is_down,
            startAt: new Date(rowValues.start_at),
            endAt: new Date(rowValues.end_at),
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await editService(values);
            formikEdit.resetForm();
        },
    });

    useEffect(() => {
        formikEdit.setValues({
            message: rowValues.message,
            isDown: rowValues.is_down,
            startAt: removeTimezoneOffset(new Date(rowValues.start_at)),
            endAt: removeTimezoneOffset(new Date(rowValues.end_at)),
        });
    }, [rowValues]);

    const editService = async (values) => {
        const isSecondsDisabled = true;
        const editRequestData = {
            message: values.message,
            is_down: values.isDown,
            end_at: toIsoIgnoreTimezone(values.endAt, isSecondsDisabled),
            start_at: toIsoIgnoreTimezone(values.startAt, isSecondsDisabled),
        };

        await editServiceStatus(selectedServiceId, editRequestData);

        setLoading(true);
        setOpenEditing(false);
        getServices();
        formikEdit.resetForm();
    };

    return (
        <FormContainer
            onSubmit={formikEdit.handleSubmit}
            noValidate="novalidate"
        >
            <Grid
                id={'editInvoicing1'}
                container
                spacing={0}
                alignItems={'center'}
                height={'100%'}
                style={{ marginTop: '20px' }}
            >
                <Grid
                    id={'editInvoicing2'}
                    item
                    xs={12}
                    sm
                    container
                    flexDirection={'column'}
                    alignItems={'center'}
                    rowSpacing={3}
                    height={'100%'}
                >
                    <Grid item>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={enGB}
                        >
                            <DateTimePicker
                                label="Start At"
                                id="startAt"
                                name="startAt"
                                value={formikEdit.values.startAt}
                                format={datetimeFormat}
                                onChange={(newValue) => {
                                    formikEdit.setFieldValue('startAt', newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={enGB}
                        >
                            <DateTimePicker
                                label="End At"
                                id="endAt"
                                name="endAt"
                                value={formikEdit.values.endAt}
                                format={datetimeFormat}
                                onChange={(newValue) => {
                                    formikEdit.setFieldValue('endAt', newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="message"
                            name="message"
                            label="Message"
                            // variant="standard"
                            value={formikEdit.values.message}
                            onChange={formikEdit.handleChange}
                            error={formikEdit.touched.message && Boolean(formikEdit.errors.message)}
                            helperText={formikEdit.touched.message && formikEdit.errors.message}
                            multiline
                            rows={6}
                            rowsmax={10}
                            style={{ minHeight: '150px', minWidth: '230px' }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            label="Is Down"
                            control={(
                                <Checkbox
                                    id="isDown"
                                    name="isDown"
                                    value={formikEdit.values.isDown}
                                    onChange={formikEdit.handleChange}
                                    checked={formikEdit.values.isDown}
                                    error={formikEdit.touched.isDown && Boolean(formikEdit.errors.isDown)}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            size="small"
                            variant={'outlined'}
                            endIcon={
                                <PublishedWithChangesIcon />
                            }
                            // onClick={handleClose}
                            type={'submit'}
                            sx={{
                                m: 3,
                                position: 'relative',
                                minWidth: '230px',
                            }}
                        >
                            {' '}
                            Edit
                            {' '}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </FormContainer>
    );
}

PopUpEditServiceComponent.propTypes = {};
export default PopUpEditServiceComponent;
