import { useEffect, useRef, useState } from 'react';
import TopNavBar from './TopNavBar';

export default function NavbarComponent({ children }) {
    const topNavbarRef = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (entries[0].target) {
                setHeight(entries[0].contentRect.height);
            }
        });

        if (topNavbarRef.current) {
            resizeObserver.observe(topNavbarRef.current);
        }

        return () => {
            if (topNavbarRef.current) {
                resizeObserver.unobserve(topNavbarRef.current);
            }
        };
    }, []);

    return (
        <>
            <TopNavBar ref={topNavbarRef} />
            <div style={{ paddingTop: `${height}px` }} >
                {children}
            </div>
        </>
    );
}
