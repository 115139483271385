import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { changeOwner } from '../../Services/Search.services';

const TextFieldContainer = styled('div')(() => {
    return {
        display: 'flex',
        alignItems: 'flex-end',
        gap: '10px',
    };
});


function ChangeEmail({ refreshRecords, projectsCurrentUser, selectedProjectId, onClose }) {
    const validationSchema = yup.object()
        .shape({
            email: yup.string()
                .email('Please, write a proper email format')
                .required('Required'),
        });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            changeOwnerOfProject(values.email)
                .then(() => {
                    refreshRecords();
                });
        }
    });

    const changeOwnerOfProject = (newUser) => {
        const data = {
            'new_owner': newUser,
            'current_owner': projectsCurrentUser,
        };

        return changeOwner(data, selectedProjectId)
            .then((response) => {
                if (response === true) {
                    onClose();
                }
            });
    };

    return (
        <form
            style={{ height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onSubmit={formik.handleSubmit}
            noValidate="novalidate"
        >
            <TextFieldContainer>
                <TextField
                    id="email"
                    name="email"
                    label="New Owner Email"
                    variant="standard"
                    value={formik.values.displayName}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <Button
                    size="small"
                    variant="contained"
                    type="submit"
                >
                    Save
                </Button>
            </TextFieldContainer>
        </form>
    );
}
ChangeEmail.propTypes = {
    refreshRecords: PropTypes.func,
    projectsCurrentUser: PropTypes.string,
    selectedProjectId: PropTypes.string,
    onClose: PropTypes.func,
};
export default ChangeEmail;
