import PropTypes from 'prop-types';
import { AvlTextField } from '../FieldText/AvlTextField';
import { useRef } from 'react';
import Button from '@mui/material/Button';
import styles from './UploadFileButton.module.css';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export function UploadFileButton(
    {
        label = 'Choose file',
        chosenFile,
        onChange,
        isDisabled,
    },
) {
    const inputRef = useRef(null);

    const handleOnChange = (e) => {
        const file = e.target.files[0];
        onChange(file);
    };

    const handleClear = () => {
        onChange(null);
        inputRef.current.value = '';
    };

    return (
        <div className={styles.buttonAndField}>
            <Button
                variant="contained"
                component="label"
                disabled={isDisabled}
            >
                {label}
                <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={handleOnChange}
                    ref={inputRef}
                />
            </Button>
            {
                chosenFile && (
                    <AvlTextField
                        className={styles.input}
                        isDisabled
                        disabled
                        value={chosenFile.name}
                    />
                )
            }
            {
                chosenFile && (
                    <IconButton aria-label="delete" onClick={handleClear}>
                        <DeleteIcon color="action" />
                    </IconButton>
                )
            }
        </div>
    );
}

UploadFileButton.propTypes = {
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    props: PropTypes.object,
};
