import { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';
import '../../../Orgs.css';
import { downloadInvoice, orgInvoicesList } from '../../../../../../Services/Search.services';
import SkeletonComponent from '../../../../../SkeletonComponent';

import { Alert, Grid, Pagination, Stack } from '@mui/material';
import { datetimeToFormattedString } from '../../../../../../Utils/Utils';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function OrgInvoiceTable({ orgName, startDate, endDate }) {
    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const downloadButtonRefs = useRef([]);

    const handleDownload = (url, orgName, index, date) => {
        downloadButtonRefs.current[index].disabled = true;
        downloadButtonRefs.current[index].style.backgroundColor = ' rgba(25, 118, 210, 0.04)';
        downloadButtonRefs.current[index].style.color = 'rgba(0, 0, 0, 0.26)';
        downloadButtonRefs.current[index].style.borderColor = 'rgba(0, 0, 0, 0.26)';

        downloadInvoice(url, orgName, date)
            .then((response) => {
                if (response.status === 200) {
                    downloadButtonRefs.current[index].style.backgroundColor = 'rgba(25, 118, 210, 0.04)';
                    downloadButtonRefs.current[index].style.color = 'rgb(25, 118, 210)';
                    downloadButtonRefs.current[index].style.borderColor = 'rgb(25, 118, 210)';
                    downloadButtonRefs.current[index].disabled = false;
                }
            });
    };

    const handlePageChange = (event, page) => {
        setPage(page);
        loadRecords(page);
    };

    const makeParams = (paginationPage) => {
        let urlParam = 'offset=' + offset;
        urlParam = 'limit=' + limit;

        if (startDate) {
            urlParam += '&startDate=' + startDate;
        }

        if (endDate) {
            urlParam += '&endDate=' + endDate;
        }

        if (page) {
            urlParam += '&offset=' + (paginationPage - 1);
        }

        return urlParam;
    };

    const loadRecords = (page) => {
        const urlParam = makeParams(page);
        orgInvoicesList(orgName, urlParam)
            .then((res) => {
                if (res && res.stack && res.message) {
                    setRecords([]);
                    setOffset(0);
                    setLimit(10);
                    setPageCount(1);
                } else {
                    let dataResp = res.data;
                    setRecords(dataResp.invoices);
                    setOffset(dataResp.offset);
                    setLimit(dataResp.limit);

                    let count = Number(dataResp.count);
                    let limit = Number(dataResp.limit);
                    let pcount = Math.ceil(count / limit);
                    setPageCount(pcount);
                }
            })
            .catch(() => {
                setRecords([]);
                setOffset(0);
                setLimit(10);
                //
                // let count = Number(dataResp.count);
                // let limit = Number(dataResp.limit);
                // let pcount = Math.ceil((count / limit));
                setPageCount(1);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setIsLoading(true);
        loadRecords(page);
    }, [startDate, endDate]);

    return (
        <>
            {isLoading
                ? (<SkeletonComponent />)
                : records.length > 0
                    ? (
                        <Grid container item spacing={2} xs={12} sm={12} md={12} sx={{ ml: 2, mt: 2, mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="user table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Invoice Type</TableCell>
                                            <TableCell>To(Email)</TableCell>
                                            <TableCell>CC(Emails)</TableCell>
                                            <TableCell align='center'>Download</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {records.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {datetimeToFormattedString(row.date)}
                                                </TableCell>
                                                <TableCell>{row.invoiceKind || '-'}</TableCell>
                                                <TableCell>{row.emailsTo || '-'}</TableCell>
                                                <TableCell>{row.emailsCc?.replaceAll(';', '; ') || '-'}</TableCell>
                                                <TableCell align='center'>
                                                    <Tooltip title="Download">
                                                        <IconButton
                                                            id={'button' + index}
                                                            ref={(el) => (downloadButtonRefs.current[index] = el)}
                                                            onClick={() => handleDownload(row.attachments, orgName, index, row.date)}
                                                        >
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid item xs={12} sm={12} md={12} sx={{ mt: 1, mb: 2 }}>
                                <Stack spacing={2}>
                                    {/*<Pagination count={3} showFirstButton showLastButton /> */}
                                    <Pagination
                                        showFirstButton
                                        showLastButton
                                        count={pageCount}
                                        page={page}
                                        siblingCount={1}
                                        boundaryCount={1}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={handlePageChange}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    )
                    : (
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="warning">Records not found!</Alert>
                        </Stack>
                    )}
        </>
    );
}
