import React from 'react';
import { Grid } from '@mui/material';
import FeatureFlags from '../Components/BodyComponent/FeatureFlagsComponents/FeatureFlags';
import { Header } from '../Components/Shared/Header/Header';

function FeatureFlagsPage() {
    return (
        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2, mb: 2 }}>
            <div className="header">
                <Header title="Feature Flags" />
            </div>
            <FeatureFlags />
        </Grid>
    );
}

FeatureFlagsPage.propTypes = {};
export default FeatureFlagsPage;
