import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { deleteServiceStatus } from '../../Services/Search.services';


const FormContainer = styled('form')(() => ({
    width: '100%',
    height: '100%',
}));

const TextFieldContainer = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '90%',
    };
});


function DeleteService({ refreshRecords, serviceId, ClosePopUpFunction }) {

    const deleteService = (id) => {
        return deleteServiceStatus(id);
    };


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {},
        initialStatus: {},
        onSubmit: () => {
            // debugger;
            deleteService(serviceId)
                .then(() => {
                    refreshRecords();
                    ClosePopUpFunction();
                });
        }
    });

    return (
        <FormContainer
            onSubmit={formik.handleSubmit}
            noValidate="novalidate"
        >
            <Container id={'popUpContainer'}>
                <Container
                    sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}
                >
                    <Button
                        size="small"
                        variant={'outlined'}
                        color="error"
                        // onClick={handleClose}
                        type={'submit'}
                        sx={{
                            m: 3,
                            position: 'relative'
                        }}
                    >
                        {' '}
                        Delete
                    </Button>
                </Container>
            </Container>
        </FormContainer>
    );
}
DeleteService.propTypes = {
    refreshRecords: PropTypes.func,
    serviceId: PropTypes.string,
    closePopUpFunction: PropTypes.func,
};
export default DeleteService;
