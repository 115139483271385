import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

export function AvlTextField({ label = '', value, isDisabled, onChange, ...props }) {
    const sx = !isDisabled
        ? {
            '.MuiInput-root.Mui-disabled': {
                borderRadius: '4px',
                backgroundColor: '#00000007',

                '&::before': {
                    borderBottomColor: 'transparent',
                },
            },
            '.MuiFormLabel-root.Mui-disabled': {
                color: 'rgba(0, 0, 0, 0.6)',
            },
            '.MuiInput-input.Mui-disabled': {
                WebkitTextFillColor: 'black',
            },
        }
        : undefined;

    const numberInputProps = {
        ...props.inputProps,
        step: props.step,
        min: props.min,
        max: props.max,
    };

    return (
        <TextField
            inputProps={props.type === 'number' ? numberInputProps : props.inputProps}
            InputLabelProps={props.InputLabelProps}
            variant={props.variant || 'standard'}
            autoComplete={props.autoComplete}
            placeholder={props.placeholder}
            InputProps={props.InputProps}
            helperText={props.helperText}
            fullWidth={props.fullWidth}
            className={props.className}
            multiline={props.multiline}
            inputRef={props.inputRef}
            required={props.required}
            disabled={props.disabled}
            onKeyUp={props.onKeyUp}
            onChange={onChange}
            error={props.error}
            sx={props.sx || sx}
            size={props.size}
            name={props.name}
            type={props.type}
            key={props.key}
            id={props.id}
            label={label}
            value={value}
        />
    );
}

AvlTextField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    props: PropTypes.object,
};
