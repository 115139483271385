export const AuthActionTypes = {
    loading: 'Loading',
    signInStart: 'Sign In Start',
    refreshToken: 'Refresh Token',
    successSignIn: 'Success Sign In',
    fail: 'Fail',
    logoutStart: 'Logout Start',
    logout: 'Logout',
    admin: 'Admin',
};
