import React from 'react';
import UserDetailsTable from './UserDetailsTable';
import UserSearchTable from './UserSearchTable';

export default function UserDetails({ userId, email, folderId }) {
    return (
        <>
            <UserDetailsTable userId={userId} email={email} />
            <UserSearchTable key={folderId} userId={userId} email={email} folderId={folderId} />
        </>
    );
}
