import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { signInToFirebaseBySso } from '../../Store/Actions/AuthenticationActions';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

function SsoSignIn({ signInToAvail, authState }) {
    const [searchParams] = useSearchParams();
    const authError = authState?.error;

    const handleSubmit = (values) => {
        const provider = values.provider;
        signInToAvail(provider);
    };

    const validationSchema = yup.object({
        provider: yup
            .string()
            .required('Provider is required'),
    });

    const formik = useFormik({
        initialValues: {
            provider: searchParams.get('sso') || '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <Typography component="h1" variant="h5">Sign in by SSO</Typography>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '400px',
                    width: '100%',
                    marginTop: '8px',
                }}
            >
                {!!authError && (
                    <Stack sx={{ width: '100%', padding: '20px 0' }} spacing={2}>
                        <Alert severity="error">{authError}</Alert>
                    </Stack>
                )}
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="provider"
                    label="SSO provider"
                    name="provider"
                    autoComplete="provider"
                    autoFocus
                    onChange={formik.handleChange}
                    error={!!formik.errors.provider}
                    helperText={formik.errors.provider}
                />
                <Button
                    style={{ marginTop: '50px' }}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={formik.handleSubmit}
                >
                    Sign In
                </Button>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        alignItems: 'center',
                    }}
                >
                    <NavLink style={{ textDecoration: 'none' }} to="/login" variant="body">
                        Login by password
                    </NavLink>
                    <NavLink style={{ textDecoration: 'none' }} to="/reset-password" variant="body">
                        Forgot password
                    </NavLink>
                </div>
            </div>
        </>
    );
}


const dispatchSsoSignInProps = (dispatch) => {
    return {
        signInToAvail: (provider) => dispatch(signInToFirebaseBySso(provider)),
    };
};

const mapStateToProps = (state) => ({
    authState: state.auth,
});

export default connect(mapStateToProps, dispatchSsoSignInProps)(SsoSignIn);
