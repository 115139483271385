import { Button } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Header } from '../../../../Shared/Header/Header';
import OrgInvoicingTable from './OrgInvoicesContent/OrgInvoicingTable';
import InvoicingForm from '../../../../Shared/InvoicingForm/InvoicingForm';
import OrgInvoiceTable from './OrgInvoicesContent/OrgInvoiceTable';
import { Filters } from '../../../../Shared/Filters/Filters';
import { format } from 'date-fns';
import { dateFormat } from '../../../../../Constants/Date.constant';

const ContentType = {
    invoices: 0,
    invoicing: 1,
    addInvoicing: 2,
    editInvoicing: 3,
};

export function OrgInvoicesTab({ orgName }) {
    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);
    const [currentContentType, setCurrentContentType] = useState(ContentType.invoices);
    const [editingInvoicing, setEditingInvoicing] = useState(null);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    const handleFilterChange = (filterValues) => {
        const startDate = filterValues.startDate;
        const endDate = filterValues.endDate;
        const formattedStartDate = startDate && format(startDate, dateFormat);
        const formattedEndDate = endDate && format(endDate, dateFormat);

        setFilterStartDate(formattedStartDate);
        setFilterEndDate(formattedEndDate);
    };

    const goToInvoicingPage = () => {
        setCurrentContentType(ContentType.invoicing);
    };

    const goToEditInvoicingPage = (editingInvoicing) => {
        setEditingInvoicing(editingInvoicing);
        setCurrentContentType(ContentType.editInvoicing);
    };

    const contents = {
        [ContentType.invoices]: {
            title: 'Invoices',
            filters: <Filters isDateRange onFiltersChange={handleFilterChange} />,
            body: (
                <OrgInvoiceTable
                    orgName={orgName}
                    startDate={filterStartDate}
                    endDate={filterEndDate}
                />
            ),
            actionButtons: [
                isAdmin && (
                    <Button
                        key="org-invoices-add-invoicing-button"
                        variant="contained"
                        size="small"
                        color="primary"
                        sx={{ mr: 1, ml: 1 }}
                        onClick={() => setCurrentContentType(ContentType.addInvoicing)}
                    >
                        Add Invoicing
                    </Button>
                ),
                <Button
                    key="org-invoices-invoicing-button"
                    variant="contained"
                    size="small"
                    color="primary"
                    sx={{ mr: 1, ml: 1 }}
                    onClick={() => setCurrentContentType(ContentType.invoicing)}
                >
                    Invoicing
                </Button>,
            ],
        },
        [ContentType.invoicing]: {
            title: 'Invoicing',
            filters: false,
            body: <OrgInvoicingTable orgName={orgName} onOpenEditPage={goToEditInvoicingPage} />,
            actionButtons: [
                <Button
                    key="org-invoices-invoicing-back-button"
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => setCurrentContentType(ContentType.invoices)}
                >
                    Back
                </Button>,
            ],
        },
        [ContentType.addInvoicing]: {
            title: 'Add Invoicing',
            filters: false,
            body: <InvoicingForm orgName={orgName} onSave={goToInvoicingPage} />,
            actionButtons: [
                <Button
                    key="org-invoices-add-back-button"
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => setCurrentContentType(ContentType.invoices)}
                >
                    Back
                </Button>,
            ],
        },
        [ContentType.editInvoicing]: {
            title: 'Edit Invoicing',
            filters: false,
            body: <InvoicingForm
                isEditMode
                orgName={orgName}
                onSave={goToInvoicingPage}
                initialValues={editingInvoicing}
            />,
            actionButtons: [
                <Button
                    key="org-invoices-edit-back-button"
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => setCurrentContentType(ContentType.invoicing)}
                >
                    Back to invoicing
                </Button>,
            ],
        },
    };

    const currentContent = contents[currentContentType];

    return (
        <>
            <div className="header">
                <Header
                    title={currentContent.title}
                    actionButtons={currentContent.actionButtons}
                    filters={currentContent.filters}
                />
            </div>
            {currentContent.body}
        </>
    );
}
